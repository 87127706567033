import { Component, OnInit }                              from '@angular/core';
import { animate, state, style, transition, trigger }     from '@angular/animations';
import { Router, ActivatedRoute }                         from '@angular/router';
import json                                               from '../../../assets/json/upload-otros/upload-otros.json';
import { QueriesService }                                 from '../../services/queries.service';
import { FormService }                                    from '../../services/form.service';

@Component({
  selector: 'app-upload-otros',
  templateUrl: './upload-otros.component.html',
  styleUrls: ['./upload-otros.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class UploadOtrosComponent implements OnInit {
  public json: any = json;
  public usuario;
  public name_usuario;
  public activePane: any = 't1';

  constructor(
    private _queriesService: QueriesService,
    private router: Router,
    public formService: FormService
  ) { }

  ngOnInit() {
    // REFRESH PÁGINA
    if(!this.formService.idguardar) {
      this.router.navigate(['/upload/subir-publicacion']);
      return
    }
    this.formService.toScroll();

    /* VALORIZACION */
    var URLactual = window.location.href;
    this.json["rating"]['star'].pagina = URLactual

    /* USUARIOS SIN CODPERS */
    if(localStorage.getItem('estado_user_sipa') == "false"){
      this.router.navigate(['/mispublicaciones/confirmado']);
    }
    
    /* RESCATAR NOMBRE USUARIO */
    this.usuario = this._queriesService.getUser()
    let split = this.usuario.split(',');
    this.name_usuario = split[1];
    this.formService.mis_guardados(); 

    /* Limpiar Form autores */
    this.formService.busca_autor = null;
    this.formService.msj_autor = null;
  }

  collapse(item){
    if(item == "general" && this.formService.array_enviar.titulo){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "autorias" && this.formService.array_enviar.autores && this.formService.array_enviar.autores.length > 0 && this.formService.validaAutores){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "archivos"){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "licencias" && this.formService.array_enviar.licencia.concedo_licencia){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
  }

}
