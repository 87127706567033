import { Component, OnInit, DoCheck, ɵConsole }   from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params }         from '@angular/router';
import * as L                                     from 'leaflet/dist/leaflet.js';
import { QueriesService }                         from '../../services/queries.service';
import { SubscribeService }                       from '../../services/subscribe.service';
import { DataService }                            from '../../services/data.service';

import PoliticasAccesoAbierto                     from '../../../assets/json/template-content/politicas-de-acceso-abierto.json';
import QuienesSomos                               from '../../../assets/json/template-content/quienes-somos.json';
import NuestroEquipo                              from '../../../assets/json/template-content/nuestro-equipo.json';
import PoliticasPrivacidad                        from '../../../assets/json/template-content/politicas-de-privacidad.json';
import Licencia                                   from '../../../assets/json/template-content/licencia.json';
import CondicionesUso                             from '../../../assets/json/template-content/condiciones-de-uso.json';
import MapaSitio                                  from '../../../assets/json/template-content/mapa-del-sitio.json';

import SubidosRecientemente                       from '../../../assets/json/template-content/subidos-recientemente.json';
import MasDescargados                             from '../../../assets/json/template-content/mas-descargados.json';
import Destacados                                 from '../../../assets/json/template-content/destacados.json';
import InvestigacionMes                           from '../../../assets/json/template-content/investigacion-del-mes.json';
import Suscripcion                                from '../../../assets/json/template-content/suscripcion.json';
import json                                       from '../../../assets/json/home.json';


@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.css']
})
export class PageContentComponent implements OnInit, DoCheck {

  data: any = [];
  pagina;
  json:     any = json;
  subscribeData: any = <any>{};
  status;
  public urlSuscribirNews: string;

  constructor(
    private subscribeService: SubscribeService,
    private _sanitizer: DomSanitizer, 
    private queriesService: QueriesService,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    public dataService: DataService
    ) { 
      this.dataService.urls();
      this.urlSuscribirNews = this.dataService.phpSuscribirNews;
    }

  ngOnInit() {

    // refresca página
    /* this.queriesService.getRefresh(); */

    // SUSCRIBETE A NUESTRO NEWSLETTER
    this.data['SuscripcionNews'] = json['horizon_novedades']['section_suscription'];

    this.activatedRoute.params.subscribe(params =>{
      this.pagina = params['pag'];
      // Scroll
      document.getElementById('banner').scrollIntoView({behavior: 'smooth'});

      // JQuery ir arriba
      /* $('body, html').animate({
        scrollTop: '0px'
      }, 300); */
    }); 

  }
  ngDoCheck(){
    this.paginas();
  }

  paginas(){
      // MENU NOVEDADES
      if(this.pagina == "subidos-recientemente"){
        this.data['content'] = SubidosRecientemente;
      }
      if(this.pagina == "mas-descargados"){
        this.data['content'] = MasDescargados;
      }
      if(this.pagina == "destacados"){
        this.data['content'] = Destacados;
      }
      if(this.pagina == "investigacion-del-mes"){
        this.data['content'] = InvestigacionMes;
      }
      if(this.pagina == "suscripcion"){
        this.data['content'] = Suscripcion;
      }
      // MENU QUE ES REPOSITORIO
      if(this.pagina == "politicas-de-acceso-abierto"){
        this.data['content'] = PoliticasAccesoAbierto;
      }
      if(this.pagina == "quienes-somos"){
        this.data['content'] = QuienesSomos;
      }
      if(this.pagina == "nuestro-equipo"){
        this.data['content'] = NuestroEquipo;
      }
      if(this.pagina == "politicas-de-privacidad"){
        this.data['content'] = PoliticasPrivacidad;
      }
      if(this.pagina == "condiciones-de-uso"){
        this.data['content'] = CondicionesUso;
      }
      if(this.pagina == "mapa-del-sitio"){
        this.data['content'] = MapaSitio;
      }
      if(this.pagina == "licencia"){
        this.data['content'] = Licencia;
      }
  }

  newsletter(){
    this.queriesService.queryGet( this.urlSuscribirNews + this.subscribeData['email'])
      .then((data) => { 
        /* this.data['search']= Object.keys(data) .map(i =>data[i]);
        this.data['search']= this.data['search'][1]['docs']; */
        //console.log(data);
        //console.log(this.subscribeData['email']);
        if(data['status'] == 200){
          this.status = "success";
          //console.log(data['mensaje']);
        }
        if(data['status'] == 214){
          this.status = "existe";
          //console.log(data['mensaje']);
        }
        if(data['status'] == 400){
          this.status = "error";
          //console.log(data['mensaje']);
        }
    });
  }
  volverSuscribir(){
    this.status = false;
    //alert("aaa");
  }

/*   subscribe(form) {

    this.subscribeService.subscribeToList(this.subscribeData)
      .subscribe(res => {
        this.status = "success";
      }, err => {
        console.log(err);
      })
  } */

  getBackgroundImage(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(29, 29, 29, 0), rgba(16, 16, 23, 0.5)), url(${image})`);
  }
}
