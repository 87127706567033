import { Component, OnInit, Input }   from '@angular/core';
import { QueriesService }             from '../../../services/queries.service';
import { DataService }                from '../../../services/data.service';

@Component({
  selector: 'app-valorizacion-estrellas',
  templateUrl: './valorizacion-estrellas.component.html',
  styleUrls: ['./valorizacion-estrellas.component.css']
})
export class ValorizacionEstrellasComponent implements OnInit {
  @Input() valorizaciones : any;
  public viewComentario;
  public comentario;
  public msj_rating;
  public array_rating     : any = [];
  public urlMailEnvio: string;

  constructor(
    private queriesService      : QueriesService,
    public dataService: DataService
  ) { 
    this.dataService.urls();
    this.urlMailEnvio = this.dataService.phpEmail;
  }

  ngOnInit() {
  }

  valoracion(valor, pagina){
    this.viewComentario = true;
    this.array_rating   = {
      valor       : valor.target.value,
      pagina      : pagina,
      user        : this.queriesService.getUser(),
      comentario  : ""
    }
    var email = "rodrigo.pantoja@uc.cl"
    console.log(this.array_rating)


    /* this.queriesService.queryGet(this.urlMailEnvio+'nombreApellido='+this.queriesService.getUser()+'&mail='+email+'&consulta='+this.array_rating +'&tipo=contacto').then(
      (data) => {
        if(data['respuesta'] == true){
          console.log("enviado")
        }else{
          console.log(data);
        }
      },
      (error) => {
        console.log(error);
      }
    ); */
    
  }
  comentarios(pagina){
    this.viewComentario           = false;
    this.msj_rating               = "¡Muchas gracias por tu aporte!"
    this.array_rating.comentario  = this.comentario
    console.log(this.array_rating)
  }




}
