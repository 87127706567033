import { Component, OnInit }          from '@angular/core';
import json                           from '../../../assets/json/produccion-academicos/produccion-academicos.json';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader.service';
import { ActivatedRoute, Router }     from '@angular/router';
import { QueriesService }             from '../../services/queries.service';
import { HttpClient, HttpHeaders }    from '@angular/common/http';
import { DataService }                from '../../services/data.service';

declare var jQuery:any;
declare var $     :any;

@Component({
  selector    : 'app-produccion-academicos',
  templateUrl : './produccion-academicos.component.html',
  styleUrls   : ['./produccion-academicos.component.css']
})
export class ProduccionAcademicosComponent implements OnInit {
  public json: any                = json;
  public pag_active               = "Académicos";
  public valorSearch              = "";
  public valorSearchMilista       = "";
  public data: any                = [];
  public array_check: any         = [];
  public array_ckeck_activar: any = [];
  public array_descheck: any      = [];
  public urlLoginCas;
  public urlPhp;
  public urlSql;
  public codpers;
  public urlMiLista;
  public urlDownload;
  public urlMailEnvio;

  public orden;
  public orden_pa;
  public entrada;
  public cargando;
  public cargando_listar;
  public cargando_insert;
  public cargando_update;
  public cargando_download;
  public cargando_download_ATS;
  public cargando_download_ATSA;
  public cargando_prod_acad;
  public cargando_delete;
  public msj;
  public msj_listar;
  public msj_insert;
  public msj_update;
  public msj_download;
  public msj_prod_acad;
  public msj_delete;

  public registros;
  public totalPage;
  public page;
  public npage: any               = 1;
  public cantidadReg;
  public paginacion               = [];
  public nextPage;
  public prevPage;

  public ordenMiLista;
  public registrosMiLista;
  public totalPageMiLista;
  public pageMiLista;
  public npageMiLista: any        = 1;
  public cantidadRegMiLista;
  public paginacionMiLista        = [];
  public nextPageMiLista;
  public prevPageMiLista;

  public totalPageListaAcadSel;
  public pageListaAcadSel;
  public npageListaAcadSel: any   = 1;
  public cantidadRegListaAcadSel;
  public paginacionListaAcadSel   = [];
  public nextPageListaAcadSel;
  public prevPageListaAcadSel;

  public headers;
  public storageSvc;
  public urlGenerarExcel;
  public urlDownloadExcel;
  public checkAll;

  

  constructor(
    private dynamicScriptLoader : DynamicScriptLoaderService,
    private activatedRoute      : ActivatedRoute, 
    public dataService          : DataService,
    private router              : Router,
    private queriesService      : QueriesService,
    private http                : HttpClient,
  ) { 
    this.dataService.urls();
    this.urlLoginCas            = this.dataService.urlLoginCas;
    this.urlPhp                 = this.dataService.phpDiscovery;
    this.urlSql                 = this.dataService.phpSql; 
    this.urlMiLista             = this.dataService.phpPubliAcademicos; 
    this.urlGenerarExcel        = this.dataService.phpGenerarExcel; 
    this.urlDownloadExcel       = this.dataService.phpDescargarExcel; 
    this.urlMailEnvio           = this.dataService.phpEmail;
  }

  ngOnInit() {
    // JQuery ir arriba
    /* $('body, html').animate({
      scrollTop: '0px'
    }, 300); */

    document.getElementById('buscador').scrollIntoView({behavior: 'smooth'});

    /* Desde CAS */
    if(this.queriesService.getjsonAcademico()){
      let perfil    = JSON.parse(this.queriesService.getjsonAcademico());
      this.codpers  =  perfil.COD_PERS;
    }

    this.data['param']  = this.activatedRoute.snapshot.paramMap.get('pag');
    this.pag_active     = this.data['param'];
    this.dynamicScriptLoader.load('uc-kit');
    if(this.data['param'] == "academicos"){
      this.buscar_academicos("1", 3);
      this.inserta_mis_academicos(1,3);
    }
    if(this.data['param'] == "produccion_academicos"){
      this.buscar_miLista(1,3);
    }

    /* VALORIZACION */
    var URLactual = window.location.href;
    this.json["rating"]['star'].pagina = URLactual



  }

  toScroll(id){
    document.getElementById(id).scrollIntoView({behavior: 'smooth'});
  }
  menuPestanias(menu){
    /* this.router.navigate(['/produccion-academicos/'+menu]); */
    window.location.href='/produccion-academicos/'+menu;
  }
  collapse(item){
    $('#'+item).slideToggle(300);
  }

  


  /* PESTAÑA ACADEMICOS */

  buscar_academicos(page, orden) {
    this.cargando   = true;
    this.msj        = null;
    this.npage      = page;
    localStorage.setItem( 'page', page );
    page            = (page-1)*20;
    this.orden      = orden;
    localStorage.setItem( 'search_form', this.valorSearch );

    let array_buscar = {
      tipo    : "listarAcademicos",
      campo   : localStorage.getItem( 'search_form' ),
      codpers : this.codpers,
      start   : this.npage,
      orden   : this.orden
    };
    
    const formData = new FormData();
    formData.append('array_insert', JSON.stringify( array_buscar ));


    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlSql, formData)
    .subscribe(data => {

        this.cargando = false;


        if(data['code'] == 404){
          window.location.href  = this.urlLoginCas;
        }else if(data == 400){
          this.data['search']   = null;
          this.totalPage        = null;
          this.msj              = "Búsqueda sin resultados";
        }else{
          this.data['search']   = data['Academicos'];
          
          if(this.data['search'] || this.data['search'].length>0 ){

          }else{
            this.msj            = "Sin registros";
          }

          this.totalPage        = data['Total Registro'];
          this.cantidadReg      = 20;
          this.page             = Math.ceil(this.totalPage / this.cantidadReg); 
          this.paginacion       = [];
          for(let i=1; i<=this.page; i++){
            if(i <= 5){
              if(this.npage>5){
                this.paginacion.push(i+(this.npage-5));
              }else{
                this.paginacion.push(i);
              }
            }
          }
          if(this.npage>=2){
            this.prevPage       = this.npage-1;
          }else{
            this.prevPage       = 1;
          }
          if(this.npage<this.page){
            this.nextPage       = this.npage+1;
          }else{
            this.nextPage       = this.page;
          }
        }

    }, err => {

      this.cargando             = false;
      this.msj                  = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });


  }

  inserta_mis_academicos(start, orden) {
    this.cargando_insert    = true;
    this.msj_insert         = null;

    let array_inserta_mis_academicos = {
      tipo      : "inserta_gestiona_academicos",
      codpers   : this.codpers,
      json      : "null",
      start     : start,
      orden     : orden
    };
    
    const formData          = new FormData();
    formData.append('array_insert', JSON.stringify(array_inserta_mis_academicos));

    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlSql, formData)
    .subscribe(data => {
      this.listar_mis_academicos(1,3)

    }, err => {

      this.cargando_insert  = false;
      this.msj_insert       = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      this.msj_listar       = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });
  }

  listar_mis_academicos(start, orden) {
    this.cargando_listar            = true;
    this.msj_listar                 = null;
    this.npageListaAcadSel          = start;
    start                           = (start-1)*20;
    this.orden                      = orden;
    let array_listar_mis_academicos = {
      tipo      : "listar_gestiona_academicos",
      campo     : localStorage.getItem('search_form'),
      codpers   : this.codpers,
      start     : this.npageListaAcadSel,
      orden     : this.orden
    };
    const formData                  = new FormData();
    formData.append('array_insert', JSON.stringify(array_listar_mis_academicos));

    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlSql, formData)
    .subscribe(data => {

        this.cargando_listar                = false;
        this.data['search_mis_academicos']  = JSON.parse(data[0]['json_gestion_academicos']);
        this.totalPageListaAcadSel          = this.data['search_mis_academicos']['Total Registro'];
        this.data['search_mis_academicos']  = this.data['search_mis_academicos']['academicos'];
        if(!this.data['search_mis_academicos'] || this.data['search_mis_academicos'].length==0){
          this.msj_listar                   = "Sin registros"
        }
        this.cantidadReg                    = 20;
        this.pageListaAcadSel               = Math.ceil(this.totalPageListaAcadSel / this.cantidadReg); 
        this.paginacionListaAcadSel         = [];
          for(let i=1; i<=this.pageListaAcadSel; i++){
            if(i <= 5){
              if(this.npageListaAcadSel>5){
                this.paginacionListaAcadSel.push(i+(this.npageListaAcadSel-5));
              }else{
                this.paginacionListaAcadSel.push(i);
              }
            }
          }
          if(this.npageListaAcadSel>=2){
            this.prevPageListaAcadSel       = this.npageListaAcadSel-1;
          }else{
            this.prevPageListaAcadSel       = 1;
          }
          if(this.npageListaAcadSel<this.pageListaAcadSel){
            this.nextPageListaAcadSel       = this.npageListaAcadSel+1;
          }else{
            this.nextPageListaAcadSel       = this.pageListaAcadSel;
          }

    }, err => {

      this.cargando_listar                  = false;
      this.msj_listar                       = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });


  }

  update_mis_academicos(Nombre, codpers, correo, i) {
    this.cargando_update  = true;
    this.msj_update       = null;
    let array_update      = {
      Nombre    : Nombre,
      codPers   : codpers,
      correo    : correo
    }
    let array_update_mis_academicos = {
      tipo      : "update_gestiona_academicos",
      codpers   : this.codpers,
      json      : array_update,
      start     : this.npageListaAcadSel,
      orden     : this.orden
    };
    const formData          = new FormData();
    formData.append('array_insert', JSON.stringify(array_update_mis_academicos));


    // ++++++++++++++++++ ENVÍO API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlSql, formData)
    .subscribe(data => {

      this.cargando_update  = false;
      this.msj_update       = "¡Agregado!";
      if(this.data['search'].length==1){
        this.valorSearch    = ""
        localStorage.setItem('search_form',this.valorSearch);
      }
      this.buscar_academicos(1,3);
      this.listar_mis_academicos(1,3)

    }, err => {

      this.cargando_update  = false;
      this.msj_update       = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });
  }

  delete_mis_academicos(Nombre, codpers, correo, i) {
    this.cargando_delete            = true;
    this.msj_delete                 = null;
    let array_delete                = {
      Nombre    : Nombre,
      codPers   : codpers,
      correo    : correo
    }
    let array_delete_mis_academicos = {
      tipo      : "eliminar_gestiona_academicos",
      codpers   : this.codpers,
      json      : array_delete,
      start     : this.npageListaAcadSel,
      orden     : this.orden
    };
    const formData                  = new FormData();
    formData.append('array_insert', JSON.stringify(array_delete_mis_academicos));


    // ++++++++++++++++++ ENVÍO API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlSql, formData)
    .subscribe(data => {

      this.cargando_delete          = false;
      this.msj_delete               = "¡Quitado!";
      this.buscar_academicos(1,3);
      this.listar_mis_academicos(1,3);


      /* Elimina checkbox de PA si existe */
      if(localStorage.getItem("exportPA")){
        this.array_check            = JSON.parse(localStorage.getItem("exportPA"));
        for(var e = 0; this.array_check.length>e; e++){  
          if(this.array_check[e].codpers == codpers){
            this.array_check.splice(e, 1);
          }  
        }
        if(this.array_check.length == 0){
          localStorage.removeItem("exportPA");
        }else{
          localStorage.setItem("exportPA",JSON.stringify(this.array_check));
        }
      }

    }, err => {

      this.cargando_delete          = false;
      this.msj_delete               = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });
  }


  /* PESTAÑA PRODUCCION DE ACADEMICOS */

  buscar_miLista(page, orden) {
    document.getElementById('buscador').scrollIntoView({behavior: 'smooth'});
    this.cargando_prod_acad = true;
    this.msj_prod_acad      = null;
    this.npageMiLista       = page;
    page                    = (page-1)*20;
    this.ordenMiLista       = orden;
    localStorage.setItem('search_form',this.valorSearchMilista);
    let array_buscar        = {
      tipo    : "listarAcademicos",
      campo   : localStorage.getItem('search_form'),
      start   : this.npageMiLista,
      orden   : this.ordenMiLista,
      codpers : this.codpers
    };
    const formData          = new FormData();
    formData.append('array_insert', JSON.stringify(array_buscar));

    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlMiLista, formData)
    .subscribe(data => {

        this.cargando_prod_acad = false;

        if(data['code'] == 404){
          window.location.href  = this.urlLoginCas;
        }else{
          this.data['search_milista'] = data['items'];

          if(this.data['search_milista'].length>0 ){
            
            if(this.checkAll){
              this.array_ckeck_activar = [];
              /* for(let i=0; i<this.data['search_milista'].length; i++){ 
                this.array_ckeck_activar.push({
                  btn: true
                });
              } */

              if(localStorage.getItem("exportAll-descheck")){
                this.array_ckeck_activar=[];
                this.array_descheck=JSON.parse(localStorage.getItem("exportAll-descheck"));
                for(let i=0; i<this.data['search_milista'].length; i++){
                  for(let a=0; a < this.array_descheck.length; a++){
                    if(this.data['search_milista'][i].codpers==this.array_descheck[a].codpers){
                      var btn = false
                      break;
                    }else{
                      var btn = true
                    }
                  }
                  this.array_ckeck_activar.push({
                    btn: btn
                  });
                }
              }else{
                for(let i=0; i<this.data['search_milista'].length; i++){
                  this.array_ckeck_activar.push({
                    btn: true
                  });
                }
              }
              
            }else{
              if(localStorage.getItem("exportPA")){
                this.array_ckeck_activar  = [];
                this.array_check          = JSON.parse(localStorage.getItem("exportPA"));
                for(let i=0; i<this.data['search_milista'].length; i++){
                  for(let a=0; a < this.array_check.length; a++){
                    if(this.data['search_milista'][i].codpers==this.array_check[a].codpers){
                      var btn = true
                      break;
                    }else{
                      var btn = false
                    }
                  }
                  this.array_ckeck_activar.push({
                    btn: btn
                  });
                }
              }else{
                for(let i=0; i<this.data['search_milista'].length; i++){
                  this.array_ckeck_activar.push({
                    btn: false
                  });
                }
              }
            }

          }else{
            this.msj_prod_acad    = "Sin registros";
          }


          this.totalPageMiLista   = data['num-found'];
          this.cantidadRegMiLista = 20;
          this.pageMiLista        = Math.ceil(this.totalPageMiLista / this.cantidadRegMiLista); 

          this.paginacionMiLista  = [];
          for(let i=1; i<=this.pageMiLista; i++){
            if(i <= 5){
              if(this.npageMiLista>5){
                this.paginacionMiLista.push(i+(this.npageMiLista-5));
              }else{
                this.paginacionMiLista.push(i);
              }
            }
          }
          if(this.npageMiLista>=2){
            this.prevPageMiLista = this.npageMiLista-1;
          }else{
            this.prevPageMiLista = 1;
          }
          if(this.npageMiLista<this.pageMiLista){
            this.nextPageMiLista = this.npageMiLista+1;
          }else{
            this.nextPageMiLista = this.pageMiLista;
          }

        }

    }, err => {

      this.cargando_prod_acad = false;
      this.msj_prod_acad      = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });


  }

  cheq(event, i:number, nombre){
    if(this.checkAll){
      if(event.target.checked){
        for(var e = 0; this.array_descheck.length>e; e++){  
          if(this.array_descheck[e].codpers == event.target.value){
            this.array_descheck.splice(e, 1);
          }  
        }
        if(this.array_descheck.length == 0){
          localStorage.removeItem("exportAll-descheck");
        }else{
          localStorage.setItem("exportAll-descheck",JSON.stringify(this.array_descheck));
        }
      }else{
        this.array_descheck.push({
          nombre  : nombre,
          codpers : event.target.value
        })
        localStorage.setItem("exportAll-descheck",JSON.stringify(this.array_descheck));   
      }
    }else{
      if(event.target.checked){
          this.array_check.push({
            nombre  : nombre,
            codpers : event.target.value
          })
          localStorage.setItem("exportPA",JSON.stringify(this.array_check));
      }else{
          for(var e = 0; this.array_check.length>e; e++){  
            if(this.array_check[e].codpers == event.target.value){
              this.array_check.splice(e, 1);
            }  
          }
          if(this.array_check.length == 0){
            localStorage.removeItem("exportPA");
          }else{
            localStorage.setItem("exportPA",JSON.stringify(this.array_check));
          }
      }
    }
    
  }
  cheqAll(event){
    this.checkAll             = event.target.checked;
    this.array_ckeck_activar  = [];
    if(this.checkAll){ 
      for(let i=0; i<this.data['search_milista'].length; i++){       
        this.array_ckeck_activar.push({
          btn: true
        });
      }
      this.array_descheck     = [];
      localStorage.removeItem("exportAll-descheck");
    }else{
      for(let i=0; i<this.data['search_milista'].length; i++){       
        this.array_ckeck_activar.push({
          btn: false
        });
      }
      this.array_check        = [];
      localStorage.setItem("exportPA",JSON.stringify(this.array_check));
    }
  }
  _cheqAll(event){
    this.checkAll = event.target.checked; 
    this.array_check = [];
    let checkboxes=document.getElementsByTagName('input'); //obtenemos todos los controles del tipo Input
    for(let i=0;i<checkboxes.length;i++){
      if(checkboxes[i].name == "check_ac"){
        checkboxes[i].checked=event.target.checked;
      }
    }
    let clasecheq=document.getElementsByName('check_ac'); //obtenemos todos los controles del name check_ac
    for(let i=0;i<clasecheq.length;i++){ //recoremos todos los controles
        if(event.target.checked){
          this.array_check.push({
            nombre: this.data['search_milista'][i]['academico'],
            codpers: this.data['search_milista'][i]['codpers']
          })
        }else{
          this.array_check = [];
        }

      //checkboxes[i].checked=event.target.checked; //si es un checkbox le damos el valor del checkbox que lo llamó (Marcar/Desmarcar Todos)

        


    
      //if(checkboxes[i].type == "checkbox"){ //solo si es un checkbox entramos

        /* checkboxes[i].checked=event.target.checked; //si es un checkbox le damos el valor del checkbox que lo llamó (Marcar/Desmarcar Todos)
        this.array_check = [];
        this.array_check.push({
          nombre: this.data['search_milista'][i]['academico'],
          codpers: this.data['search_milista'][i]['codpers']
        }) */
        /* console.log(this.data['search_milista'][i]['academico']) */
        /* console.log(checkboxes[i].value) */
      //}
      
    }


  }

  _download_gest_academicos(report) {
    if(report=="FULL"){
      this.cargando_download = true;
    }
    if(report=="ATS"){
      this.cargando_download_ATS = true;
    }
    if(report=="ATSA"){
      this.cargando_download_ATSA = true;
    }
    
    this.msj_download = null;

    let array_buscar = {
      tipo        : "listarAcademicosParcial", // listaarAcademicosTodos
      reporte     : report, // FULL ATS ATSA
      academicos  : this.array_check // quitar-academicos
    };
    const formData = new FormData();
    formData.append('array_insert', JSON.stringify(array_buscar));


    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlGenerarExcel, formData)
    .subscribe(data => {
        this.cargando_download = false;
        this.cargando_download_ATS = false;
        this.cargando_download_ATSA = false;
        window.location.href= this.urlDownloadExcel + data['archivo'];
    }, err => {
      this.cargando_download = false;
      this.msj_download = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });
  }

  download_gest_academicos(report) {
    if(report == "FULL"){
      this.cargando_download      = true;
    }
    if(report == "ATS"){
      this.cargando_download_ATS  = true;
    }
    if(report == "ATSA"){
      this.cargando_download_ATSA = true;
    }

    let tipo;
    let academicos;
    if( this.checkAll ){
      tipo        = "listaarAcademicosTodos";
      academicos  = this.array_descheck;
    }else{
      tipo        = "listarAcademicosParcial";
      academicos  = this.array_check;
    }
    
    this.msj_download = null;
    let array_buscar  = {
      tipo        : tipo,
      reporte     : report, // FULL ATS ATSA
      academicos  : academicos, 
      codpers     : this.codpers
    };
    const formData    = new FormData();
    formData.append('array_insert', JSON.stringify(array_buscar));


    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++

    this.http.post(this.urlGenerarExcel, formData)
    .subscribe(data => {
        this.cargando_download      = false;
        this.cargando_download_ATS  = false;
        this.cargando_download_ATSA = false;
        window.location.href        = this.urlDownloadExcel + data['archivo'];

    }, err => {

      this.cargando_download        = false;
      this.msj_download             = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });
  }

}

