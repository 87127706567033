import { Component, OnInit }                              from '@angular/core';
import { animate, state, style, transition, trigger }     from '@angular/animations';
import { Router, ActivatedRoute }                         from '@angular/router';
import json                                               from '../../../assets/json/upload-obra-creativa/upload-obra-creativa.json';
import { QueriesService }                                 from '../../services/queries.service';
import { FormService }                                    from '../../services/form.service';

@Component({
  selector: 'app-upload-obra-creativa',
  templateUrl: './upload-obra-creativa.component.html',
  styleUrls: ['./upload-obra-creativa.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class UploadObraCreativaComponent implements OnInit {
  public json: any = json;
  public usuario;
  public name_usuario;
  public activePane: any = 't1';
  

  constructor(
    private _queriesService: QueriesService,
    private router: Router,
    public formService: FormService
  ) { }

  ngOnInit() {
    // REFRESH PÁGINA
    if(!this.formService.idguardar) {
      this.router.navigate(['/upload/subir-publicacion']);
      return
    }

    this.formService.toScroll();

    /* VALORIZACION */
    var URLactual = window.location.href;
    this.json["rating"]['star'].pagina = URLactual

    /* USUARIOS SIN CODPERS */
    if(localStorage.getItem('estado_user_sipa') == "false"){
      this.router.navigate(['/mispublicaciones/confirmado']);
    }
    /* RESCATAR NOMBRE USUARIO */
    this.usuario = this._queriesService.getUser()
    let split = this.usuario.split(',');
    this.name_usuario = split[1];

    this.formService.date();
    this.formService.rangoDate("");
    this.formService.mis_guardados();
    this.formService.validaLugarBoton();
    this.formService.validaAuspiciadoraBoton();
    this.formService.validaPatrocinadoraBoton();
    this.formService.validaAutoresBoton();

    /* Limpiar Form autores */
    this.formService.busca_autor = null;
    this.formService.msj_autor = null;
    
  }

  collapse(item){ 
    this.formService.camposObligatorios();
    if(item == "general" && this.formService.array_enviar.titulo && this.formService.max_date_fecha_inicio && !this.formService.max_date_fecha_termino && this.formService.validalugar && this.formService.validauspiciadora && this.formService.validapatrocinadora && this.formService.array_enviar.tipo_creacion && (this.formService.valida_tipo_obra || this.formService.valida_arte_viva  || this.formService.valida_curatorias)){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "autorias" && this.formService.array_enviar.autores && this.formService.array_enviar.autores.length > 0 && this.formService.validaAutores){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "archivos"){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "licencias" && this.formService.array_enviar.licencia.concedo_licencia){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
  }
  cheq_indexacion(event, ind:number){
    var lista = this.json['horizon_forms']['content'][0]['sections'][0]['input_indexacion']['options'];
    if(!this.formService.array_enviar.indexacion){
      this.formService.array_enviar.indexacion = [];
    }
    if(event.target.checked){
      this.formService.array_enviar.indexacion.push(
        event.target.value
      );
      lista[ind].cheq = true;
    }else{
      for(var i = 0; this.formService.array_enviar.indexacion.length>i; i++){    
        if(this.formService.array_enviar.indexacion[i]==event.target.value){
          this.formService.array_enviar.indexacion.splice(i, 1);
        }
      }
      lista[ind].cheq = false;
    }
  }
  addUrl() {  
    if(!this.formService.array_enviar.url_publicacion){
      this.formService.array_enviar.url_publicacion = [];
    }
    this.formService.array_enviar.url_publicacion.push({
      url: null
    });
  }
  removeUrl(i: number) {
    this.formService.array_enviar.url_publicacion.splice(i, 1); // elimina 1 indice a partir del indice i
    if(this.formService.array_enviar.url_publicacion==0){
      this.formService.array_enviar.url_publicacion = null;
    }
  }
  addLugaryPais(){
    if(!this.formService.array_enviar.pais_ubicacion){
      this.formService.array_enviar.pais_ubicacion = [];
    }
    this.formService.indice_fecha();
    this.formService.array_enviar.pais_ubicacion.push({
      ubicacion: null,
      indice: this.formService.indice_autor
    });
    this.formService.validaLugarBoton();
  }
  DeleteLugaryPais(i){
    this.formService.array_enviar.pais_ubicacion.splice(i, 1);
    if(this.formService.array_enviar.pais_ubicacion==0){
      this.formService.array_enviar.pais_ubicacion = null;
    }
    this.formService.validaLugarBoton();
  }
  addAuspiciadora() {  
    if(!this.formService.array_enviar.institucion_auspiciadora){
      this.formService.array_enviar.institucion_auspiciadora = [];
    }
    this.formService.indice_fecha();
    this.formService.array_enviar.institucion_auspiciadora.push({
      nombre: null,
      indice: this.formService.indice_autor
    });
    this.formService.validaAuspiciadoraBoton();
  }
  removeAuspiciadora(i: number) {
    this.formService.array_enviar.institucion_auspiciadora.splice(i, 1);
    if(this.formService.array_enviar.institucion_auspiciadora==0){
      this.formService.array_enviar.institucion_auspiciadora = null;
    }
    this.formService.validaAuspiciadoraBoton();
  }
  addPatrocinadora() {  
    if(!this.formService.array_enviar.institucion_patrocinadora){
      this.formService.array_enviar.institucion_patrocinadora = [];
    }
    this.formService.indice_fecha();
    this.formService.array_enviar.institucion_patrocinadora.push({
      nombre: null,
      indice: this.formService.indice_autor
    });
    this.formService.validaPatrocinadoraBoton();
  }
  removePatrocinadora(i: number) {
    this.formService.array_enviar.institucion_patrocinadora.splice(i, 1); // elimina 1 indice a partir del indice i
    if(this.formService.array_enviar.institucion_patrocinadora==0){
      this.formService.array_enviar.institucion_patrocinadora = null;
    }
    this.formService.validaPatrocinadoraBoton();
  }

}
