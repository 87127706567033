import { Component, OnInit, DoCheck, Output }  from '@angular/core';
import { animate, state, style, transition, trigger }     from '@angular/animations';
import { Router }                         from '@angular/router';
import json                                               from '../../../assets/json/upload-record/upload-record.json';
import { DataService }                                    from '../../services/data.service';
import { FormService }                                    from '../../services/form.service';

@Component({
  selector: 'app-upload-record',
  templateUrl: './upload-record.component.html',
  styleUrls: ['./upload-record.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class UploadRecordComponent implements OnInit, DoCheck {

  @Output()
  public json: any = json;


  constructor(
    private router: Router,
    public dataService: DataService,
    public formService: FormService
    ) 
    {}
    
  ngOnInit() {
    // refresca página
    /* this._queriesService.getRefresh(); */

    /* VALORIZACION */
    var URLactual = window.location.href;
    this.json["rating"]['star'].pagina = URLactual

    /* USUARIOS SIN CODPERS */
    if(localStorage.getItem('estado_user_sipa')=="false"){
      this.router.navigate(['/mispublicaciones/confirmado']);
    }

    this.toScroll();
    this.formService.mis_guardados()
  }

  ngDoCheck(){}

  toScroll(){
    document.getElementById('pasos').scrollIntoView({behavior: 'smooth'});
  }

}
