import { Injectable } from '@angular/core';
import jsonVersiones  from '../../assets/json/versiones.json';
import { QueriesService } from '../services/queries.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  postId: string          = null;
  entrada: boolean        = null;
  activarSidebar: string  = 'false';
  editar: string          = "aqui";

  //ENTORNOS
  private PROD: any                   = window.location.protocol +'//'+window.location.hostname;
  private local_prod: string          = "https://sipa.uc.cl";
  private local_lib: string           = "https://sipa-az.lib.uc.cl" /* "https://sipa-lib.cloud.uc.cl"; */
  private local_des: string           = "https://sipa-az.des.uc.cl" /* "https://sipa-des.cloud.uc.cl"; */

  // *****   DECLARAR ENTORNO   *****
  public dominio: string              = this.PROD;
  // ****

  public php: string;
  public php_img: string;
  public php_download: string;
  public phpDiscovery: string;
  public phpDiscovery2: string;
  public phpEmail: string;
  public phpFicha: string;
  public urlLoginCas: string;
  public phpVincular: string;
  public phpGuardar: string;
  public phpDescargar: string;
  public phpTitAlt: string;
  public phpRegistrar: string;
  public phpFormulario:string;
  public phpSql: string;
  public phpPubliAcademicos: string;
  public phpGenerarExcel: string;
  public phpDescargarExcel: string;
  public phpSqlArte: string;
  public phpLanding: string;
  public phpLandingPage: string;
  public phpSuscribirNews: string;
  public phpUltimosAgregados: string;
  public phpUploadFile: string;
  public phpCantidadArticulos: string;
  public phpCantidadCapLibros: string;
  public phpCantidadLibros: string;
  public phpCantidadPatentes: string;
  public urlDesvincular: string;
  public phpCantidadObras: string;
  public urlHandle: string;
  
  public jsonVersiones:any            = jsonVersiones;
  public versionPortal:any            = [];
  /* ficha */
  public error:boolean = false;
  public data:any = [];
  public ficha:any = [];
  public foto:string;
  public embargo:boolean;
  public metadatos_simple:any = []; 

  constructor(
    private queriesService: QueriesService
  ) { }

  public urls(){
    this.php                    = '/assets/php';
    this.urlHandle              = '/handle/';
    if(this.dominio == this.PROD){
      this.php_img              = '/xmlui/bitstream/handle';
      this.php_download         = '/xmlui/bitstream/handle'; 
    }else{
      this.php_img              = ':8080/bitstream/handle';
      this.php_download         = ':8080/bitstream/handle';
    }

      this.php_img              = this.dominio + this.php_img;
      this.php_download         = this.dominio + this.php_download;
      this.urlHandle            = this.dominio + this.urlHandle;
      this.phpDiscovery         = this.dominio + this.php + "/discovery-sipa.php?";
      this.phpDiscovery2        = this.dominio + this.php + "/discovery.php?";
      this.phpEmail             = this.dominio + this.php + "/mail-contacto/envio.php?";
      this.phpFicha             = this.dominio + this.php + "/ficha.php?";
      this.urlLoginCas          = this.dominio + this.php + '/cas-log/cas-login.php';
      this.phpVincular          = this.dominio + this.php + '/vinculacion/VincularSipaWos.php';
      this.phpGuardar           = this.dominio + this.php + '/vinculacion/mis_publicaciones.php';
      this.phpDescargar         = this.dominio + this.php + '/vinculacion/descarga.php';
      this.phpTitAlt            = this.dominio + this.php + '/vinculacion/titulo-alternativo.php';
      this.phpRegistrar         = this.dominio + this.php + '/adjuntos/adjunto.php';
      this.phpFormulario        = this.dominio + this.php + "/formulario-sipa/formulario.php";
      this.phpSql               = this.dominio + this.php + "/formulario-sipa/sql.php";
      this.phpPubliAcademicos   = this.dominio + this.php + "/jefatura/pub-academicos.php";
      this.phpGenerarExcel      = this.dominio + this.php + "/jefatura/generar-excel.php";
      this.phpDescargarExcel    = this.dominio + this.php + "/jefatura/descargar-excel.php?archivo=";
      this.phpSqlArte           = this.dominio + this.php + "/formulario-sipa/sqlArtes.php";
      this.phpLanding           = this.dominio + this.php + "/api-mailchimp/landing.php?page=";
      this.phpLandingPage       = this.dominio + this.php + "/api-mailchimp/landing-page.php";
      this.phpSuscribirNews     = this.dominio + this.php + "/api-mailchimp/suscribir-newsletter.php?email=";
      this.phpUltimosAgregados  = this.dominio + this.php + "/ultimos-agregados.php?";
      this.phpUploadFile        = this.dominio + this.php + "/autoarchivo/subirArchivo.php";
      this.phpCantidadArticulos = this.dominio + this.php + '/cantidad-articulos.php';
      this.phpCantidadCapLibros = this.dominio + this.php + '/cantidad-capituloDeLibros.php';
      this.phpCantidadLibros    = this.dominio + this.php + '/cantidad-libros.php';
      this.phpCantidadPatentes  = this.dominio + this.php + '/cantidad-patentes.php';
      this.phpCantidadObras     = this.dominio + this.php + '/cantidad-obras.php';
      this.urlDesvincular       = `${this.dominio}${this.php}/desvincular.php`;
  }

  public login(){
    if(this.dominio == this.PROD){
      window.location.href='assets/php/cas-log/cas-login.php';
    }else{
      localStorage.setItem('usuario', 'Pantoja, Rodrigo');
      localStorage.setItem('correo', 'rodrigo.pantoja@uc.cl');
      localStorage.setItem('estado_user_sipa', 'true');
      localStorage.setItem('jsonAcademico', 
      '{"NOMBRE_COMPLETO": "RODRIGO PANTOJA","CATEGORIA_ACADEMICA": "PROFESOR ASISTENTE","COD_PERS": "1105052","CORREO": "rodrigo.pantoja@uc.cl","DV": "K","FACULTAD": "FACULTAD DE MEDICINA","RUT":"11.111.111-1","GRADO_PERFECCIONAMIENTO":"ESPECIALIDAD MÉDICA/ ODONTOLÓGICA","PAIS":"Chile","ORCID":"123456789","INSTITUCION":"PUC","UNIDAD_ACADEMICA":"DISEÑO","NOMBRE_PERFIL":["jefaturas"] }');    
      var d1:any = new Date().getTime();
      localStorage.setItem("sessionsipa", d1); 
    }
    
  }

  public logoutCas(){
    localStorage.removeItem('usuario');
    localStorage.removeItem('correo');
    localStorage.removeItem('estado_user_sipa');
    localStorage.removeItem('jsonAcademico');
    window.location.href='assets/php/cas-log/logout-cas.php';
  }
  
  public versionesPortal(){
    this.versionPortal = jsonVersiones;
    for(var i = 0; i < jsonVersiones.length; i++){
      this.versionPortal[i].version = this.jsonVersiones[i].numero_version + "." + this.jsonVersiones[i].numero_mejora_version + "." + this.jsonVersiones[i].numero_item_mejora + "-" + this.jsonVersiones[i].numero_rama
    }
  }

  public dataFicha(handle:number, titulo:string){
    this.metadatos_simple = [];
    this.queriesService.queryGet(this.phpFicha +'handle=' + handle).then((data) => {
      if(data['code'] == 404){
        window.location.href = this.urlLoginCas;
      }else{
        if(data['response']['docs'].length>0){ 
          this.error = false;
          this.ficha = data['response']['docs'][0]; 

          // Cambiar titulo por alternativo
          if(titulo != ""){
            this.ficha['title'][0] = titulo;
          }

          //data['miniatura'];
          if(data['miniatura']){
            this.foto = this.php_img +"/"+data['response']['docs'][0]['handle']+"/"+data['miniatura'];
          }else{
            this.foto = null;
          }

          // Archivo en embargo
          if(data['embargo'] == 200 || data['embargo'] == 201){
            this.embargo = false;
          }else{
            this.embargo = true;
          }

          // Construye tabla metadatos
          let list = this.ficha;
          for(let i in list){
            if (i.substr(0,3)  == 'dc.' && i.indexOf('_') == -1 || i.substr(0,10)=='indexacion' || i.substr(0,10)=='sipa.fechainicio' || i.substr(0,10)=='sipa.fechatermino'){  
              /* cambiar nombres */
              var alias        = i;
              var link         = false;
              var mostrar      = true;
              var orden:number = 100000000;

              /* Orden de prioridad */
              if(i == "dc.contributor.author"){
                alias          = "Autor";
                link           = false;
                orden          = 1;
              }
              if(i == "dc.contributor.advisor"){
                alias          = "Profesor guía"; 
                link           = false;
                orden          = 2;
              } 
              if(i == "dc.contributor.editor"){
                alias          = "Editor";
                link           = false;
                orden          = 3;
              }  
              if(i == "dc.contributor.illustrator"){
                alias          = "Ilustrador";
                link           = false;
                orden          = 4;
              } 
              if(i == "dc.contributor.participante"){
                alias          = "Participante";
                link           = false;
                orden          = 5;
              } 
              if(i == "dc.contributor.other"){
                alias          = "Otro autor"; 
                link           = false;
                orden          = 6;
              }  
              if(i == "dc.title"){
                alias = "Título"; 
                link = false;
                orden          = 7;
              } 
              if(i == "dc.title.alternative"){
                alias = "Otro título"; 
                link = false;
                orden          = 8;
              } 
              if(i == "dc.description.uri"){
                alias          = "Video"; 
                link           = true;
                orden          = 9;
              } 
              if(i == "dc.revista"){
                alias = "Revista"; 
                link = false;
                orden          = 10;
              } 
              if(i == "dc.identifier.issn"){
                alias          = "ISSN";
                link           = false; 
                orden          = 11;
              } 
              if(i == "dc.identifier.eissn"){
                alias          = "ISSN electrónico"; 
                link           = false;
                orden          = 12;
              } 
              if(i == "dc.volumen"){
                alias = "Volumen"; 
                link = false;
                orden          = 13;
              } 
              if(i == "dc.issue.numero"){
                alias = "Número de publicación"; 
                link = false;
                orden          = 14;
              }
              if(i == "dc.article.number"){
                alias          = "Número de artículo"; 
                link           = false;
                orden          = 15;
              } 
              if(i == "dc.relation.ispartofseries"){
                alias = "Serie"; 
                link = false;
                orden          = 16;
              } 
              if(i == "dc.identifier.isbn"){
                alias          = "ISBN"; 
                link           = false;
                orden          = 17;
              } 
              if(i == "dc.identifier.eisbn"){
                alias          = "ISBN electrónico"; 
                link           = false;
                orden          = 18;
              } 
              if(i == "dc.pagina.inicio"){
                alias = "Página inicio"; 
                link = false;
                orden          = 19;
              }
              if(i == "dc.pagina.final"){
                alias = "Página final"; 
                link = false;
                orden          = 20;
              } 
              if(i == "dc.date.issued"){
                alias          = "Fecha de publicación";
                link           = false;
                orden          = 21;
              }
              if(i == "dc.description"){
                alias          = "Nota"; 
                link           = false;
                orden          = 22;
              } 
              if(i == "dc.identifier.citation"){
                alias          = "Cómo citar este documento"; 
                link           = false;
                orden          = 23;
              } 
              if(i == "dc.description.abstract"){
                alias          = "Resumen"; 
                link           = false;
                orden          = 24;
              } 
              if(i == "dc.rights"){
                alias = "Derechos"; 
                link = false;
                orden          = 25;
              } 
              if(i == "dc.rights.license"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
                orden          = 26;
              } 

              /* Orden alfabético */
              if(i == "dc.areadearte"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.catalogador"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.concurso"){
                alias          = "Concurso Arte y Cultura"; 
                link           = false;
              } 
              if(i == "dc.conservacion.estado"){
                alias          = "Conservación"; 
                link           = false;
              }    
              if(i == "dc.contributor"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.coverage.spatial"){
                alias          = "Cobertura geográfica";
                link           = false;
              } 
              if(i == "dc.coverage.temporal"){
                alias          = "Cobertura temporal";
                link           = false;
              } 
              if(i == "dc.creator"){
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.cultura"){
                alias          = "Cultura";
                link           = false;
              } 
              if(i == "dc.date"){
                alias          = "Fecha de embargo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.date.accessioned"||i == "dc.date.available" || i == "dc.date.submitted"){ /* eliminar ??? */
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.date.concesion"){
                alias          = "Fecha de concesión";
                link           = false;
              }
              if(i == "dc.date.copyright"){
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.date.created"){
                alias          = "Fecha de creación";
                link           = false;
              }
              if(i == "dc.date.updated"){
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.description.funder"){
                alias          = "Financiamiento"; 
                link           = false;
              } 
              if(i == "dc.description.lineas"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.description.provenance"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.description.sponsorship"){
                alias          = "Patrocinio"; 
                link           = false;
              } 
              if(i == "dc.description.statementofresponsibility"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.description.tableofcontents"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.description.version"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.destinatario"){
                alias          = "Destinatario"; 
                link           = false;
              } 
              if(i == "dc.donante"){
                alias          = "Donante"; 
                link           = false;
              } 
              if(i == "dc.estado.patente"){
                alias          = "Estado de patentamiento"; 
                link           = false;
              } 
              if(i=="dc.estamento1" ||i=="dc.estamento2" ||i=="dc.estamento3" ||i=="dc.estamento4"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i=="dc.estamento.1" ||i=="dc.estamento.2" ||i=="dc.estamento.3" ||i=="dc.estamento.4"){
                alias          = "nulo"; 
                link           = false;
              } 
              if(i == "dc.fechaingreso.objetodigital"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.format"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.format.extent"){
                alias          = "Paginación"; 
                link           = false;
              } 
              if(i == "dc.format.medium"){
                alias          = "Medio"; 
                link           = false;
              } 
              if(i == "dc.format.mimetype"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.fuente.origen"){ 
                alias          = "Fuente"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.funcion"){
                alias          = "Función"; 
                link           = false;
              } 
              if(i == "dc.identifier"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i=="dc.identifier.codUA"||i=="dc.identifier.codUA1"||i=="dc.identifier.codUA2"||i=="dc.identifier.codUA3"||i=="dc.identifier.codUA4"||i=="dc.identifier.codUA5"||i=="dc.identifier.codUA6"||i=="dc.identifier.codUA7"||i=="dc.identifier.codUA8"||i=="dc.identifier.codUA9"||i=="dc.identifier.codUA10"){
                alias          = "Unidad académica"; 
                link           = false;
              } 
              
              if(i == "dc.identifier.concesion"){
                alias          = "Número de concesión"; 
                link           = false;
              } 
              if(i == "dc.identifier.converisid"){
                alias          = "Id de publicación en Converis"; 
                link           = false;
              } 
              if(i == "dc.identifier.dialnetid"){
                alias          = "Id de publicación en Dialnet"; 
                link           = false;
              } 
              if(i == "dc.identifier.doi"){
                alias          = "DOI"; 
                link           = true;
              } 
              if(i == "dc.identifier.govdoc"){
                alias          = "Número de solicitud"; 
                link           = false;
              } 
              
              if(i == "dc.identifier.ismn"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.identifier.other"){
                alias          = "Identificador del recurso";
                link           = false; 
              }
              if(i == "dc.identifier.pubmedid"){
                alias = "Id de publicación en Pubmed";
                link = false; 
              } 
              if(i == "dc.identifier.scieloid"){
                alias = "Id de publicación en Scielo";
                link = false; 
              } 
              if(i == "dc.identifier.scopusid"){
                alias = "Id de publicación en Scopus";
                link = false; 
              } 
              if(i == "dc.identifier.sici"){
                alias = "nulo";
                link = false; 
                mostrar = false;
              } 
              if(i == "dc.identifier.slug"){
                alias = "nulo";
                link = false; 
                mostrar = false;
              } 
              if(i == "dc.identifier.UA"){
                alias = "Unidad Académica";
                link = false; 
              } 
              if(i == "dc.identifier.orcid"||i == "dc.identifier.orcid1"||i == "dc.identifier.orcid2"||i == "dc.identifier.orcid3"||i == "dc.identifier.orcid4"||i == "dc.identifier.orcid5"||i == "dc.identifier.orcid6"||i == "dc.identifier.orcid7"||i == "dc.identifier.orcid8"||i == "dc.identifier.orcid9"||i == "dc.identifier.orcid10"){
                alias = "Número ORCID";
                link = false;
              } 
              if(i == "dc.identifier.uri"){
                alias = "Enlace";
                link = true;
              } 
              if(i == "dc.identifier.wosid"){
                alias = "Id de publicación en WoS";
                link = false;
              } 
              if(i == "dc.information.autoruc"){
                alias = "Información del autor UC";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.language"){
                alias = "Idioma";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.language.iso"){
                alias = "nulo"; // Código ISO del idioma de la publicación
                link = false;
              } 
              if(i == "dc.language.rfc3066"){
                alias = "nulo";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.localidad"){
                alias = "Localidad";
                link = false;
              } 
              if(i == "dc.lugar.publicacion"){
                alias = "Lugar de publicación";
                link = false;
              } 
              if(i == "dc.material"){
                alias = "Material";
                link = false;
              } 
              if(i == "dc.nota"){
                alias = "nulo";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.nota.acceso"){
                alias = "nulo";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.pagina.pais"){
                alias = "Pais"; 
                link = false;
              } 
              if(i == "dc.pais"){
                alias = "Pais"; 
                link = false;
              } 
              if(i == "dc.presentacion"){
                alias = "Tipo de presentación de patente"; 
                link = false;
              } 
              if(i == "dc.provenance"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.publisher"){
                alias = "Editorial"; 
                link = false;
              } 
              if(i == "dc.region"){
                alias = "Región"; 
                link = false;
              } 
              if(i == "dc.relation"){
                alias = "Publicación relacionada"; 
                link = false;
              }
              if(i == "dc.relation.haspart"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              }
              if(i == "dc.relation.hasversion"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              }
              if(i == "dc.relation.isbasedon"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              }
              if(i == "dc.relation.isformatof"){
                alias = "Se encuentra en"; 
                link = false;
              } 
              if(i == "dc.relation.isreferencedby"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.isreplacedby"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.isversionof"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.replaces"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.requires"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.ispartof"){
                alias = "Publicado en / Colección"; 
                link = false;
              } 
              if(i == "dc.rights.access"){
                alias = "Tipo de Acceso"; 
                link = false;
              } 
              if(i == "dc.rights.holder"){
                alias = "Titular de los derechos"; 
                link = false;
              } 
              if(i == "dc.rights.uri"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.rubro"){
                alias = "Rubro"; 
                link = false;
              } 
              if(i == "dc.solicitante"){
                alias = "Nombre de solicitante"; 
                link = false;
              } 
              if(i == "dc.source"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.source.uri"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.uri"){
                alias = "Enlace relacionado";
                link = true;
              } 
              if(i == "dc.subject"){
                alias = "Palabra clave"; 
                link = false;
              } 
              if(i == "dc.subject.classification"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.ddc"){
                alias = "Número de clasificación Dewey"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.dewey"){
                alias = "Temática"; 
                link = false;
              } 
              if(i == "dc.subject.lcc"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.lcsh"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.mesh"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.ods"){
                alias = "Tema ODS"; 
                link = false;
              } 
              if(i == "dc.subject.odspa"){
                alias = "Tema ODS español"; 
                link = false;
              } 
              if(i == "dc.subject.other"){
                alias = "Materia"; 
                link = false;
              } 
              if(i == "dc.tecnica"){
                alias = "Técnica"; 
                link = false;
              } 
              if(i == "dc.territorialidad"){
                alias = "Territorialidad"; 
                link = false;
              } 
              if(i == "dc.type"){
                alias = "Tipo de documento"; 
                link = false;
              } 
              if(i == "dc.type.qualificationlevel"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.ubicacion"){
                alias = "Ubicación"; 
                link = false;
              } 
              if(i == "dc.uc.hito"){
                alias = "Hito UC"; 
                link = false;
              } 
              if(i == "dc.zcode"||i == "dc.zcode.1" || i == "dc.zcode.2" || i == "dc.zcode.3"|| i == "dc.zcode.4"|| i == "dc.zcode.5"|| i == "dc.zcode.6"|| i == "dc.zcode.7"|| i == "dc.zcode.8"|| i == "dc.zcode.9"|| i == "dc.zcode.10"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "sipa.fechainicio"){
                alias = "Fecha de inicio"; 
                link = false;
              } 
              if(i == "sipa.fechatermino"){
                alias = "Fecha de término"; 
                link = false;
              } 
              /* if(i == "sipa.index"){
                alias = "Indexación"; 
                link = false;
              } 
              if(i == "sipa.otra.index"){
                alias = "Indexación"; 
                link = false;
              }  */
              if(i == "indexacion"){
                alias = "Indexación"; 
                link = false;
              } 
              if(i == "sipa.afi.uc"||i == "sipa.codpersvinculados" || i == "sipa.fecha.validacionbiblio" || i == "sipa.fecha.validavinculacion"|| i == "sipa.fecha.vinculacion"|| i == "sipa.identifier.ha"|| i == "sipa.identifier.propintelectual"|| i == "sipa.identifier.repositoriouc"|| i == "sipa.identifier.solicpropintelectual"|| i == "sipa.trazabilidad"|| i == "sipa.validacionbiblio"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.description.auspiciador"){ /* Agregado nuevo el 29 may 2023 */
                alias = "Institución auspiciadora"; 
                link = false;
              } 
              if(i == "dc.description.caracterobra"){ /* Agregado nuevo el 29 may 2023 se cambia nombre 2024*/
                alias = "Carácter de la curatoría"; 
                link = false;
              } 
              if(i == "dc.tipodeobra"){ /* Agregado nuevo el 29 may 2023 */
                alias = "Tipo de Obra"; 
                link = false;
                mostrar = true;
              } 
              if(i == "sipa.tipocreacion"){ /* Agregado nuevo el 29 may 2023 */
                alias = "Tipo de creación"; 
                link = false;
              } 
              if(i == "dc.rol"){ /* Agregado el 29 may 2023 */
                alias = "Rol"; 
                link = false;
              } 

              /* CAMBIO NOMBRE METADATOS REGISTRO COMPLETO  */
              if(i == "indexacion"){ /* i == "dc.title" ||  */
                if(i == "indexacion"){
                  this.metadatos_simple.push( { "metadato": alias, "cod":'sipa.index', "valor": list[i], "link":link, "mostrar":mostrar, "order": orden });
                }
              }else{
                this.metadatos_simple.push( { "metadato": alias, "cod":i, "valor": list[i], "link":link, "mostrar":mostrar, "order": orden });
              }
            }
          }

          /* ORDEN ALFABETICO DE ALIAS O REGISTRO SENCILLO */
          this.metadatos_simple.sort((a:any, b:any) => (a.metadato > b.metadato) ? 1 : -1);
          // AHORA ORDENAR DE MENOR A MAYOR
          this.metadatos_simple.sort((a:any, b:any) => 
            a.order - b.order
          );
          
          /* console.log("metadatos_simple dataservice",this.metadatos_simple) */
          

        }else{
          this.error = true;
        }
      }
      
    });

  }
}
