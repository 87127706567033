import { Component, OnInit, DoCheck } from '@angular/core';
import { QueriesService }             from '../../services/queries.service';

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.css']
})
export class MisDatosComponent implements OnInit, DoCheck {

  /*loggedIn: any = false;
  usuario;
  correo;
  unidad;
   public datos_academicos:any = []; */
  public datos_academicos = JSON.parse(this.queriesService.getjsonAcademico());
  
  constructor(
    private queriesService: QueriesService
  ) { }

  ngOnInit() {
    //document.getElementById('top').scrollIntoView({behavior: 'smooth'}); // Scroll hacia buscador
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
  }
  ngDoCheck(){

    // COMPUEBO LOGIN
    /* if(localStorage.getItem('correo')){
      this.loggedIn = true;
      this.usuario = localStorage.getItem('usuario');
      this.correo = localStorage.getItem('correo');
      this.unidad = localStorage.getItem('unidad');
      if(localStorage.getItem('jsonAcademico')==""){
        this.datos_academicos = false;
      }else{
        this.datos_academicos = JSON.parse(localStorage.getItem('jsonAcademico'));
      }
      
    }else{
      this.loggedIn = false;
    } */

  }

}
