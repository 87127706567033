import { Component, OnInit }          from '@angular/core';
import { ActivatedRoute, Params }     from '@angular/router';
import { Title, Meta }                from '@angular/platform-browser';
import {Location}                     from '@angular/common';
import { QueriesService }           from '../../services/queries.service';
import { HttpClient }                 from '@angular/common/http';

@Component({
  selector: 'app-descarga-ga',
  templateUrl: './descarga-ga.component.html',
  styleUrls: ['./descarga-ga.component.css']
})
export class DescargaGaComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute, 
    private meta: Meta,
    private title: Title,
    private _location: Location,
    private queriesService: QueriesService,
    private http: HttpClient,
  ) { 
  }

  ngOnInit() {
    // refresca página
    this.queriesService.getRefresh();

    // Recibo nombre de la página por get (descarga-ficha/nombrepagina)
    this.activatedRoute.params.subscribe(params =>{
      // Pone nombre de la página en title
      this.title.setTitle( params['titulo'] );
    });

    this._location.back(); 

  }

}
