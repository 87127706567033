import { Component, OnInit, Input }        from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Router }                   from '@angular/router';
import { QueriesService }           from '../../services/queries.service';
import json                         from './single-record.json';
import { Title, Meta }              from '@angular/platform-browser';
import {Location}                   from '@angular/common';
import { ThrowStmt }                from '@angular/compiler';
import { DataService }              from '../../services/data.service';

@Component({
  selector: 'app-single-record',
  templateUrl: './single-record.component.html',
  styleUrls: ['./single-record.component.css']
})
export class SingleRecordComponent implements OnInit {
  data: any = [];
  json: any = json;
  public error = false;
  public urlFicha: string;
  public urlImg;
  public urldownload;
  public urldescargar;
  metadatos: any = [];
  metadatos_simple: any = [];
  metadatos_origen: any = [];

  public_id: any = '1000';

  response: any = {
    'show': false,
    'data': ''
  };
  liked: any = false;
  public meta_datos = true;
  public embargo;
  public foto;
  public tipodata = "sencillo";
  

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private queriesService: QueriesService,
    private meta: Meta,
    private title: Title,
    private _location: Location,
    public dataService: DataService
    ) { 
      this.dataService.urls();
      this.urlFicha         = this.dataService.phpFicha;
      this.urlImg           = this.dataService.php_img;
      this.urldownload      = this.dataService.php_download;
      this.urldescargar      = this.dataService.phpDescargar;
      
    }

  ngOnInit() {
    // refresca página
    /* this.queriesService.getRefresh(); */
    
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);

    // Variables para devolverse con datos anteriores
    localStorage.setItem('ficha','true');
    if(localStorage.getItem('json_filtros')=="[]"){
        localStorage.setItem('filtroActivo','false');
        localStorage.setItem('title','Búsqueda simple');
    }else{
        localStorage.setItem('ficha','true');
        localStorage.setItem('filtroActivo','true');
        localStorage.setItem('title','Búsqueda avanzada');
    }

      this.activatedRoute.params.subscribe(params =>{
        this.data['param1'] = params['param1'];
        this.data['param2'] = params['param2'];
        this.data['param'] = this.data['param1'] + "/" + this.data['param2']

        /* Redireccion si deslogin  QUITÉ GUARD DE ROUTING*/
        let identity = this.queriesService.getToken();
        if(identity){
          this.dataService.dataFicha(this.data['param'], "");
        }else{
            window.location.href='assets/php/cas-log/cas-login.php?ruta=handle/' + this.data['param'];
        }
      }); 

    
  }

  metadatas(reg){
    this.tipodata = reg;
  }

  likeRecord() {
    this.queriesService.queryGet('http://localhost:3000/likes').then((data) => {
      this.liked = data['liked'];
      this.queriesService.queryPost('http://localhost:3000/likes', {liked: !data['liked']});
    });
  }

  resetForm() {
    this.response.show = false;
    this.response.text = '';
  }

  submitComment(form, $event) {
    $event.preventDefault();
    if(form.form.value) {
      let date = new Date();
      let full_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
      let comment = {
        user: "Napoleón Gómez", //Reemplazar por el usuario actual que haya iniciado sesión
        date: full_date,
        text: form.form.value['comment_textarea']
      }
      /* this.queriesService.queryPost('http://localhost:3000/comments', comment).then(
        (data) => {
          this.queriesService.queryGet('http://localhost:3000/comments').then((data) => {this.data['comments'] = data});
          this.response.show = true;
          this.response.text = 'Su comentario ha sido publicado con éxito';
        },
        (error) => {
          this.response.show = true;
          this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
        }
      ); */
    }
  }

  descargaGA(file){
    console.log(file);
    /* this.data['tit'] = this.data['publics']['title'][0];
    this.router.navigate(['/descarga-ficha/'+this.data['tit']]); */
  }
  getSearch() {
    localStorage.setItem('search_form','');
    localStorage.setItem('json_filtros',"[]");
    localStorage.setItem('filtroActivo','false');
    localStorage.setItem('title','Búsqueda simple');
    localStorage.setItem('page','1' );
    this.router.navigate(['/busqueda']);
  }
  BusquedaAvanzada(search_by, term) {
    var array_Filtros: any[] = [
      {
        search_by: search_by,
        contains: 'es',
        term: term
      }
    ];
    localStorage.setItem('searchAdvanced','true');
    localStorage.setItem('search_form','');
    localStorage.setItem('json_filtros',JSON.stringify(array_Filtros));
    
    this.router.navigate(['/busqueda']);
  }


}
