import { Component, OnInit, DoCheck, ɵConsole, EventEmitter, Output } from '@angular/core';
import { animate, state, style, transition, trigger }                 from '@angular/animations';
import { ActivatedRoute, Params }             from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router }                             from '@angular/router';
import json                                   from '../../../assets/json/search-advanced/search-advanced.json';
import { QueriesService }                     from '../../services/queries.service';
import { DataService }                        from 'src/app/services/data.service.js';
import { HttpClient }                         from '@angular/common/http';
import { from } from 'rxjs';
/* import { ConsoleReporter } from 'jasmine'; */

declare var jQuery  :any;
declare var $       :any;

@Component({
  selector    : 'app-busqueda',
  templateUrl : './busqueda.component.html',
  styleUrls   : ['./busqueda.component.css'],
  animations  : [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class BusquedaComponent implements OnInit, DoCheck {

  @Output()
  propagar                        = new EventEmitter<Object>();
  public json: any                = json;
  public data: any                = [];
  public urlLoginCas;
  public urlDiscovery;
  public urlImg;
  public urlFicha;
  public urldownload;
  public urlHandle;
  public urlvincular;
  public urlGuardar; 
  public urldescargar;
  public urlfile;
  public valorSearch              = "";
  public array_Filtros: any[]     = [];
  public array_resumen: any[]     = [];
  public array_email_search: any  = {
    mensaje   : null,
    nombre    : this.queriesService.getUser(),
    email     : this.queriesService.getToken(),
    archivos  : null
  }
  public filtro: any;
  public registros;
  public totalPage;
  public page;
  public npage: any               = 1;
  public cantidadReg;
  public paginacion               = [];
  public nextPage;
  public prevPage;

  public tipodoc: any             = [];
  public autores: any             = [];
  public tematicas: any           = [];
  public materias: any            = [];

  public facetas_desde;
  public facetas_hasta;
  public orden;
  public entrada;
  public cargando;
  public cargando_solicitar;
  public msj;
  public msj_solicitar;
  public msj_file;
  public condicion_publicar       = null;
  public modal                    = false;
  public modal_ficha              = false;
  public tituloModal:string;
  public ver_mas=false;
  public array_vincular: any      = [];
  public emailSolicitar           = null;
  public activePane: any          = 't1';
  public msjEmail;
  public msjSend;
  public msjBuscar;
  public numenvios                = 0;
  public totalenvios              = 0;
  public pasos                    = false;
  public ordenlista               = 'pubrecientes';

  /* ficha */
  public foto;
  public embargo;
  public metadatos_simple:any     = [];
  public error                    = false;
  public tipodata                 = "sencillo";

  /* email */
  public urlEmail;
  public response: any            = {
    'show': false,
    'data': ''
  }
  public message;
  public usuario                  = this.queriesService.getUser()
  public correo                   = this.queriesService.getToken();
  public name_usuario;
  public codpers;
  public bolsaVincula             = true;
  public guardados;
  public array_btn_vincular: any  = [];
  public num_vinculaciones        = null;
  public myFiles:string []        = [];
  public form_help_search;
  public estado_user_sipa;
  public no_enviados              = 0;
  public views                    = 'lista';

  private myTemplate: any = "";

  constructor(
    private queriesService        : QueriesService,
    private activatedRoute        : ActivatedRoute,
    public dataService            : DataService,
    private router                : Router,
    private http                  : HttpClient
  ) { 
    this.dataService.urls();
    this.urlLoginCas              = this.dataService.urlLoginCas;
    this.urlImg                   = this.dataService.php_img;
    this.urlDiscovery             = this.dataService.phpDiscovery;
    this.urlEmail                 = this.dataService.phpEmail;
    this.urlFicha                 = this.dataService.phpFicha;
    this.urlHandle                = this.dataService.urlHandle;
    this.urldownload              = this.dataService.php_download;
    this.urlvincular              = this.dataService.phpVincular; 
    this.urlGuardar               = this.dataService.phpGuardar; 
    this.urldescargar             = this.dataService.phpDescargar; 

  }
  
  ngOnInit() {
    /* this.queriesService.getRefresh(); */

    /* USUARIOS SIN CODPERS */
    if(localStorage.getItem('estado_user_sipa') == "false"){
      this.router.navigate(['/mispublicaciones/confirmado']);
    }
  

    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);

    /* VALORIZACION */
    var URLactual = window.location.href;
    this.json["rating"]['star'].pagina = URLactual

    /* Datos usuario perfil Desde CAS */
    let split                 = this.usuario.split(',');
    this.name_usuario         = split[1];
    if(this.queriesService.getjsonAcademico()){
      let perfil              = JSON.parse(this.queriesService.getjsonAcademico());
      this.codpers            =  perfil.COD_PERS;
    }

    /* Declainer */
    this.dataService.entrada  = false;
    this.tituloModal          = "Términos y condiciones del servicio";
    if(localStorage.getItem("declainer")){
      this.condicion_publicar = localStorage.getItem("declainer");
      this.modal              = false;
    }else{
      this.condicion_publicar = null;
      this.modal              = true;
    }

    this.data['filtroActivo'] = false;
    this.npage                = 1;
    this.entrada              = false;

    /* Bolsa de vinculaciones */
    if(localStorage.getItem("vinculaciones")){
      this.num_vinculaciones  = JSON.parse(localStorage.getItem("vinculaciones")).length;
      this.array_vincular     = JSON.parse(localStorage.getItem("vinculaciones"));
    }
    var altura                = 600;   
    $(window).on('scroll', function(){
      if ( $(window).scrollTop() > altura && $(window).scrollTop()<document.body.offsetHeight-1500 && screen.width > 1200){
          $('.mainBolsa').addClass('mainBolsa-fixed');
          $('.headerBolsa').addClass('headerBolsa-fixed');
          $('.footerBolsa').addClass('footerBolsa-fixed');
      } else {
          $('.mainBolsa').removeClass('mainBolsa-fixed');
          $('.headerBolsa').removeClass('headerBolsa-fixed');
          $('.footerBolsa').removeClass('footerBolsa-fixed');
      }
    });

    this.mis_guardados();

  }

  ngDoCheck(){

  }

  toScroll(){
    document.getElementById('pasos').scrollIntoView({behavior: 'smooth'});
  }

  refresh(){
    this.queriesService.getRefresh();
  }

  seleccionarArchivo(event) {
    this.msj_file                       = null;
    /* Cargo archivos en array myFiles */
    if(!this.array_email_search.archivos){
      this.array_email_search.archivos  = [];
    }

    for (var i = 0; i < event.target.files.length; i++) {    
      if(event.target.files[i].size > 10000000){
        this.msj_file                   = "El archivo debe ser inferior a 10mb";
      }else{
        this.myFiles.push(event.target.files[i]);
        this.array_email_search.archivos.push({
          archivo: event.target.files[i].name
        });
      }
    }
  }

  removeFile(i: number) {
    this.array_email_search.archivos.splice(i, 1);
    this.myFiles.splice(i, 1);
    if(this.array_email_search.archivos.length == 0){
      this.array_email_search.archivos = null;
    }
  }

  resetFile(){
    for (var i = 0; i < this.array_email_search.archivos.length; i++) { 
      this.array_email_search.archivos[i].archivo = null;
    }
  }

  placeholder(){
      $('.label-default').addClass('label-top');
  }

  menu(event){
    this.pasos          = false;
    this.data['search'] = false;
    this.bolsaVincula   = false;
  }

  condiciones(event){
    if(event.target.checked){
      this.condicion_publicar = event.target.value;
      localStorage.setItem("declainer",this.condicion_publicar );
    }else{
      this.condicion_publicar = null;
      localStorage.removeItem("declainer");
      this.modal              = true;
    }
  }

  abreModal(){
    this.modal = true; 
  }

  cerrarModal(){
    this.condicion_publicar = "condiciones";
    localStorage.setItem("declainer",'condiciones' );
    this.modal              = false;
  }

  abreModal_ficha(i,handle, titulo){ 
    this.modal_ficha = true;
    this.dataService.dataFicha(handle, titulo);
  }

  cerrarModal_ficha(){
    this.metadatos_simple = [];
    this.modal_ficha      = false;
  }
  cerrarModal_declainer(){
    this.modal = false;
  }
  metadatas(reg){
    this.tipodata = reg;
  }

  
  registra(){
    this.propagar.emit("registrar");
  }

  buscar(page,filtro, orden) {
    document.getElementById('buscador').scrollIntoView({behavior: 'smooth'});
    this.cargando             = true;
    this.msjBuscar                  = null;
    this.dataService.entrada  = true;
    let str_filtros:any       = '';
    let urlFiltro;
    this.npage                = page;
    localStorage.setItem('page',page );
    page                      = (page-1)*20;
    this.orden                = orden;

    // SI VIENE DE ESTRA PÁGINA
    if(filtro=="busqueda"){
      localStorage.setItem('search_form',this.valorSearch);
    }
    // SI VIENE DE HOME AREAS TEMATICAS Y PAGINAS TESIS Y PUBLICACIONES
    if(localStorage.getItem('searchAdvanced') == 'true'){
      this.data['title']        = 'Búsqueda avanzada';
      this.data['filtroActivo'] = true;
      this.array_Filtros        = JSON.parse(localStorage.getItem('json_filtros'));
      localStorage.removeItem('searchAdvanced');
    }
    
    this.valorSearch            = localStorage.getItem('search_form');
    localStorage.setItem('json_filtros',JSON.stringify(this.array_Filtros));

    // RECORRO ARRAY array_Filtros DE FILTROS
    for(let i=0; i < this.array_Filtros.length; i++){
      this.array_Filtros[i]['term'] =  this.array_Filtros[i]['term'];
      let termm                     = this.array_Filtros[i]['term'];
      let term                      = termm.replace(".", "").replace(":", "").replace("-", " ");
      // Construyo cadena de filtros
      switch (this.array_Filtros[i]['search_by']) {

        case 'titulo':
          
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            this.filtro = 'fq=-dc.title:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            this.filtro = 'fq=dc.title:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=dc.title:"'+ termm +'"%26';
          }
        break;

        case 'autor':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dc.contributor.author:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dc.contributor.author:'+ term +'*%26'; */
            /* this.filtro = 'fq=-dc.contributor.author:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.contributor.author:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dc.contributor.author:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=dc.contributor.author:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.contributor.author:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=dc.contributor.author:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=bi_2_dis_value_filter:"'+ termm +'"%26';
            /* this.filtro = 'fq=bi_2_dis_value_filter:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=bi_2_dis_value_filter:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
        break;
        
        case 'fecha':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dateIssued:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dateIssued:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=-dateIssued:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dateIssued:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dateIssued:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=dateIssued:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=dateIssued:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=dateIssued:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=dateIssued:"'+ termm +'"%26';
            /* this.filtro = 'fq=dateIssued:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=dateIssued:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
        break;

        case 'tema':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dc.subject.dewey:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dc.subject.dewey:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=-dc.subject.dewey:'+ term +'* OR' +' dc.subject.other:'+ term +'*%26';  */ 
            /* this.filtro = 'fq=-dc.subject.dewey:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.subject.dewey:'+ term + ' OR ' +'dc.subject.other:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dc.subject.dewey:"'+ term +'"%26'; */
            /* this.filtro = 'fq=dc.subject.dewey:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.subject.dewey:'+ term +'* OR' +' dc.subject.other:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.subject.dewey:\"'+ term +'\"%26'; */
            this.filtro = 'fq=dc.subject.dewey:'+ term + ' OR ' +'dc.subject.other:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=bi_4_dis_value_filter:"'+ termm +'"%26';
            
            /* this.filtro = 'fq=bi_4_dis_value_filter:'+ term +'*%26'; */
            /* this.filtro = 'fq=bi_4_dis_value_filter:\"'+ term +'\"%26'; */
          }
        break;

        case 'tipo':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dc.type:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dc.type:'+ term +'*%26'; */
            /* this.filtro = 'fq=-dc.type:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.type:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dc.type:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=dc.type:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.type:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=dc.type:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=dc.type:"'+ termm +'"%26';
            /* this.filtro = 'fq=dc.type:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=dc.type:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
        break;


      } 
      str_filtros=str_filtros + this.filtro;
    }
    urlFiltro = this.urlDiscovery + 'filtro=' + str_filtros +  '&valor=' + localStorage.getItem('search_form') + '&start=' + this.npage + '&orden=' + this.orden + '&email=' + this.correo + '&codpers=' + this.codpers;


    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++


    this.queriesService.queryGet( urlFiltro )
      .then((data) => { 
        this.cargando = false; 

        if(data['code'] == 404){
          window.location.href = this.urlLoginCas;
        }else{
          this.data['search']= data['Doc']; 
          
          if(this.data['search'].length>0 ){
            this.array_btn_vincular=[];
            if(localStorage.getItem("vinculaciones")){
              this.bolsaVincula = true;
              this.array_vincular=JSON.parse(localStorage.getItem("vinculaciones"));
              for(let i=0; i<this.data['search'].length; i++){
                for(let a=0; a < this.array_vincular.length; a++){
                  if(this.data['search'][i].handle==this.array_vincular[a].handle){
                    var btn = true
                    break;
                  }else{
                    var btn = false
                  }
                }
                this.array_btn_vincular.push({
                  btn: btn
                });
              }
            }else{
              for(let i=0; i<this.data['search'].length; i++){
                this.array_btn_vincular.push({
                  btn: false
                });
              }
            }

          }else{
            this.msjBuscar="No existen registros con ese criterio de búsqueda.";
          }

          // PAGINACIÓN
          this.totalPage = data['RecordsFound'];
          this.cantidadReg = 20;
          this.page  = Math.ceil(this.totalPage / this.cantidadReg); 
          

          this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
          for(let i=1; i<=this.page; i++){
            if(i <= 5){
              if(this.npage>5){
                this.paginacion.push(i+(this.npage-5));
              }else{
                this.paginacion.push(i);
              }
            }
          }
          if(this.npage>=2){
            this.prevPage = this.npage-1;
          }else{
            this.prevPage = 1;
          }
          if(this.npage<this.page){
            this.nextPage = this.npage+1;
          }else{
            this.nextPage = this.page;
          }
          // Fin paginación
        }
    }, err => {
      this.cargando = false;
      this.msjBuscar = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    });


  }
  busquedaAvanzada(){
    this.data['filtroActivo'] = true;
    this.data['title']        = 'Búsqueda avanzada';
    this.addFiltros();
  }
  addFiltros() {  
    this.array_Filtros.push({
      search_by : '',
      contains  : '',
      term      : ''
    });
  }
  removefiltros(i: number) {
    this.array_Filtros.splice(i, 1);
    if(this.array_Filtros.length == 0){
      this.data['filtroActivo'] = false;
      this.data['title']        = 'Búsqueda simple';
    }
  }

  /* Desde menu facetas despliega todas las facetas */
  searchFacetas(param, num){
    this.data['listFacetas'] = true;
    this.data['search'] = false;
    if(param=='tipodoc'){
      this.data['facetas'] = this.tipodoc;
      this.data['tipo'] = 'tipo';
    }
    if(param=='autores'){
      this.data['facetas'] = this.autores;
      this.data['tipo'] = 'autor';
    }
    if(param=='tema'){
      this.data['facetas'] = this.tematicas;
      this.data['tipo'] = param;
    }

                  // PAGINACIÓN
                  this.totalPage = this.data['facetas'].length;
                  this.cantidadReg = 50;
                  this.npage = num;
                  num = (num-1)*20;
                  this.page  = Math.ceil(this.totalPage / this.cantidadReg); 
                  this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
                  this.facetas_desde= (this.npage-1) * this.cantidadReg;
                  this.facetas_hasta = (this.npage) * this.cantidadReg;

                  for(let i=1; i<=this.page; i++){
                    if(i <= 5){
                      if(this.npage>5){
                        this.paginacion.push(i+(this.npage-5));
                      }else{
                        this.paginacion.push(i);
                      }
                    }
                  }
                  if(this.npage>=2){
                    this.prevPage = this.npage-1;
                  }else{
                    this.prevPage = 1;
                  }
                  if(this.npage<this.page){
                    this.nextPage = this.npage+1;
                  }else{
                    this.nextPage = this.page;
                  }
                  // Fin paginación
  }
  /* Desde lista de resutados de facetas despliega lista de resultados de documentos */
  getFacetas(faceta,item){
    this.data['filtroActivo'] = true;
    this.data['title'] = 'Búsqueda avanzada';
    let splits = faceta.split('|||'); // string pasa a array si Separador es |||
    if(splits.length > 1) {
      faceta = splits.pop(); // elimina último elemento de un array
    }
    this.array_Filtros.push({
      search_by: item,
      contains: 'es',
      term: faceta
    });
    this.buscar(1,item, 'asc');
  }

  // GUARDADOS SIN ENVIAR
  mis_guardados(){ 
    this.cargando   = true;
    this.msj        = null;
    var acciones;
    const formData  = new FormData();
    acciones        = {accion: "consultar", id: "", email: this.correo, validaBiblio:"", start: "", handle: ""}
    formData.append('array_acciones', JSON.stringify(acciones));
    
    /* +++++ CARGAR EN BBDD ++++ */
      
    this.http.post(this.urlGuardar, formData)
      .subscribe(response => {
        this.cargando           = false;
        if(response['code'] == 404){ /* Verifica sesion */
          window.location.href  = this.urlLoginCas;
        }else{
          if(response){ 
            this.guardados      = response;
            this.guardados      = this.guardados.length;
          }else{
            this.msj            = "No existen documentos guardados"
          }
        }
    }, err => {
      this.cargando             = false;
      this.msj                  = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    })

  }

  /* Solicitar contacto */
  submitContact(form, $event, title, handle) {
    $event.preventDefault();

    if(form.form.value){
      this.response.show = true;
      /* var datos = "<h3>" + this.usuario + " ha enviado una solicitud de corrección para la publicación: <br><a href='" + this.urlHandle + handle + " '>" + title + "</a> <br><br>Handle:<br>" +handle+ "</h3>";
      this.message = datos + "<h3>Mensaje: " + this.message + "</h3>"; */

      this.queriesService.queryGet(this.urlEmail+'nombreApellido='+this.usuario+'&mail='+this.correo+'&consulta='+this.message+'&handle='+this.urlHandle+handle+'&titulo='+title+'&tipo=correccion').then(
      (data) => {
        if(data['respuesta'] == true){
          this.response.show = true;
          this.response.text = 'Mensaje enviado';
          this.response.icono = 'check_circle';
          this.response.color = 'color-verde';
          //form.form.reset();
          this.message = "";
        }else{
          this.response.show = true;
          this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
          this.response.icono = 'highlight_off';
          this.response.color = 'texto-dg-rojo';
        }
      },
      (error) => {
        console.log(error);
        this.response.show = true;
        this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
        this.response.icono = 'highlight_off';
        this.response.color = 'texto-dg-rojo';
      }
    );
    }
  }

  vincular(data,titulo,autores, fecha, tag, revista, capitulo, handle, event, i){

    if(event.target.checked){
      this.array_vincular.push({
        idpost    : data,
        fuente    : "SIPA",
        emailuser : this.correo,
        tag: tag,
        titulo: titulo,
        autores: autores,
        fecha_publicacion: fecha,
        revista: revista,
        capitulo: capitulo,
        handle: handle,
        pag: this.npage,
        indice: i
      });
      localStorage.setItem("vinculaciones",JSON.stringify(this.array_vincular));
      this.bolsaVincula = true;
      
    }else{
      for(var e = 0; this.array_vincular.length>e; e++){  
        if(this.array_vincular[e].handle==event.target.value){
          this.array_vincular.splice(e, 1);
        }  
      }
      if(this.array_vincular.length == 0){
        localStorage.removeItem("vinculaciones");
        this.bolsaVincula = false;
      }else{
        localStorage.setItem("vinculaciones",JSON.stringify(this.array_vincular));
        this.bolsaVincula = true;
      }
    }
    this.num_vinculaciones = this.array_vincular.length;
  }

  eliminaBolsa(i:number, handle){
    this.array_vincular.splice(i, 1);
    this.bolsaVincula = false;

    if(this.array_vincular.length==0){
      localStorage.removeItem("vinculaciones");
      /* this.bolsaVincula = false; */
      /* for(let i=0; i<this.data['search'].length; i++){
        this.array_btn_vincular.push({
          btn: false
        });
      } */
    }else{
      localStorage.setItem("vinculaciones",JSON.stringify(this.array_vincular));
      /* this.bolsaVincula = true; */
    }
    this.num_vinculaciones = this.array_vincular.length;
    this.buscar(this.npage,'', '1');

  }

  bolsaVinculaciones(){
    this.pasos = true
    this.bolsaVincula = false;
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
  }
  seguirBuscando(){
    
    this.pasos = false; 
    this.toScroll(); 
    this.bolsaVincula=true
    this.buscar(this.npage,'busqueda','1'); 
  }

  // +++++++++++ ENVIO EMAIL FORM +++++++++++++++
  submitEmailSearch(form){ 
    this.cargando_solicitar = true;
    this.form_help_search = "resultado";
    const formData = new FormData();

        let limpiar_array = this.array_email_search;
        for(let i in limpiar_array){
          if(limpiar_array[i] == null || limpiar_array[i] == ''){
            delete limpiar_array[i];
          }
        }

        /* Entrego archivos */
        for (var i = 0; i < this.myFiles.length; i++) { 
          formData.append("file[]", this.myFiles[i]);
        }
        formData.append('array_datos_email', JSON.stringify(limpiar_array));

        /* +++++ ENVIAR A API ++++ */
        this.http.post(this.urlEmail, formData)
        .subscribe(response => {
          this.cargando_solicitar = false;

          if(response['respuesta'] == true){ // response['httpCode'] == "200" || response['httpCode'] == "201"
                  /* form.reset(); */
                  this.msj_solicitar = "success";
                  this.msjSend = "Proceso exitoso"
                  this.array_email_search.archivos = [];
          }else{
            this.msj_solicitar = "error";
            console.log(response)
          }
        }, err => {
          this.msj_solicitar = "error";
          this.cargando_solicitar = false;
          console.log(err);
        })
        
      
  }

  submitPublica__(form){ 
    this.cargando = true;
    const formData = new FormData();
    this.totalenvios = this.array_vincular.length;
    this.array_resumen = [];
    this.numenvios = 0;

      for(let i=0; i < this.array_vincular.length; i++){

          formData.append('array_vincular', JSON.stringify(this.array_vincular[i]));
          this.http.post(this.urlvincular, formData)
          .subscribe(response => {
            if(response['code'] == 404){ /* Verifica sesion */
              window.location.href = this.urlLoginCas;
            }else{
              this.array_resumen.push({
                titulo:         this.array_vincular[i].titulo,
                handle:         this.array_vincular[i].handle,
                httpCode:       response['httpCode'],
                validacion:     response['validacion']
              });
              /* Conteo No enviados */
              if(response['httpCode']!='200' && response['httpCode']!='201'){
                this.no_enviados = this.no_enviados+1;
              }
            }           
          }, err => {
            this.array_resumen.push({
              titulo:         this.array_vincular[i].titulo,
              handle:         this.array_vincular[i].handle,
              httpCode:       "error"
            });

            this.no_enviados = this.no_enviados+1;
            /* this.msj = "error";
            this.cargando = false;
            console.log("¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde");
            console.log(err); */
          }) 

          /* +++++ MENSAJE FIN PROCESO ++++ */
          
          this.numenvios =  this.numenvios+1; 
          if(this.totalenvios == this.numenvios){
            this.cargando = false;
            this.array_vincular= [];
            localStorage.removeItem("vinculaciones")
            

            if(this.no_enviados == 0){
              this.msj = "success";
              this.msjSend = "Proceso finalizado exitosamente";
            }
            if(this.no_enviados > 0 && this.no_enviados < this.totalenvios){
              this.msj = "success";
              this.msjSend = "Proceso finalizado con algunos errores";
            }
            if(this.no_enviados == this.totalenvios){
              this.msj = "success";
              this.msjSend = "Proceso fallido";
            }

            /* +++++ ENVIAR EMAIL A API ++++ */
            let array_detalle = JSON.stringify(this.array_resumen);
            formData.append('nombreApellido', this.usuario);
            formData.append('mail', this.correo);
            formData.append('consulta', array_detalle);
            formData.append('tipo', "detalle");

            this.http.post(this.urlEmail, formData)
            .subscribe(response => {
              if(response['respuesta'] == true){
                this.msjEmail = "Se ha enviado email de confirmación a su cuenta";
              }else{
                this.msjEmail = "No hemos podido enviar email de confirmación a su cuenta";
              }
            }, err => {
              this.msjEmail = "No hemos podido hacer conección para enviar email de confirmación a su cuenta";
              console.log(err);
            })


            
            
          }
          
      }
      
  }

  submitPublica(form){ 
    this.cargando = true;
    const formData = new FormData();
    this.totalenvios = this.array_vincular.length;
    this.array_resumen = [];
    this.numenvios = 0;

      for(let i=0; i < this.array_vincular.length; i++){

        formData.append('array_vincular', JSON.stringify(this.array_vincular[i]));
          this.http.post(this.urlvincular, formData)
          .subscribe(response => {
            if(response['code'] == 404){ /* Verifica sesion */
              window.location.href = this.urlLoginCas;
            }else{
              this.array_resumen.push({
                titulo:         this.array_vincular[i].titulo,
                handle:         this.array_vincular[i].handle,
                uuid:           this.array_vincular[i].idpost,
                httpCode:       response['httpCode'],
                validacion:     response['validacion']
              });

              /* +++++ ENVIAR EMAIL A API ++++ */

              this.numenvios =  this.numenvios+1; 
              if(this.totalenvios == this.numenvios){
                let array_detalle = JSON.stringify(this.array_resumen);
                formData.append('nombreApellido', this.usuario);
                formData.append('mail', this.correo);
                formData.append('consulta', array_detalle);
                formData.append('tipo', "detalle");

                this.http.post(this.urlEmail, formData)
                .subscribe(response => {
                  if(response['respuesta'] == true){
                    this.msjEmail = "Se ha enviado email de confirmación a su cuenta";
                  }else{
                    this.msjEmail = "No hemos podido enviar email de confirmación a su cuenta";
                    console.log(response)
                  }
                }, err => {
                  this.msjEmail = "No hemos podido enviar email de confirmación a su cuenta por problemas de conexión";
                  console.log(err);
                })


                this.cargando = false;
                this.msj = "success";
                this.msjSend = "Proceso exitoso"
                this.array_vincular= [];
                localStorage.removeItem("vinculaciones")
                
              }

              
              
            }           
          }, err => {
            this.array_resumen.push({
              titulo:         this.array_vincular[i].titulo,
              handle:         this.array_vincular[i].handle,
              httpCode:       "error"
            });

            this.msj = "error";
            this.cargando = false;
            console.log(err);
          }) 

          
      }
      
  }

}
