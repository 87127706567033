import { Component, OnInit, Input }        from '@angular/core';
import json                         from '../../../assets/json/upload-articulos/upload-articulos.json';
import { FormService }              from '../../services/form.service';

@Component({
  selector: 'app-resultado-envio-forms',
  templateUrl: './resultado-envio-forms.component.html',
  styleUrls: ['./resultado-envio-forms.component.css']
})
export class ResultadoEnvioFormsComponent implements OnInit {
  @Input() tipoForm: any;
  public json: any = json;

  constructor(
    public formService: FormService
  ) { }

  ngOnInit() {

  }

}
