import { Component, OnInit, Input }                       from '@angular/core';
import { DataService }                                    from '../../../services/data.service';
import { FormService }                                    from '../../../services/form.service';
import { QueriesService }                                 from '../../../services/queries.service';
import { HttpClient }                                     from '@angular/common/http';
import { FormControl }                                    from '@angular/forms';
import { debounceTime }                                   from 'rxjs/operators';

@Component({
  selector: 'app-select-obra',
  templateUrl: './select-obra.component.html',
  styleUrls: ['./select-obra.component.css']
})
export class SelectObraComponent implements OnInit {
  @Input() label: any;
  @Input() nameInput: any;
  @Input() dataInput: any;
  @Input() placeholder: any;
  @Input() multiselect: any;
  @Input() array: any;
  @Input() indice: any;
  @Input() table: any;
  @Input() api: any;
  @Input() array_valor: any;
  @Input() required: any;
  private urlLoginCas: string;
  public urlRegistrar: string; 
  public urlGuardar: string;
  public urlvincular: string;
  public urlSql: string; 
  public urlPhp: string;
  public urlSqlArte: string; 
  public mostrar;
  public cargando: boolean;
  public select_obracreativa;

  public data: any = [];
  public myFiles:string [] = [];
  revistas            = new FormControl('')
  otro_financiamiento = new FormControl('') 
  institucion         = new FormControl('')
  tipo_obra           = new FormControl('')
  arte_viva           = new FormControl('')
  curatorias          = new FormControl('')
  pais_obra           = new FormControl('')
  pais_auspiciadora   = new FormControl('')
  pais_patrocinadora  = new FormControl('')
  lugar_edicion       = new FormControl('')
  editorial           = new FormControl('')
  unidad_academica    = new FormControl('')
  pais_patente        = new FormControl('')
  rol_O               = new FormControl('')
  rol_AV              = new FormControl('')
  rol_C               = new FormControl('')
  public filtro_valor = "";

  constructor(
    public dataService: DataService,
    private _queriesService: QueriesService,
    private http: HttpClient,
    public formService: FormService
  ) { 
    this.dataService.urls();
      this.urlLoginCas        = this.dataService.urlLoginCas;
      this.urlRegistrar       = this.dataService.phpFormulario;
      this.urlGuardar         = this.dataService.phpGuardar; 
      this.urlvincular        = this.dataService.phpVincular; 
      this.urlSql             = this.dataService.phpSql;
      this.urlPhp             = this.dataService.phpDiscovery;
      this.urlSqlArte         = this.dataService.phpSqlArte;
  }

  ngOnInit() {
  }

  search(select, tabla, namInp, api, indice){ 
    this.mostrar = true; 
    select = false   
    if(!select){ 
      this[namInp].valueChanges.pipe(debounceTime(300)).subscribe(value => {
        this.getSelect(tabla, value, namInp, this[api]);
        this.filtro_valor = value;
      });
    }
    this.getSelect(tabla,"",namInp, this[api]);
  }

  getSelect(tabla, filtro, namInp, url){ 
    this.cargando = true;
    this._queriesService.queryGet( url+ '?campo='+tabla+'&busqueda='+filtro)
      .then((data) => {  
        if(data) {this.cargando = false}; 
        this.data[namInp] = data['resultado']; 
        if(data['code'] == 404) window.location.href= this.urlLoginCas;
      });
      
  }

  add(name,namInp,dataInput,multiselect,indice,array,ruta){ 
    if(!multiselect){
      ruta[dataInput] = name;
        this.array = ruta[dataInput];
    }

    event.preventDefault()
    this.data[namInp] = null;
    this[namInp].setValue('');
    this.mostrar = false;
    this.formService.validaLugarBoton();
    this.formService.validaAuspiciadoraBoton();
    this.formService.validaPatrocinadoraBoton();
    this.formService.validaAutoresBoton();
  }

  deleteSelect(ruta, dato){
    delete ruta[dato];
    
    this.formService.validaLugarBoton();
    this.formService.validaAuspiciadoraBoton();
    this.formService.validaPatrocinadoraBoton();
    this.formService.validaAutoresBoton();
    if(this.dataInput == "tipo_obra" || this.dataInput == "arte_viva" || this.dataInput == "curatorias"){this.formService.validaTipoCreacion()}
  }

  cerrarVentana(dataInput){
    this.mostrar = false;
    this[dataInput].setValue('');
  }

}
