import { Component, OnInit }        from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Router }                   from '@angular/router';
import { QueriesService }           from '../../services/queries.service';
import json                         from './single-record.json';
import { Title, Meta }              from '@angular/platform-browser';
import {Location}                   from '@angular/common';
import { ThrowStmt }                from '@angular/compiler';
import { DataService }              from '../../services/data.service';

@Component({
  selector: 'app-single-record',
  templateUrl: './single-record.component.html',
  styleUrls: ['./single-record.component.css']
})
export class SingleRecordComponent implements OnInit {

  data: any = [];
  json: any = json;
  public error = false;
  public urlFicha: string;
  public urlImg;
  public urldownload;
  public urldescargar;
  metadatos: any = [];
  metadatos_simple: any = [];
  metadatos_origen: any = [];

  public_id: any = '1000';

  response: any = {
    'show': false,
    'data': ''
  };
  liked: any = false;
  public meta_datos = true;
  public embargo;
  public foto;
  public tipodata = "sencillo";
  

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private queriesService: QueriesService,
    private meta: Meta,
    private title: Title,
    private _location: Location,
    public dataService: DataService
    ) { 
      this.dataService.urls();
      this.urlFicha         = this.dataService.phpFicha;
      this.urlImg           = this.dataService.php_img;
      this.urldownload      = this.dataService.php_download;
      this.urldescargar      = this.dataService.phpDescargar;
      
    }

  ngOnInit() {
    // refresca página
    /* this.queriesService.getRefresh(); */
    
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);

    // Variables para devolverse con datos anteriores
    localStorage.setItem('ficha','true');
    if(localStorage.getItem('json_filtros')=="[]"){
        localStorage.setItem('filtroActivo','false');
        localStorage.setItem('title','Búsqueda simple');
    }else{
        localStorage.setItem('ficha','true');
        localStorage.setItem('filtroActivo','true');
        localStorage.setItem('title','Búsqueda avanzada');
    }

    // recibe valor handle de url
    this.activatedRoute.params.subscribe(params =>{

      this.data['param1'] = params['param1'];
      this.data['param2'] = params['param2'];
      this.data['param'] = this.data['param1'] + "/" + this.data['param2']

      /* Redireccion si deslogin  QUITÉ GUARD DE ROUTING*/
      let identity = this.queriesService.getToken();
      if(identity){

        this.queriesService.queryGet(this.urlFicha +'handle=' + this.data['param']).then((data) => {
        console.log(data['response'].numFound)
        
          if(data['response']['docs'].length>0 && data['response'].numFound != 0){
            this.error = false;
            this.data['publics'] = data['response']['docs'][0]; console.log(data);

            /* Cambio a titulo alternativo */
            if(this.data['publics']['sipa.titulovariante']){
              this.data['publics']['title'] = this.data['publics']['sipa.titulovariante'];
            }
            
  
            //data['miniatura'];
            if(data['miniatura']){
              this.foto = this.urlImg +"/"+data['response']['docs'][0]['handle']+"/"+data['miniatura'];
            }else{
              /* this.foto = "assets/img/logo_uc_linea.png"; */
              this.foto = null;
            }
  
            // Archivo en embargo
            if(data['embargo'] == 200 || data['embargo'] == 201){
                this.embargo = false;
            }else{
                this.embargo = true;
            }
  
            // Construye tabla metadatos
            let list = this.data['publics'];
            for(let i in list){
              if (i.substr(0,3)  == 'dc.' && i.indexOf('_') == -1 || i.substr(0,10)=='indexacion' || i.substr(0,16)=='sipa.fechainicio' || i.substr(0,17)=='sipa.fechatermino' || i.substr(0,17)=='sipa.tipocreacion'){  
                /* cambiar nombres */
                var alias = i;
                var link = false;
                var mostrar = true;
                
                if(i == "dc.areadearte"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.article.number"){
                  alias          = "Número de artículo"; 
                  link           = false;
                } 
                if(i == "dc.concurso"){
                  alias          = "Concurso Arte y Cultura"; 
                  link           = false;
                } 
                if(i == "dc.conservacion.estado"){
                  alias          = "Conservación"; 
                  link           = false;
                }    
                if(i == "dc.contributor"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.contributor.advisor"){
                  alias          = "Profesor guía"; 
                  link           = false;
                } 
                if(i == "dc.contributor.author"){
                  alias          = "Autor";
                  link           = false;
                } 
                if(i == "dc.contributor.participante"){
                  alias          = "Participante";
                  link           = false;
                } 
                if(i == "dc.contributor.other"){
                  alias          = "Otro autor"; 
                  link           = false;
                }  
                if(i == "dc.contributor.editor"){
                  alias          = "Editor";
                  link           = false;
                }  
                if(i == "dc.contributor.illustrator"){
                  alias          = "Ilustrador";
                  link           = false;
                }  
                if(i == "dc.coverage.spatial"){
                  alias          = "Cobertura geográfica";
                  link           = false;
                } 
                if(i == "dc.coverage.temporal"){
                  alias          = "Cobertura temporal";
                  link           = false;
                } 
                if(i == "dc.creator"){
                  alias          = "nulo";
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.cultura"){
                  alias          = "Cultura";
                  link           = false;
                } 
                if(i == "dc.date"){
                  alias          = "Fecha de embargo";
                  link           = false;
                  mostrar        = false;
                }
                if(i == "dc.date.accessioned"||i == "dc.date.available" || i == "dc.date.submitted"){ /* eliminar ??? */
                  alias          = "nulo";
                  link           = false;
                  mostrar        = false;
                }
                if(i == "dc.date.concesion"){
                  alias          = "Fecha de concesión";
                  link           = false;
                }
                if(i == "dc.date.copyright"){
                  alias          = "nulo";
                  link           = false;
                  mostrar        = false;
                }
                if(i == "dc.date.created"){
                  alias          = "Fecha de creación";
                  link           = false;
                }
                if(i == "dc.date.issued"){
                  alias          = "Fecha de publicación";
                  link           = false;
                }
                if(i == "dc.date.updated"){
                  alias          = "nulo";
                  link           = false;
                  mostrar        = false;
                }
                if(i == "dc.description"){
                  alias          = "Nota"; 
                  link           = false;
                } 
                if(i == "dc.description.abstract"){
                  alias          = "Resumen"; 
                  link           = false;
                } 
                if(i == "dc.description.funder"){
                  alias          = "Financiamiento"; 
                  link           = false;
                } 
                if(i == "dc.description.provenance"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.description.sponsorship"){
                  alias          = "Patrocinio"; 
                  link           = false;
                } 
                if(i == "dc.description.statementofresponsibility"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.description.tableofcontents"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.description.uri"){
                  alias          = "Video"; 
                  link           = true;
                } 
                if(i == "dc.description.version"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.destinatario"){
                  alias          = "Destinatario"; 
                  link           = false;
                } 
                if(i == "dc.donante"){
                  alias          = "Donante"; 
                  link           = false;
                } 
                if(i == "dc.estado.patente"){
                  alias          = "Estado de patentamiento"; 
                  link           = false;
                } 
                if(i=="dc.estamento1" ||i=="dc.estamento2" ||i=="dc.estamento3" ||i=="dc.estamento4"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i=="dc.estamento.1" ||i=="dc.estamento.2" ||i=="dc.estamento.3" ||i=="dc.estamento.4"){
                  alias          = "nulo"; 
                  link           = false;
                } 
                if(i == "dc.format"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.format.extent"){
                  alias          = "Paginación"; 
                  link           = false;
                } 
                if(i == "dc.format.medium"){
                  alias          = "Medio"; 
                  link           = false;
                } 
                if(i == "dc.format.mimetype"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.fuente.origen"){ 
                  alias          = "Fuente"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.funcion"){
                  alias          = "Función"; 
                  link           = false;
                } 
                if(i == "dc.identifier"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i=="dc.identifier.codUA"||i=="dc.identifier.codUA1"||i=="dc.identifier.codUA2"||i=="dc.identifier.codUA3"||i=="dc.identifier.codUA4"||i=="dc.identifier.codUA5"||i=="dc.identifier.codUA6"||i=="dc.identifier.codUA7"||i=="dc.identifier.codUA8"||i=="dc.identifier.codUA9"||i=="dc.identifier.codUA10"){
                  alias          = "Unidad académica"; 
                  link           = false;
                } 
                if(i == "dc.identifier.citation"){
                  alias          = "Cómo citar este documento"; 
                  link           = false;
                } 
                if(i == "dc.identifier.concesion"){
                  alias          = "Número de concesión"; 
                  link           = false;
                } 
                if(i == "dc.identifier.converisid"){
                  alias          = "Id de publicación en Converis"; 
                  link           = false;
                } 
                if(i == "dc.identifier.dialnetid"){
                  alias          = "Id de publicación en Dialnet"; 
                  link           = false;
                } 
                if(i == "dc.identifier.doi"){
                  alias          = "DOI"; 
                  link           = true;
                } 
                if(i == "dc.identifier.eisbn"){
                  alias          = "ISBN electrónico"; 
                  link           = false;
                } 
                if(i == "dc.identifier.eissn"){
                  alias          = "ISSN electrónico"; 
                  link           = false;
                } 
                if(i == "dc.identifier.govdoc"){
                  alias          = "Número de solicitud"; 
                  link           = false;
                } 
                if(i == "dc.identifier.isbn"){
                  alias          = "ISBN"; 
                  link           = false;
                } 
                if(i == "dc.identifier.ismn"){
                  alias          = "nulo"; 
                  link           = false;
                  mostrar        = false;
                } 
                if(i == "dc.identifier.issn"){
                  alias          = "ISSN";
                  link           = false; 
                } 
                if(i == "dc.identifier.other"){
                  alias          = "Identificador del recurso";
                  link           = false; 
                }
                if(i == "dc.identifier.pubmedid"){
                  alias = "Id de publicación en Pubmed";
                  link = false; 
                } 
                if(i == "dc.identifier.scieloid"){
                  alias = "Id de publicación en Scielo";
                  link = false; 
                } 
                if(i == "dc.identifier.scopusid"){
                  alias = "Id de publicación en Scopus";
                  link = false; 
                } 
                if(i == "dc.identifier.sici"){
                  alias = "nulo";
                  link = false; 
                  mostrar = false;
                } 
                if(i == "dc.identifier.slug"){
                  alias = "nulo";
                  link = false; 
                  mostrar = false;
                } 
                if(i == "dc.identifier.UA"){
                  alias = "Unidad Académica";
                  link = false; 
                } 
                if(i == "dc.issue.numero"){
                  alias = "Número de publicación"; 
                  link = false;
                }
                if(i == "dc.identifier.orcid"||i == "dc.identifier.orcid1"||i == "dc.identifier.orcid2"||i == "dc.identifier.orcid3"||i == "dc.identifier.orcid4"||i == "dc.identifier.orcid5"||i == "dc.identifier.orcid6"||i == "dc.identifier.orcid7"||i == "dc.identifier.orcid8"||i == "dc.identifier.orcid9"||i == "dc.identifier.orcid10"){
                  alias = "Número ORCID";
                  link = false;
                } 
                if(i == "dc.identifier.uri"){
                  alias = "Enlace";
                  link = true;
                } 
                if(i == "dc.identifier.wosid"){
                  alias = "Id de publicación en WoS";
                  link = false;
                } 
                if(i == "dc.information.autoruc"){
                  alias = "Información del autor UC";
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.language"){
                  alias = "Idioma";
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.language.iso"){
                  alias = "nulo"; // Código ISO del idioma de la publicación
                  link = false;
                } 
                if(i == "dc.language.rfc3066"){
                  alias = "nulo";
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.localidad"){
                  alias = "Localidad";
                  link = false;
                } 
                if(i == "dc.lugar.publicacion"){
                  alias = "Lugar de publicación";
                  link = false;
                } 
                if(i == "dc.material"){
                  alias = "Material";
                  link = false;
                } 
                if(i == "dc.nota"){
                  alias = "nulo";
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.nota.acceso"){
                  alias = "nulo";
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.pagina.final"){
                  alias = "Página final"; 
                  link = false;
                } 
                if(i == "dc.pagina.inicio"){
                  alias = "Página inicio"; 
                  link = false;
                } 
                if(i == "dc.pagina.pais"){
                  alias = "Pais"; 
                  link = false;
                } 
                if(i == "dc.pais"){
                  alias = "Pais"; 
                  link = false;
                } 
                if(i == "dc.presentacion"){
                  alias = "Tipo de presentación de patente"; 
                  link = false;
                } 
                if(i == "dc.provenance"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.publisher"){
                  alias = "Editorial"; 
                  link = false;
                } 
                if(i == "dc.region"){
                  alias = "Región"; 
                  link = false;
                } 
                if(i == "dc.relation"){
                  alias = "Publicación relacionada"; 
                  link = false;
                }
                if(i == "dc.relation.haspart"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                }
                if(i == "dc.relation.hasversion"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                }
                if(i == "dc.relation.isbasedon"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                }
                if(i == "dc.relation.isformatof"){
                  alias = "Se encuentra en"; 
                  link = false;
                } 
                if(i == "dc.relation.ispartofseries"){
                  alias = "Serie"; 
                  link = false;
                } 
                if(i == "dc.relation.isreferencedby"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.relation.isreplacedby"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.relation.isversionof"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.relation.replaces"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.relation.requires"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.relation.ispartof"){
                  alias = "Publicado en / Colección"; 
                  link = false;
                } 
                if(i == "dc.revista"){
                  alias = "Revista"; 
                  link = false;
                } 
                if(i == "dc.rights"){
                  alias = "Derechos"; 
                  link = false;
                } 
                if(i == "dc.rights.access"){
                  alias = "Tipo de Acceso"; 
                  link = false;
                } 
                if(i == "dc.rights.holder"){
                  alias = "Titular de los derechos"; 
                  link = false;
                } 
                if(i == "dc.rights.license"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.rights.uri"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.rubro"){
                  alias = "Rubro"; 
                  link = false;
                } 
                if(i == "dc.solicitante"){
                  alias = "Nombre de solicitante"; 
                  link = false;
                } 
                if(i == "dc.source"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.source.uri"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.relation.uri"){
                  alias = "Enlace relacionada";
                  link = true;
                } 
                if(i == "dc.subject.classification"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.subject.ddc"){
                  alias = "Número de clasificación Dewey"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.subject.dewey"){
                  alias = "Temática"; 
                  link = false;
                } 
                if(i == "dc.subject.lcc"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.subject.lcsh"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.subject.mesh"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.subject.other"){
                  alias = "Materia"; 
                  link = false;
                } 
                if(i == "dc.tecnica"){
                  alias = "Técnica"; 
                  link = false;
                } 
                if(i == "dc.territorialidad"){
                  alias = "Territorialidad"; 
                  link = false;
                } 
                if(i == "dc.subject"){
                  alias = "Palabra clave"; 
                  link = false;
                } 
                if(i == "dc.title.alternative"){
                  alias = "Otro título"; 
                  link = false;
                } 
                if(i == "dc.title"){
                  /* list[i] = title;
                  i = "title"; */
                  
                  alias = "Título"; 
                  link = false;
                } 
                if(i == "dc.type"){
                  alias = "Tipo de documento"; 
                  link = false;
                } 
                if(i == "dc.type.qualificationlevel"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.ubicacion"){
                  alias = "Ubicación"; 
                  link = false;
                } 
                if(i == "dc.uc.hito"){
                  alias = "Hito UC"; 
                  link = false;
                } 
                if(i == "dc.volumen"){
                  alias = "Volumen"; 
                  link = false;
                } 
                if(i == "dc.zcode"||i == "dc.zcode.1" || i == "dc.zcode.2" || i == "dc.zcode.3"|| i == "dc.zcode.4"|| i == "dc.zcode.5"|| i == "dc.zcode.6"|| i == "dc.zcode.7"|| i == "dc.zcode.8"|| i == "dc.zcode.9"|| i == "dc.zcode.10"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "sipa.fechainicio"){
                  alias = "Fecha de inicio"; 
                  link = false;
                } 
                if(i == "sipa.fechatermino"){
                  alias = "Fecha de término"; 
                  link = false;
                } 
                /* 
                if(i == "sipa.otra.index"){
                  alias = "Indexación"; 
                  link = false;
                }  */
                if(i == "indexacion"){
                  alias = "Indexación"; 
                  link = false;
                } 
                if(i == "sipa.afi.uc"||i == "sipa.codpersvinculados" || i == "sipa.fecha.validacionbiblio" || i == "sipa.fecha.validavinculacion"|| i == "sipa.fecha.vinculacion"|| i == "sipa.identifier.ha"|| i == "sipa.identifier.propintelectual"|| i == "sipa.identifier.repositoriouc"|| i == "sipa.identifier.solicpropintelectual"|| i == "sipa.trazabilidad"|| i == "sipa.validacionbiblio"){
                  alias = "nulo"; 
                  link = false;
                  mostrar = false;
                } 
                if(i == "dc.description.auspiciador"){ /* Agregado nuevo el 29 may 2023 */
                  alias = "Institución auspiciadora"; 
                  link = false;
                } 
                if(i == "dc.description.caracterobra"){ /* Agregado nuevo el 29 may 2023 */
                  alias = "Carácter de la curatoría"; 
                  link = false;
                } 
                if(i == "dc.tipodeobra"){ /* Agregado nuevo el 29 may 2023 */
                  alias = "Tipo de Obra"; 
                  link = false;
                } 
                if(i == "sipa.tipocreacion"){ /* Agregado nuevo el 29 may 2023 */
                  alias = "Tipo de creación"; 
                  link = false;
                } 
                if(i == "dc.rol"){ /* Agregado nuevo el 29 may 2023 */
                  alias = "Rol"; 
                  link = false;
                } 

                /* CAMBIO NOMBRE METADATOS REGISTRO COMPLETO  */
                if(i == "dc.title" && this.data['publics']['sipa.titulovariante'] || i == "indexacion"){
                  if(i == "dc.title" && this.data['publics']['sipa.titulovariante']){
                    this.metadatos_simple.push( { "metadato": alias, "cod":'title', "valor": [this.data['publics']['sipa.titulovariante']], "link":link, "mostrar":mostrar });
                  }
                  if(i == "indexacion"){
                    this.metadatos_simple.push( { "metadato": alias, "cod":'sipa.index', "valor": list[i], "link":link, "mostrar":mostrar });
                  }
                }else{
                  this.metadatos_simple.push( { "metadato": alias, "cod":i, "valor": list[i], "link":link, "mostrar":mostrar });
                }
  
              }
            }
  
            this.metadatos_simple.sort((a, b) => (a.metadato > b.metadato) ? 1 : -1);
            console.log(this.metadatos_simple)
            // Fin tabla
  
            
            /* Metatag SEO en header */
              
              this.title.setTitle( this.data['publics']['title'][0]);
  
              if(this.data['publics']['dc.description.abstract']){
                this.meta.updateTag({ name: 'description', content: "" + this.data['publics']['dc.description.abstract'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'description', content: "" });
              }
  
              if(this.data['publics']['dc.contributor.advisor']){
                this.meta.updateTag({ name: 'DC.contributor', content: "" + this.data['publics']['dc.contributor.advisor'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'DC.contributor', content: "" });
              }
  
              if(this.data['publics']['dc.contributor.author']){
                this.meta.updateTag({ name: 'DC.creator',  content: "" + this.data['publics']['dc.contributor.author'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'DC.creator',  content: "" });
              }
  
              if(this.data['publics']['dc.contributor.other']){
                this.meta.updateTag({ name: 'DC.contributor1', content: "" + this.data['publics']['dc.contributor.other'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'DC.contributor1', content: "" });
              }
  
              if(this.data['publics']['dc.date.accessioned']){
                this.meta.updateTag({ name: 'DCTERMS.dateAccepted', content: "" + this.data['publics']['dc.date.accessioned'][0] + "" , scheme:'DCTERMS.W3CDTF'});
              }else{
                this.meta.updateTag({ name: 'DCTERMS.dateAccepted', content: "" });
              }
  
              if(this.data['publics']['dc.date.available']){
                this.meta.updateTag({ name: 'DCTERMS.available',    content: "" + this.data['publics']['dc.date.available'][0] + "" , scheme:'DCTERMS.W3CDTF'});
              }else{
                this.meta.updateTag({ name: 'DCTERMS.available', content: "" });
              }
  
              if(this.data['publics']['dc.date.issued']){
                this.meta.updateTag({ name: 'DCTERMS.issued',       content: "" + this.data['publics']['dc.date.issued'][0] + "" , scheme:'DCTERMS.W3CDTF'});
              }else{
                this.meta.updateTag({ name: 'DCTERMS.issued', content: "" });
              }
  
              if(this.data['publics']['dc.identifier.uri']){
                this.meta.updateTag({ name: 'DC.identifier', content: "" + this.data['publics']['dc.identifier.uri'][0] + "" , scheme:"DCTERMS.URI"});
              }else{
                this.meta.updateTag({ name: 'DC.identifier', content: "" });
              }
  
              if(this.data['publics']['dc.description']){
                this.meta.updateTag({ name: 'DC.description',       content: "" + this.data['publics']['dc.description'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'DC.description', content: "" });
              }
  
              if(this.data['publics']['dc.description.abstract']){
                this.meta.updateTag({ name: 'DCTERMS.abstract',       content: "" + this.data['publics']['dc.description.abstract'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'DCTERMS.abstract', content: "" });
              }
  
              if(this.data['publics']['dc.format.extent']){
                this.meta.updateTag({ name: 'DCTERMS.extent',       content: "" + this.data['publics']['dc.format.extent'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'DCTERMS.extent', content: "" });
              }
  
              if(this.data['publics']['dc.language.iso']){
                this.meta.updateTag({ name: 'DC.language', content: "" + this.data['publics']['dc.language.iso'][0] + "" , scheme:"DCTERMS.RFC1766" });
              }else{
                this.meta.updateTag({ name: 'DC.language', content: "" });
              }
  
              if(this.data['publics']['dc.title']){
                this.meta.updateTag({ name: 'DC.title',       content: "" + this.data['publics']['dc.title'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'DC.title', content: "" });
              }
  
              if(this.data['publics']['dc.type']){
                this.meta.updateTag({ name: 'DC.type', content: "" + this.data['publics']['dc.type'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'DC.type', content: "" });
              }
  
              let subject = this.data['publics']['dc.subject.other'];
              if(subject){
                for(let i in subject){
                    this.meta.updateTag({ name: 'DC.subject'+i, content: "" + subject[i]  + ""});
                }
              }else{
                this.meta.updateTag({ name: 'DC.subject0', content: "" });
                this.meta.updateTag({ name: 'DC.subject1', content: "" });
                this.meta.updateTag({ name: 'DC.subject2', content: "" });
              }
  
              if(this.data['publics']['dc.type']){
                this.meta.updateTag({ name: 'citation_keywords', content: "" + this.data['publics']['dc.type'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'citation_keywords', content: "" });
              }
  
              if(this.data['publics']['dc.title']){
                this.meta.updateTag({ name: 'citation_title',       content: "" + this.data['publics']['dc.title'][0] + ""});
              }else{
                this.meta.updateTag({ name: 'citation_title', content: "" });
              }
              
              if(this.data['publics']['dc.language.iso']){
                this.meta.updateTag({ name: 'citation_language',       content: "" + this.data['publics']['dc.language.iso'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'citation_language', content: "" });
              }
  
              if(this.data['publics']['author']){
                this.meta.updateTag({ name: 'citation_author', content: "" + this.data['publics']['author'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'citation_author', content: "" });
              }
              
              if(this.data['publics']['dateIssued.year']){
                this.meta.updateTag({ name: 'citation_date',       content: "" + this.data['publics']['dateIssued.year'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'citation_date', content: "" });
              }
              
              if(this.data['publics']['dc.identifier.uri']){
                this.meta.updateTag({ name: 'citation_abstract_html_url', content: "" + this.data['publics']['dc.identifier.uri'][0] + "" });
              }else{
                this.meta.updateTag({ name: 'citation_abstract_html_url', content: "" });
              }
            /* Fin Metatag SEO en header */
  
          }else{
            this.error = true;
          }
        });


      }else{
          window.location.href='assets/php/cas-log/cas-login.php?ruta=handle/' + this.data['param'];
      }


      

    });

    
    
  }

  metadatas(reg){
    this.tipodata = reg;
  }

  likeRecord() {
    this.queriesService.queryGet('http://localhost:3000/likes').then((data) => {
      this.liked = data['liked'];
      this.queriesService.queryPost('http://localhost:3000/likes', {liked: !data['liked']});
    });
  }

  resetForm() {
    this.response.show = false;
    this.response.text = '';
  }

  submitComment(form, $event) {
    $event.preventDefault();
    if(form.form.value) {
      let date = new Date();
      let full_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
      let comment = {
        user: "Napoleón Gómez", //Reemplazar por el usuario actual que haya iniciado sesión
        date: full_date,
        text: form.form.value['comment_textarea']
      }
      /* this.queriesService.queryPost('http://localhost:3000/comments', comment).then(
        (data) => {
          this.queriesService.queryGet('http://localhost:3000/comments').then((data) => {this.data['comments'] = data});
          this.response.show = true;
          this.response.text = 'Su comentario ha sido publicado con éxito';
        },
        (error) => {
          this.response.show = true;
          this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
        }
      ); */
    }
  }

  descargaGA(file){
    console.log(file);
    /* this.data['tit'] = this.data['publics']['title'][0];
    this.router.navigate(['/descarga-ficha/'+this.data['tit']]); */
  }
  getSearch() {
    localStorage.setItem('search_form','');
    localStorage.setItem('json_filtros',"[]");
    localStorage.setItem('filtroActivo','false');
    localStorage.setItem('title','Búsqueda simple');
    localStorage.setItem('page','1' );
    this.router.navigate(['/busqueda']);
  }
  BusquedaAvanzada(search_by, term) {
    var array_Filtros: any[] = [
      {
        search_by: search_by,
        contains: 'es',
        term: term
      }
    ];
    localStorage.setItem('searchAdvanced','true');
    localStorage.setItem('search_form','');
    localStorage.setItem('json_filtros',JSON.stringify(array_Filtros));
    
    this.router.navigate(['/busqueda']);
  }


}
