import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute }                               from '@angular/router';
import { QueriesService }                                       from '../../services/queries.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Output()
  propagar = new EventEmitter<Object>();

  loggedIn;
  pag_active = "publicar";
  num_toolguia;
  public estado_user_sipa;
  public rol;
  public datos_academicos = JSON.parse(this._queriesService.getjsonAcademico());
  
  constructor(
    public dataService: DataService,
    private router: Router,
    private _queriesService: QueriesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.estado_user_sipa = localStorage.getItem('estado_user_sipa');

    /* ACTIVA MENU SEGUN URL */
    if(this.router.url == "subir-publicacion" ||  this.router.url == "/misdatos"){
      this.pag_active = "misdatos";
    }
    if(this.router.url == "/upload/subir-publicacion" ||  this.router.url == "/upload/editar"){
      this.pag_active = "publicar";
    }
    if(this.router.url == "/mispublicaciones/confirmado" || this.router.url == "/mispublicaciones/sinenviar"){
      this.pag_active = "mis_publicaciones";
    }
    if(this.router.url == "/produccion-academicos/academicos" || this.router.url == "/produccion-academicos/produccion_academicos"){
      this.pag_active = "produccion_academicos";
    }
  }

  ngDoCheck(){
    if(!localStorage.getItem("toolguiaClose")){
      this.num_toolguia = localStorage.getItem("toolguia")
    }
  }

  activarSidebar(menu){
    this.dataService.activarSidebar = menu;
    
    this.router.navigate(['/busqueda']);
    this.propagar.emit("false");
  }

  toolguia(paso){
    if(paso=='p0'){
      localStorage.setItem("toolguiaClose", "false")
      this.num_toolguia = "p0"
    }else{
      localStorage.setItem("toolguia", paso)
    }
  }

}
