import { Component, OnInit, Input }               from '@angular/core';
import { Router }                                 from '@angular/router';

@Component({
  selector: 'app-card-topic',
  templateUrl: './card-topic.component.html',
  styleUrls: ['./card-topic.component.css']
})
export class CardTopicComponent implements OnInit {

  @Input() cardTopicData: any;
  @Input() cardTopicIndex: any;
  @Input() cardTopicImage: any;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  fotoerror(){
    alert("no hay");
  }

  getSearch(topic){
    var array_Filtros: any[] = [
      {
        search_by: 'tema',
        contains: 'es',
        term: topic
      }
    ];
    localStorage.setItem('json_filtros',JSON.stringify(array_Filtros));
    localStorage.setItem('searchAdvanced','true');
    localStorage.setItem('search_form','');

    this.router.navigate(['/busqueda']);
  }

}
