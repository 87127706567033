import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { FormService }                       from '../../../services/form.service';

@Component({
  selector: 'app-licencias',
  templateUrl: './licencias.component.html',
  styleUrls: ['./licencias.component.css']
})
export class LicenciasComponent implements OnInit, DoCheck {
  public password;
  public array_resumen:any = [];
  public cc_compartir = null;
  public cc_comercial = null;
  public cc_sicondicion = null;
  public titulo_cc = null;
  public titulo_dc_right = null;
  public url_cc = null;
  public descripcion_licencia = null;
  public img_licencia = null;
  public img_cc = null;
  public img_userby = null;
  public by = null;  
  public sa = "";
  public img_sa = null;
  public nd = "";
  public img_nd = null;
  public nc = "";
  public img_nc = null;
 
  @Input() array_di: any = {}

  constructor(
    public formService: FormService
  ) { }

  ngOnInit() {
  }
  ngDoCheck(){
    if(this.array_di.licencia){
      if(this.array_di.licencia.creative_commons == "Atribución 4.0 Internacional (CC BY 4.0)"){
        this.cc_compartir = "cc_sicomparte";
        this.cc_comercial = "cc_sicomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-CompartirIgual 4.0 Internacional (CC BY-SA 4.0)"){
        this.cc_compartir = "cc_sicondicion";
        this.cc_comercial = "cc_sicomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-SinDerivadas 4.0 Internacional (CC BY-ND 4.0)"){
        this.cc_compartir = "cc_nocomparte";
        this.cc_comercial = "cc_sicomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-NoComercial 4.0 Internacional (CC BY-NC 4.0)"){
        this.cc_compartir = "cc_sicomparte";
        this.cc_comercial = "cc_nocomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-NoComercial-CompartirIgual 4.0 Internacional (CC BY-NC-SA 4.0)"){
        this.cc_compartir = "cc_sicondicion";
        this.cc_comercial = "cc_nocomercial";
      }
      if(this.array_di.licencia.creative_commons == "Atribución-NoComercial-SinDerivadas 4.0 Internacional (CC BY-NC-ND 4.0)"){
        this.cc_compartir = "cc_nocomparte";
        this.cc_comercial = "cc_nocomercial";
      }
    }else{
      this.formService.array_enviar.licencia={
        licencia: {
          concedo_licencia : false,
          creative_commons : "Atribución 4.0 Internacional (CC BY 4.0)",
          url : "https://creativecommons.org/licenses/by/4.0/legalcode.es"
        }
      }
      this.cc_compartir = "cc_sicomparte";
      this.cc_comercial = "cc_sicomercial";
    }

    this.cc(this.cc_compartir);
  }

  cc(data: string){

    if(data == "cc_sicomparte" && this.cc_comercial == "cc_sicomercial" || data == "cc_sicomercial" && this.cc_compartir == "cc_sicomparte"){
      this.titulo_cc = "Licencia Creative Commons Atribución 4.0 Internacional";
      this.titulo_dc_right = "Atribución 4.0 Internacional (CC BY 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by/4.0/legalcode.es";
      this.img_cc = "../../../assets/img/ccby40.png";
      this.img_licencia = "../../../assets/img/seal.png";
      this.descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
      this.img_userby = "../../../assets/img/userby.png";
      this.by = "El crédito debe ser otorgado a usted, el creador.";
    }
    if(data == "cc_sicondicion" && this.cc_comercial == "cc_sicomercial" || data == "cc_sicomercial" && this.cc_compartir == "cc_sicondicion"){
      this.url_cc = "https://creativecommons.org/licenses/by-sa/4.0/legalcode.es";
      this.titulo_cc = "Licencia Creative Commons Atribución-CompartirIgual 4.0 Internacional";
      this.titulo_dc_right = "Atribución-CompartirIgual 4.0 Internacional (CC BY-SA 4.0)";
      this.img_cc = "../../../assets/img/ccbysa40.png";
      this.img_licencia = "../../../assets/img/seal.png";
      this.descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
      this.img_sa = "../../../assets/img/sa.png";
      this.sa = "Las adaptaciones deben compartirse en los mismos términos.";
      this.img_userby = "../../../assets/img/userby.png";
      this.by = "El crédito debe ser otorgado a usted, el creador.";  
    }
    if(data == "cc_nocomparte" && this.cc_comercial == 'cc_sicomercial' || data == "cc_sicomercial" && this.cc_compartir == "cc_nocomparte"){
      this.url_cc = "https://creativecommons.org/licenses/by-nd/4.0/legalcode.es";
      this.titulo_cc = "Licencia Creative Commons Atribución-SinDerivadas 4.0 Internacional";
      this.titulo_dc_right = "Atribución-SinDerivadas 4.0 Internacional (CC BY-ND 4.0)";
      this.img_cc = "../../../assets/img/ccbynd40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.img_nd = "../../../assets/img/nd.png";
      this.nd = "No se permiten derivados ni adaptaciones de su trabajo.";
      this.img_userby = "../../../assets/img/userby.png";
      this.by = "El crédito debe ser otorgado a usted, el creador.";  
    }
    if(data == "cc_nocomercial" && this.cc_compartir == "cc_sicomparte" || data == "cc_sicomparte" && this.cc_comercial == "cc_nocomercial"){
      this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial 4.0 Internacional";
      this.titulo_dc_right = "Atribución-NoComercial 4.0 Internacional (CC BY-NC 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by-nc/4.0/legalcode.es";
      this.img_cc = "../../../assets/img/ccbync40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.img_nc = "../../../assets/img/nc.png";
      this.nc = "Sólo se permite el uso no comercial de su trabajo.";
      this.img_userby = "../../../assets/img/userby.png";
      this.by = "El crédito debe ser otorgado a usted, el creador.";  
    }
    if(data == "cc_nocomercial" && this.cc_compartir == "cc_sicondicion" || data == "cc_sicondicion" && this.cc_comercial == "cc_nocomercial"){
      this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial-CompartirIgual 4.0 Internacional";
      this.titulo_dc_right = "Atribución-NoComercial-CompartirIgual 4.0 Internacional (CC BY-NC-SA 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode.es";
      this.img_cc = "../../../assets/img/ccbyncsa40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.img_sa = "../../../assets/img/sa.png";
      this.sa = "Las adaptaciones deben compartirse en los mismos términos.";
      this.img_nc = "../../../assets/img/nc.png";
      this.nc = "Sólo se permite el uso no comercial de su trabajo.";
      this.img_userby = "../../../assets/img/userby.png";
      this.by = "El crédito debe ser otorgado a usted, el creador."; 
    }
    if(data == "cc_nocomercial" && this.cc_compartir == "cc_nocomparte" || data == "cc_nocomparte" && this.cc_comercial == "cc_nocomercial"){
      this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial-SinDerivadas 4.0 Internacional";
      this.titulo_dc_right = "Atribución-NoComercial-SinDerivadas 4.0 Internacional (CC BY-NC-ND 4.0)";
      this.url_cc = "https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode.es";
      this.img_cc = "../../../assets/img/ccncnd40.png";
      this.img_licencia = "../../../assets/img/no_aprueba.png";
      this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
      this.img_nc = "../../../assets/img/nc.png";
      this.nc = "Sólo se permite el uso no comercial de su trabajo.";
      this.img_nd = "../../../assets/img/nd.png";
      this.nd = "No se permiten derivados ni adaptaciones de su trabajo.";
      this.img_userby = "../../../assets/img/userby.png";
      this.by = "El crédito debe ser otorgado a usted, el creador."; 
    }

    this.formService.array_enviar.licencia.creative_commons = this.titulo_dc_right;
    this.formService.array_enviar.licencia.url = this.url_cc;
  }

}
