import { Injectable } from '@angular/core';
import jsonVersiones  from '../../assets/json/versiones.json';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  postId: string          = null;
  entrada: boolean        = null;
  activarSidebar: string  = 'false';
  editar: string          = "aqui";

  //ENTORNOS
  private PROD: any                   = window.location.protocol +'//'+window.location.hostname;
  private local_prod: string          = "https://sipa.uc.cl";
  private local_lib: string           = "https://sipa-lib.cloud.uc.cl";
  private local_des: string           = "https://sipa-des.cloud.uc.cl";

  // *****   DECLARAR ENTORNO   *****
  public dominio: string              = this.PROD;
  // ****

  public php: string;
  public php_img: string;
  public php_download: string;
  public phpDiscovery: string;
  public phpDiscovery2: string;
  public phpEmail: string;
  public phpFicha: string;
  public urlLoginCas: string;
  public phpVincular: string;
  public phpGuardar: string;
  public phpDescargar: string;
  public phpTitAlt: string;
  public phpRegistrar: string;
  public phpFormulario:string;
  public phpSql: string;
  public phpPubliAcademicos: string;
  public phpGenerarExcel: string;
  public phpDescargarExcel: string;
  public phpSqlArte: string;
  public phpLanding: string;
  public phpLandingPage: string;
  public phpSuscribirNews: string;
  public phpUltimosAgregados: string;
  public phpUploadFile: string;
  public phpCantidadArticulos: string;
  public phpCantidadCapLibros: string;
  public phpCantidadLibros: string;
  public phpCantidadPatentes: string;
  public urlDesvincular: string;
  public phpCantidadObras: string;
  public urlHandle: string;
  
  public jsonVersiones:any            = jsonVersiones;
  public versionPortal:any            = [];

  constructor() { }

  public urls(){
    this.php                    = '/assets/php';
    this.urlHandle              = '/handle/';
    if(this.dominio == this.PROD){
      this.php_img              = '/xmlui/bitstream/handle';
      this.php_download         = '/xmlui/bitstream/handle'; 
    }else{
      this.php_img              = ':8080/bitstream/handle';
      this.php_download         = ':8080/bitstream/handle';
    }

      this.php_img              = this.dominio + this.php_img;
      this.php_download         = this.dominio + this.php_download;
      this.urlHandle            = this.dominio + this.urlHandle;
      this.phpDiscovery         = this.dominio + this.php + "/discovery-sipa.php?";
      this.phpDiscovery2        = this.dominio + this.php + "/discovery.php?";
      this.phpEmail             = this.dominio + this.php + "/mail-contacto/envio.php?";
      this.phpFicha             = this.dominio + this.php + "/ficha.php?";
      this.urlLoginCas          = this.dominio + this.php + '/cas-log/cas-login.php';
      this.phpVincular          = this.dominio + this.php + '/vinculacion/VincularSipaWos.php';
      this.phpGuardar           = this.dominio + this.php + '/vinculacion/mis_publicaciones.php';
      this.phpDescargar         = this.dominio + this.php + '/vinculacion/descarga.php';
      this.phpTitAlt            = this.dominio + this.php + '/vinculacion/titulo-alternativo.php';
      this.phpRegistrar         = this.dominio + this.php + '/adjuntos/adjunto.php';
      this.phpFormulario        = this.dominio + this.php + "/formulario-sipa/formulario.php";
      this.phpSql               = this.dominio + this.php + "/formulario-sipa/sql.php";
      this.phpPubliAcademicos   = this.dominio + this.php + "/jefatura/pub-academicos.php";
      this.phpGenerarExcel      = this.dominio + this.php + "/jefatura/generar-excel.php";
      this.phpDescargarExcel    = this.dominio + this.php + "/jefatura/descargar-excel.php?archivo=";
      this.phpSqlArte           = this.dominio + this.php + "/formulario-sipa/sqlArtes.php";
      this.phpLanding           = this.dominio + this.php + "/api-mailchimp/landing.php?page=";
      this.phpLandingPage       = this.dominio + this.php + "/api-mailchimp/landing-page.php";
      this.phpSuscribirNews     = this.dominio + this.php + "/api-mailchimp/suscribir-newsletter.php?email=";
      this.phpUltimosAgregados  = this.dominio + this.php + "/ultimos-agregados.php?";
      this.phpUploadFile        = this.dominio + this.php + "/autoarchivo/subirArchivo.php";
      this.phpCantidadArticulos = this.dominio + this.php + '/cantidad-articulos.php';
      this.phpCantidadCapLibros = this.dominio + this.php + '/cantidad-capituloDeLibros.php';
      this.phpCantidadLibros    = this.dominio + this.php + '/cantidad-libros.php';
      this.phpCantidadPatentes  = this.dominio + this.php + '/cantidad-patentes.php';
      this.phpCantidadObras     = this.dominio + this.php + '/cantidad-obras.php';
      this.urlDesvincular       = `${this.dominio}${this.php}/desvincular.php`;
  }

  public login(){
    if(this.dominio == this.PROD){
      window.location.href='assets/php/cas-log/cas-login.php';
    }else{
      localStorage.setItem('usuario', 'Pantoja, Rodrigo');
      localStorage.setItem('correo', 'rodrigo.pantoja@uc.cl');
      localStorage.setItem('estado_user_sipa', 'true');
      localStorage.setItem('jsonAcademico', 
      '{"NOMBRE_COMPLETO": "RODRIGO PANTOJA","CATEGORIA_ACADEMICA": "PROFESOR ASISTENTE","COD_PERS": "1105052","CORREO": "rodrigo.pantoja@uc.cl","DV": "K","FACULTAD": "FACULTAD DE MEDICINA","RUT":"11.111.111-1","GRADO_PERFECCIONAMIENTO":"ESPECIALIDAD MÉDICA/ ODONTOLÓGICA","PAIS":"Chile","ORCID":"123456789","INSTITUCION":"PUC","UNIDAD_ACADEMICA":"DISEÑO","NOMBRE_PERFIL":["jefaturas"] }');    
      var d1:any = new Date().getTime();
      localStorage.setItem("sessionsipa", d1); 
    }
    
  }

  public logoutCas(){
    localStorage.removeItem('usuario');
    localStorage.removeItem('correo');
    localStorage.removeItem('estado_user_sipa');
    localStorage.removeItem('jsonAcademico');
    window.location.href='assets/php/cas-log/logout-cas.php';
  }
  
  public versionesPortal(){
    this.versionPortal = jsonVersiones;
    for(var i = 0; i < jsonVersiones.length; i++){
      this.versionPortal[i].version = this.jsonVersiones[i].numero_version + "." + this.jsonVersiones[i].numero_mejora_version + "." + this.jsonVersiones[i].numero_item_mejora + "-" + this.jsonVersiones[i].numero_rama
    }
  }
}
