import { Component, OnInit }  from '@angular/core';
import json                   from './footer.json';
//import footer                 from './footer-01.json';
import footer                 from '../../../assets/json/footer-01.json';
import MenuRepos              from '../../../assets/json/menu-repositorio.json';
import Versiones              from '../../../assets/json/versiones.json';
import {DataService}          from '../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  //json:       any = json;
  footer:     any = footer['footer-01'];
  menuRepos:  any = MenuRepos['menu-repositorio-uc'];
  msjf;
  estilof;
  iconof;
  arrayVersion: any ;
  num_version: any;

  constructor(
    private dataService: DataService
  ) { 
    this.dataService.versionesPortal();
  }

  ngOnInit() {
    $("#msjFooter").hide();
    this.num_version = this.dataService.versionPortal[0].version;
  }

  alertasFooter(estilo, icono, texto){
    
    $("#msjFooter").fadeIn(1000);
    setInterval(() => {
      $("#msjFooter").fadeOut(1500);
    }, 2000);
    this.msjf = texto;
    this.estilof = estilo;
    this.iconof = icono;
  }

}
