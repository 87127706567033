import { Component, OnInit }  from '@angular/core';
import { QueriesService }     from '../../services/queries.service';
import json                   from './contact.json';
import Faqs                   from '../../../assets/json/faq/preguntas-frecuentes.json';
import { DataService }        from 'src/app/services/data.service.js';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  data: any = [];
  main: any = {};
  response: any = {
    'show': false,
    'data': ''
  }
  name = this.queriesService.getUser();
  email = this.queriesService.getToken();
  message;
  public urlEmail: string;

  constructor(
    private queriesService: QueriesService,
    public dataService: DataService
  )
  {  
    this.dataService.urls();
    this.urlEmail = this.dataService.phpEmail;
  }

  ngOnInit() {
    
    // refresca página
    /* this.queriesService.getRefresh(); */

    this.main = json;
    this.data['faqs'] = Faqs['preguntas-frecuente'];

    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
    
  }

  resetForm() {
    this.response.show = false;
    this.response.text = '';
  }

  submitContact(form, $event) {
    $event.preventDefault();

    if(form.form.value)
      this.response.show = true;
      this.queriesService.queryGet(this.urlEmail+'nombreApellido='+this.name+'&mail='+this.email+'&consulta='+this.message +'&tipo=contacto').then(
      (data) => {
        if(data['respuesta'] == true){
          this.response.show = true;
          this.response.text = 'Su mensaje ha sido enviado con éxito';
          this.response.icono = 'check_circle';
          this.response.color = 'color-verde';
          //form.form.reset();
          /* this.name = "";
          this.email = ""; */
          this.message = "";
        }else{
          //console.log(data);
          this.response.show = true;
          this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
          this.response.icono = 'highlight_off';
          this.response.color = 'texto-dg-rojo';
        }
      },
      (error) => {
        console.log(error);
        this.response.show = true;
        this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
        this.response.icono = 'highlight_off';
        this.response.color = 'texto-dg-rojo';
      }
    );
  }
}
