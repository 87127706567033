import { BrowserModule }              from '@angular/platform-browser';
import { NgModule }                   from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS }           from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule }    from '@angular/platform-browser/animations';
import { SlickCarouselModule }        from "ngx-slick-carousel";

import { TubosDerechaPipe }           from './pipes/tubos-derecha.pipe';
import { SlashIzquierdaPipe }         from './pipes/slash-izquierda.pipe';
import { SlashDerechaPipe }           from '../app/pipes/slash-derecha.pipe';
import { TxtPipe }                    from './pipes/txt.pipe';
import { FechaPipe }                  from './pipes/fecha.pipe';

import { AppRoutingModule }           from './app-routing.module';
import { AppComponent }               from './app.component';

import { TopComponent }               from './navbar/top/top.component';
import { BottomComponent }            from './navbar/bottom/bottom.component';
import { HeaderComponent }            from './navbar/header/header.component';
import { FooterComponent }            from './navbar/footer/footer.component';
import { HomeComponent }              from './templates/home/home.component';
import { CoverSecondLevelComponent }  from './templates/cover-second-level/cover-second-level.component';
import { ContactComponent }           from './templates/contact/contact.component';
import { PageContentComponent }       from './templates/page-content/page-content.component';
import { SingleRecordComponent }      from './templates/single-record/single-record.component';
import { UploadRecordComponent }      from './templates/upload-record/upload-record.component';
import { DescargaGaComponent }        from './templates/descarga-ga/descarga-ga.component';
import { BusquedaComponent }          from './templates/busqueda/busqueda.component';
//import { CoverContentComponent }      from './templates/cover-content/cover-content.component';
//import { BibliometricsComponent }     from './templates/bibliometrics/bibliometrics.component';
//import { BlogComponent }              from './templates/blog/blog.component';
//import { BlogListComponent }          from './templates/blog-list/blog-list.component';
//import { FaqComponent }               from './templates/faq/faq.component';
/* import { CardBibliometricsComponent } from './partials/cards/card-bibliometrics/card-bibliometrics.component'; */
import { CardNormalComponent }        from './partials/cards/card-normal/card-normal.component';
import { CardHorizontalComponent }    from './partials/cards/card-horizontal/card-horizontal.component';
import { CardTopicComponent }         from './partials/cards/card-topic/card-topic.component';
import { CardPublicationsComponent }  from './partials/cards/card-publications/card-publications.component';
import { TitleComponent }             from './partials/commons/title/title.component';
import { BreadcrumbsComponent }       from './partials/commons/breadcrumbs/breadcrumbs.component';
import { SearchFormComponent }        from './partials/commons/search-form/search-form.component';

import { RequestInterceptorService }  from './request-interceptor.service';
import { AuthService }                from './auth.service';
import { FileuploadService }          from './servicio/fileupload.service';
import { QueriesService }             from './services/queries.service';
import { IdentityGuard }              from './services/identity.guard';
import { MyPostsComponent } from './templates/my-posts/my-posts.component';
import { MisPublicacionesComponent } from './templates/mis-publicaciones/mis-publicaciones.component';
import { MisDatosComponent } from './templates/mis-datos/mis-datos.component';
import { SidebarComponent } from './navbar/sidebar/sidebar.component';
import { SearchPipe } from './pipes/search.pipe';
import { ProduccionAcademicosComponent } from './templates/produccion-academicos/produccion-academicos.component';
import { ValorizacionEstrellasComponent } from './partials/commons/valorizacion-estrellas/valorizacion-estrellas.component';
import { AlertComponent } from './partials/commons/alert/alert.component';
import { UploadMultipubComponent } from './templates/upload-multipub/upload-multipub.component';
import { UploadArticulosComponent } from './templates/upload-articulos/upload-articulos.component';
import { SelectComponent } from './partials/forms/select/select.component';
import { UploadObraCreativaComponent } from './templates/upload-obra-creativa/upload-obra-creativa.component';
import { UploadLibrosComponent } from './templates/upload-libros/upload-libros.component';
import { UploadCapituloLibrosComponent } from './templates/upload-capitulo-libros/upload-capitulo-libros.component';
import { UploadPatentesComponent } from './templates/upload-patentes/upload-patentes.component';
import { SelectObraComponent } from './partials/forms/select-obra/select-obra.component';

import { VersionesComponent } from './templates/versiones/versiones.component';
import { LicenciasComponent } from './partials/forms/licencias/licencias.component';
import { UploadOtrosComponent } from './templates/upload-otros/upload-otros.component';
import { ResultadoEnvioFormsComponent } from './partials/resultado-envio-forms/resultado-envio-forms.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopComponent,
    BottomComponent,
    HeaderComponent,
    FooterComponent,


    CardTopicComponent,
    //CardBibliometricsComponent,
    BreadcrumbsComponent,
    CardPublicationsComponent,
    SearchFormComponent,
    TitleComponent,
    CardHorizontalComponent,
    CardNormalComponent,

    CoverSecondLevelComponent,
    //BibliometricsComponent,
    //BlogComponent,
    //BlogListComponent,
    ContactComponent,
    //CoverContentComponent,
    //FaqComponent,
    PageContentComponent,
    BusquedaComponent,
    SingleRecordComponent,
    UploadRecordComponent,
    DescargaGaComponent,
    TubosDerechaPipe,
    SlashIzquierdaPipe,
    SlashDerechaPipe,
    TxtPipe,
    FechaPipe,
    MyPostsComponent,
    MisPublicacionesComponent,
    MisDatosComponent,
    SidebarComponent,
    SearchPipe,
    ProduccionAcademicosComponent,
    ValorizacionEstrellasComponent,
    AlertComponent,
    UploadMultipubComponent,
    UploadArticulosComponent,
    SelectComponent,
    UploadObraCreativaComponent,
    UploadLibrosComponent,
    UploadCapituloLibrosComponent,
    UploadPatentesComponent,
    SelectObraComponent,
    VersionesComponent,
    LicenciasComponent,
    UploadOtrosComponent,
    ResultadoEnvioFormsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SlickCarouselModule

  ],
  exports: [
    
  ],
  providers: [
    FileuploadService,
    QueriesService,
    IdentityGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
