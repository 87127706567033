import { Component, OnInit, ɵConsole }        from '@angular/core';
import { ActivatedRoute, Params }             from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router }                             from '@angular/router';
import json                                   from '../../../assets/json/search-advanced/search-advanced.json';
import { QueriesService }                     from '../../services/queries.service';
import { DataService }                        from '../../services/data.service';

@Component({
  selector: 'app-my-posts',
  templateUrl: './my-posts.component.html',
  styleUrls: ['./my-posts.component.css']
})
export class MyPostsComponent implements OnInit {

  public json: any = json;
  public data: any = [];
  public urlDiscovery2: string;
  public urlImg;
  public valorSearch = "";
  public array_Filtros: any[] = [];
  public filtro: any;
  public registros;
  public totalPage;
  public page;
  public npage: any = 1;
  public cantidadReg;
  public paginacion= [];
  public nextPage;
  public prevPage;

  public tipodoc: any = [];
  public autores: any = [];
  public tematicas: any = [];
  public materias: any = [];

  public facetas_desde;
  public facetas_hasta;
  public orden;

  constructor(
    private queriesService: QueriesService,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService
  ) {
    this.dataService.urls();
    this.urlDiscovery2  = this.dataService.phpDiscovery2;
    this.urlImg         = this.dataService.php_img;
   }

  ngOnInit() {
    this.data['filtroActivo'] = false; // oculta div de filtros
    this.data['title-pag'] = 'Mis Publicaciones';
    this.data['bajada'] = 'En esta sección se muestra la información de Artículo ISI-Scopus vinculados por el académico.';
    this.npage = 1;
    this.buscar(this.npage,"", "");
    document.getElementById('resultados').scrollIntoView({behavior: 'smooth'}); // Scroll hacia buscador
  }

  buscar(page,filtro, orden) {
    document.getElementById('resultados').scrollIntoView({behavior: 'smooth'}); //Scroll hacia div resultados
    this.data['listFacetas'] = false;
    let str_filtros:any = '';
    let urlFiltro;
    // SI VIENE DE ficha
    if(localStorage.getItem('ficha') == 'true'){
      page = Number(localStorage.getItem('page'));
    }
    this.npage = page;
    localStorage.setItem('page',page );
    page = (page-1)*20;
    this.orden = orden;

    // SI VIENE DE ESTRA PÁGINA
    if(filtro=="busqueda"){
      localStorage.setItem('search_form',this.valorSearch);
    }
    // SI VIENE DE HOME AREAS TEMATICAS Y PAGINAS TESIS Y PUBLICACIONES
    if(localStorage.getItem('searchAdvanced') == 'true'){
      this.data['title'] = 'Búsqueda avanzada';
      this.data['filtroActivo'] = true;
      this.array_Filtros = JSON.parse(localStorage.getItem('json_filtros'));
      localStorage.removeItem('searchAdvanced');
    }
    // SI VIENE DE ficha
    if(localStorage.getItem('ficha') == 'true'){
      this.data['filtroActivo'] = JSON.parse(localStorage.getItem('filtroActivo'));
      this.data['title'] = localStorage.getItem('title');
      this.array_Filtros = JSON.parse(localStorage.getItem('json_filtros'));
      localStorage.removeItem('ficha');
      localStorage.removeItem('title');
      localStorage.removeItem('filtroActivo');
    }
    
    this.valorSearch = localStorage.getItem('search_form');
    localStorage.setItem('json_filtros',JSON.stringify(this.array_Filtros));
    
    

    // RECORRO ARRAY array_Filtros DE FILTROS
    for(let i=0; i < this.array_Filtros.length; i++){
      this.array_Filtros[i]['term'] =  this.array_Filtros[i]['term'];
      let termm = this.array_Filtros[i]['term'];
      let term = termm.replace(".", "").replace(":", "").replace("-", " ");
      // Construyo cadena de filtros
      switch (this.array_Filtros[i]['search_by']) {

        case 'titulo':
          
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-title:"'+ term +'"%26'; */
            /* this.filtro = 'fq=-dc.title:'+ term +'*%26'; */
            /* this.filtro = 'fq=-title:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.title:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=title:"'+this.array_Filtros[i]["term"] +'"%26'; */
            this.filtro = 'fq=dc.title:'+ term +'%26';
            /* this.filtro = 'fq=title:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=dc.title:"'+ termm +'"%26';
            /* this.filtro = 'fq=title:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=title:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            /* this.filtro = '"fq=title:'+this.array_Filtros[i]["term"] +'*"%26'; */
          }
        break;

        case 'autor':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dc.contributor.author:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dc.contributor.author:'+ term +'*%26'; */
            /* this.filtro = 'fq=-dc.contributor.author:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.contributor.author:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dc.contributor.author:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=dc.contributor.author:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.contributor.author:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=dc.contributor.author:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=bi_2_dis_value_filter:"'+ termm +'"%26';
            /* this.filtro = 'fq=bi_2_dis_value_filter:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=bi_2_dis_value_filter:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
        break;
        
        case 'fecha':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dateIssued:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dateIssued:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=-dateIssued:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dateIssued:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dateIssued:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=dateIssued:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=dateIssued:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=dateIssued:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=dateIssued:"'+ termm +'"%26';
            /* this.filtro = 'fq=dateIssued:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=dateIssued:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
        break;

        case 'tema':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dc.subject.dewey:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dc.subject.dewey:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=-dc.subject.dewey:'+ term +'* OR' +' dc.subject.other:'+ term +'*%26';  */ 
            /* this.filtro = 'fq=-dc.subject.dewey:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.subject.dewey:'+ term + ' OR ' +'dc.subject.other:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dc.subject.dewey:"'+ term +'"%26'; */
            /* this.filtro = 'fq=dc.subject.dewey:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.subject.dewey:'+ term +'* OR' +' dc.subject.other:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.subject.dewey:\"'+ term +'\"%26'; */
            this.filtro = 'fq=dc.subject.dewey:'+ term + ' OR ' +'dc.subject.other:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=bi_4_dis_value_filter:"'+ termm +'"%26';
            
            /* this.filtro = 'fq=bi_4_dis_value_filter:'+ term +'*%26'; */
            /* this.filtro = 'fq=bi_4_dis_value_filter:\"'+ term +'\"%26'; */
          }
        break;

        case 'tipo':
          if(this.array_Filtros[i]['contains']== 'no-contiene'){
            /* this.filtro = 'fq=-dc.type:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=-dc.type:'+ term +'*%26'; */
            /* this.filtro = 'fq=-dc.type:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=-dc.type:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'contiene'){
            /* this.filtro = 'fq=dc.type:"'+this.array_Filtros[i]["term"] +'"%26'; */
            /* this.filtro = 'fq=dc.type:'+ term +'*%26'; */
            /* this.filtro = 'fq=dc.type:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
            this.filtro = 'fq=dc.type:'+ term +'%26';
          }
          if(this.array_Filtros[i]['contains']== 'es'){
            this.filtro = 'fq=dc.type:"'+ termm +'"%26';
            /* this.filtro = 'fq=dc.type:'+this.array_Filtros[i]["term"] +'*%26'; */
            /* this.filtro = 'fq=dc.type:\"'+this.array_Filtros[i]["term"] +'\"%26'; */
          }
        break;


      } 
      str_filtros=str_filtros + this.filtro;
    }

    urlFiltro = this.urlDiscovery2 + 'filtro=' + str_filtros +  '&valor=' + localStorage.getItem('search_form') + '&start=' + page + '&orden=' + this.orden;
    console.log(urlFiltro );


    // ++++++++++++++++++ ENVÍO CONSULTA API Y DEVUELVE REGISTROS ++++++++++++++++++++++


    this.queriesService.queryGet( urlFiltro )
      .then((data) => { 
        this.data['search']= Object.keys(data) .map(i =>data[i]);
        console.log(this.data['search']);
        
        // CONSTRUYO MENU FACETAS
        var x = 0;
        var arr = [];
        var tipodoc: any = [];
        var autores_arreglo: any = [];
        var tematicas_arreglo: any = [];
        var materias_arreglo: any = [];

          // Facetas materia
          /* this.data['materia'] = this.data['search'][2]['facet_fields']['bi_4_dis_value_filter'];
          for (let i = 0; i < this.data['materia'].length/2; i++) {
            x=i*2;
            arr=[];
            arr.push(this.data['materia'][x]); 
            x=(i*2)+1;
            arr.push(this.data['materia'][x]);
            materias_arreglo.push(arr);
          }
          this.materias = materias_arreglo; */

          // Facetas tematicas
          this.data['tematicas'] = this.data['search'][2]['facet_fields']['bi_4_dis_value_filter'];
          for (let i = 0; i < this.data['tematicas'].length/2; i++) {
            x=i*2;
            arr=[];
            arr.push(this.data['tematicas'][x]); 
            x=(i*2)+1;
            arr.push(this.data['tematicas'][x]);
            tematicas_arreglo.push(arr);
          }
          this.tematicas = tematicas_arreglo;
          

          // Facetas autores
          this.data['autores'] = this.data['search'][2]['facet_fields']['bi_2_dis_value_filter'];
          for (let i = 0; i < this.data['autores'].length/2; i++) {
            x=i*2;
            arr=[];
            arr.push(this.data['autores'][x]); 
            x=(i*2)+1;
            arr.push(this.data['autores'][x]);
            autores_arreglo.push(arr);
          }
          this.autores = autores_arreglo;

          // Facetas tipo documento
          this.data['tipodoc'] = this.data['search'][2]['facet_fields']['type_filter'];
          for (let i = 0; i < this.data['tipodoc'].length/2; i++) {
            x=i*2;
            arr=[];
            arr.push(this.data['tipodoc'][x]); 
            x=(i*2)+1;
            arr.push(this.data['tipodoc'][x]);
            tipodoc.push(arr);
          }
          this.tipodoc = tipodoc;
        // fin filtros facetas
        
        // PAGINACIÓN
        this.totalPage = this.data['search'][1]['numFound'];
        this.cantidadReg = 20;
        this.page  = Math.ceil(this.totalPage / this.cantidadReg); 
        
        
        //console.log(this.totalPage + " registros");
        //console.log(this.page + " páginas" );
        //console.log('pagina cada '+page)
        //console.log('página atual: '+this.npage);
        //console.log(this.nextPage);
        this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
        for(let i=1; i<=this.page; i++){
          if(i <= 5){
            if(this.npage>5){
              this.paginacion.push(i+(this.npage-5));
            }else{
              this.paginacion.push(i);
            }
          }
        }
        if(this.npage>=2){
          this.prevPage = this.npage-1;
        }else{
          this.prevPage = 1;
        }
        if(this.npage<this.page){
          this.nextPage = this.npage+1;
        }else{
          this.nextPage = this.page;
        }
        // Fin paginación
        
        // MUESTRA/OCULTA SECCIÓN REGISTROS
        if(this.data['search'][1]['numFound']==undefined || this.data['search'][1]['numFound']==0){
          this.registros=false;
        }else{
          this.registros=true;
        }
        // fin muestra7oculta sección registros
    });


  }
  busquedaAvanzada(){
    this.data['filtroActivo'] = true;
    this.data['title'] = 'Búsqueda avanzada';
    this.addFiltros();
  }
  addFiltros() {  
    this.array_Filtros.push({
      search_by: '',
      contains: '',
      term: ''
    });
  }
  removefiltros(i: number) {
    this.array_Filtros.splice(i, 1); // elimina 1 indice a partir del indice i
    if(this.array_Filtros.length == 0){ // si no existen filtros
      this.data['filtroActivo']= false; // oculta div de filtros
      this.data['title'] = 'Búsqueda simple';
    }
  }

  /* Desde menu facetas despliega todas las facetas */
  searchFacetas(param, num){
    this.data['listFacetas'] = true;
    this.data['search'] = false;
    if(param=='tipodoc'){
      this.data['facetas'] = this.tipodoc;
      this.data['tipo'] = 'tipo';
    }
    if(param=='autores'){
      this.data['facetas'] = this.autores;
      this.data['tipo'] = 'autor';
    }
    if(param=='tema'){
      this.data['facetas'] = this.tematicas;
      this.data['tipo'] = param;
    }

                  // PAGINACIÓN
                  this.totalPage = this.data['facetas'].length;
                  this.cantidadReg = 50;
                  this.npage = num;
                  num = (num-1)*20;
                  this.page  = Math.ceil(this.totalPage / this.cantidadReg); 
                  this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
                  this.facetas_desde= (this.npage-1) * this.cantidadReg;
                  this.facetas_hasta = (this.npage) * this.cantidadReg;

                  for(let i=1; i<=this.page; i++){
                    if(i <= 5){
                      if(this.npage>5){
                        this.paginacion.push(i+(this.npage-5));
                      }else{
                        this.paginacion.push(i);
                      }
                    }
                  }
                  if(this.npage>=2){
                    this.prevPage = this.npage-1;
                  }else{
                    this.prevPage = 1;
                  }
                  if(this.npage<this.page){
                    this.nextPage = this.npage+1;
                  }else{
                    this.nextPage = this.page;
                  }
                  // Fin paginación
  }
  /* Desde lista de resutados de facetas despliega lista de resultados de documentos */
  getFacetas(faceta,item){
    this.data['filtroActivo'] = true;
    this.data['title'] = 'Búsqueda avanzada';
    let splits = faceta.split('|||'); // string pasa a array si Separador es |||
    if(splits.length > 1) {
      faceta = splits.pop(); // elimina último elemento de un array
    }
    this.array_Filtros.push({
      search_by: item,
      contains: 'es',
      term: faceta
    });
    this.buscar(1,item, 'asc');
  }


}
