import { Injectable }               from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { Observable }               from 'rxjs';
import { map }                      from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QueriesService {
  public identity;
  public token;
  public user;
  public jsonAcademico;
  public pass;
  public data;
  public urls;
  d1;
  d2;
  diff;

  constructor(private httpClient: HttpClient) { }
  
  // refresca página
  public getRefresh(){
    const firstTime = localStorage.getItem('key');
    if(!firstTime){
      localStorage.setItem('key','loaded');
      location.reload();
    }else {
      localStorage.removeItem('key');
    }
  }

  /**
  *   nombre: queryGet
  *   tipo: function
  *   parametros: url (URL de una API)
  *   return: Promise (Contiene la respuesta de la API)
  *   descripcion: Recibe la URL de una API, ejecuta un GET nativo de Angular, cuyo resultado es retornado en forma de Promise
  */
  public queryGet(url){
    return new Promise( (resolve, reject) => {
      this.httpClient.get(url).pipe(
        map((res: Response) => res)
      ).subscribe(
        (data)  => {
          resolve(data);
        },
        (err)   => {
          console.log("error desde el servicio");
          console.log(err);
          reject();
        }
      );
    });
  }

  // POSTEO AJAX
  postApi(url,archivo): Observable<any>{
    let params = JSON.stringify(archivo);
    console.log(url+archivo);

    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.httpClient.post(url, params, {headers: headers});
  }

  /* +++++++  DATOS DEL USUARIO LOGEADO ++++++++++++ */

  public getIdentity(){
    let identity = JSON.parse(localStorage.getItem("identity")); // Traigo json y lo paso a objeto
    if(identity && identity != "undefined"){
      this.identity = identity;
    }else{
      this.identity = null;
    }
    return this.identity;
  }

  /* TOKEN SI ESTÁ LOGUEADO */
  public getToken(){
    this.d1 = new Date(parseInt(localStorage.getItem("sessionsipa")));
    this.d2 = new Date().getTime();
    this.diff = this.d2 - this.d1
    var exp:number = 3600000; // 1min=60000 / 5min=300000 / 1hr=3600000
    
    if(this.diff > exp){
      /* localStorage.removeItem('correo');
      localStorage.removeItem("sessionsipa");
      window.location.href='assets/php/cas-log/logout-cas.php'; */
      if(localStorage.getItem("correo")){
        localStorage.removeItem("correo");
        window.location.href='assets/php/cas-log/logout-cas.php';
      }
    }else{
      localStorage.setItem("sessionsipa", this.d2);
    }

    let token = localStorage.getItem("correo");
    if(token && token != "undefined"){
      this.token = token;
    }else{
      this.token = null;
    }
    return this.token;
  }
  public getUser(){
    let user = localStorage.getItem("usuario");
    let user2:any = JSON.parse(localStorage.getItem("jsonAcademico"));
    if(user && user != "undefined"){
      this.user = user;
    }else if(user2 && user2 != "undefined" && user2.NOMBRE_COMPLETO ){
      this.user = user2.NOMBRE_COMPLETO;
    }else{
      this.user = '';
    }
    return this.user;
  }
  public getjsonAcademico(){
    let jsonAcademico = localStorage.getItem("jsonAcademico");
    if(jsonAcademico && jsonAcademico != "undefined"){
      this.jsonAcademico = jsonAcademico;
    }else{
      this.jsonAcademico = null;
    }
    return this.jsonAcademico;
  }
  /* CLAVE DEL USUARIO LOGEADO */
  public getPass(){
    //let pass = localStorage.getItem("password"); // Traigo json y lo paso a objeto
    let pass = localStorage.getItem("password"); // Traigo json y lo paso a objeto
    if(pass && pass != "undefined"){
      this.pass = pass;
    }else{
      this.pass = null;
    }
    return this.pass;
  }


  // CONSULTA AJAX

  postReport(url,archivo): Observable<any>{
    /* let params = JSON.stringify(archivo); */

    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.httpClient.post(url, archivo, {headers, responseType: 'blob' as 'json'});
  }

  public getReport(url):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this.httpClient.get(url, {headers, responseType: 'blob' as 'json'});
  }


  public getApi(url):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.httpClient.get(url, {headers: headers});
  }

  public getLocalStorage(param){
    let data = localStorage.getItem(param);
    if(data && data != "undefined"){
      this.data = data;
    }else{
      this.data = null;
    }
    return this.data;
  }



  /**
  *   nombre: queryPost
  *   tipo: function
  *   parametros: url (URL de una API), parameters (Datos necesarios para la Query)
  *   return: Promise (Contiene la respuesta de la API)
  *   descripcion: Recibe la URL de una API y los parámetros de la query, ejecuta un POST nativo de Angular, cuyo resultado es retornado en forma de Promise
  */
  public queryPost(url, parameters){
    return new Promise( (resolve, reject) => {
      this.httpClient.post(url, parameters).pipe(
        map( (res: Response) => res )
      ).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
          reject();
        }
      )
    });
  }

  /**
  *   nombre: queryDelete
  *   tipo: function
  *   parametros: url (URL de una API)
  *   return: Promise (Contiene la respuesta de la API)
  *   descripcion: Recibe la URL de una API y los parámetros de la query, ejecuta un DELETE nativo de Angular, cuyo resultado es retornado en forma de Promise
  */
  public queryDelete(url){
    return new Promise( (resolve, reject) => {
      this.httpClient.delete(url).pipe(
        map((res: Response) => res)
      ).subscribe(
        (data)  => {
          resolve(data);
        },
        (err)   => {
          console.log(err);
          reject();
        }
      );
    });
  }

  /**
  *   nombre: queryPut
  *   tipo: function
  *   parametros: url (URL de una API)
  *   return: Promise (Contiene la respuesta de la API)
  *   descripcion: Recibe la URL de una API y parametros, ejecuta un PUT nativo de Angular, cuyo resultado es retornado en forma de Promise
  */
  public queryPut(url, parameters){
    return new Promise( (resolve, reject) => {
      this.httpClient.put(url, parameters).pipe(
        map((res: Response) => res)
      ).subscribe(
        (data)  => {
          resolve(data);
        },
        (err)   => {
          console.log(err);
          reject();
        }
      );
    });
  }
}
