import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-card-publications',
  templateUrl: './card-publications.component.html',
  styleUrls: ['./card-publications.component.css']
})
export class CardPublicationsComponent implements OnInit {

  @Input() data:        any;
  @Input() heightSame:  any;
  @Input() urlImg:  any;
  @Input() vinculado:  any;
  
  public vincularme:any = [];

  constructor() { }

  ngOnInit() {
  }

  cheq(event){
    if(event.target.checked){
      this.vincularme.push(
        event.target.value
      );
    }else{
      for(var i = 0; this.vincularme.length>i; i++){    
        if(this.vincularme[i]==event.target.value){
          this.vincularme.splice(i, 1);
        }
      }
    }
    console.log(this.vincularme)
  }

}
