import { Component, OnInit, DoCheck }   from '@angular/core';
import menu_repositorio_uc              from '../../../assets/json/menu-repositorio.json';
import { QueriesService }               from '../../services/queries.service';
import { DataService } from '../../services/data.service';
//import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
//import { FormGroup,  FormBuilder,  Validators }   from '@angular/forms';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, DoCheck {

  MenuRepos: any = menu_repositorio_uc['menu-repositorio-uc'];
  //data:     any = [];
  msj;
  estilo;
  icono;
  usuario = this.queriesService.getUser();
  loggedIn;
  num_toolguia;

  constructor(
    private queriesService: QueriesService,
    public dataService: DataService
    //private queriesService: QueriesService, private _sanitizer: DomSanitizer, private formBuilder: FormBuilder
  ) { 

  }

  ngOnInit() {
    $("#msjHome").hide();
    if(this.queriesService.getToken()){
      this.alertas("success","check_circle","Sesión abierta");
    }
    
    
  }
  ngDoCheck(){

    // COMPUEBO LOGIN
    
    if(this.queriesService.getToken()){
      this.loggedIn = true;
      this.usuario = this.queriesService.getUser();
    }else{
      this.loggedIn = false;
    }

    if(!localStorage.getItem("toolguiaClose")){
      this.num_toolguia = localStorage.getItem("toolguia")
    }
  }

  loginCas(){
    this.dataService.login();
  }
  logout(){
    this.dataService.logoutCas();
  }

  alertas(estilo, icono, texto){
    $("#msjHome").fadeIn(1000);
    setInterval(() => {
      $("#msjHome").fadeOut(1500);
    }, 2000);
    this.msj = texto;
    this.estilo = estilo;
    this.icono = icono;
  }
  
  toolguia(paso){
    if(paso=='p0'){
      localStorage.setItem("toolguiaClose", "false")
      this.num_toolguia = "p0"
    }else{
      localStorage.setItem("toolguia", paso)
    }
  }
}
