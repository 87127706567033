import { NgModule }                   from '@angular/core';
import { Routes, RouterModule }       from '@angular/router';

import { AppComponent }               from './app.component';
import { HomeComponent }              from './templates/home/home.component';
import { ContactComponent }           from './templates/contact/contact.component';
import { CoverSecondLevelComponent }  from './templates/cover-second-level/cover-second-level.component';
import { PageContentComponent }       from './templates/page-content/page-content.component';
import { SingleRecordComponent }      from './templates/single-record/single-record.component';
import { UploadRecordComponent }      from './templates/upload-record/upload-record.component';
import { UploadArticulosComponent }   from './templates/upload-articulos/upload-articulos.component';
import { UploadLibrosComponent }      from './templates/upload-libros/upload-libros.component';
import { UploadCapituloLibrosComponent } from './templates/upload-capitulo-libros/upload-capitulo-libros.component';
import { UploadObraCreativaComponent }from './templates/upload-obra-creativa/upload-obra-creativa.component';
import { UploadOtrosComponent }       from './templates/upload-otros/upload-otros.component';
import { UploadPatentesComponent }    from './templates/upload-patentes/upload-patentes.component';
import { UploadMultipubComponent }    from './templates/upload-multipub/upload-multipub.component';
import { DescargaGaComponent }        from './templates/descarga-ga/descarga-ga.component';
import { BusquedaComponent }          from './templates/busqueda/busqueda.component';
import { IdentityGuard }              from './services/identity.guard';   
import { MyPostsComponent }           from './templates/my-posts/my-posts.component';  
import { MisPublicacionesComponent }  from './templates/mis-publicaciones/mis-publicaciones.component';     
import { MisDatosComponent }          from './templates/mis-datos/mis-datos.component';   
import { ProduccionAcademicosComponent }          from './templates/produccion-academicos/produccion-academicos.component';     
import { VersionesComponent }          from './templates/versiones/versiones.component';     

const routes: Routes = [
  {path: '',                   component: HomeComponent },
  //{ path: '',                          component: UploadRecordComponent, canActivate: [IdentityGuard] },
  {path: 'bibliometrics',loadChildren: () => import('./templates/bibliometrics/bibliometrics.module').then(m => m.BibliometricsModule)},
  {path: 'blog-list',loadChildren: ()     => import('./templates/blog-list/blog-list.module').then(m => m.BlogListModule)},
  {path: 'blog/:id',loadChildren: ()      => import('./templates/blog/blog.module').then(m => m.BlogModule)},
  {path: 'faq',loadChildren: ()           => import('./templates/faq/faq.module').then(m => m.FaqModule)},
  {path: 'enlaces/:pag',loadChildren: ()  => import('./templates/cover-content/cover-content.module').then(m => m.CoverContentModule)},

  { path: 'contact',                component: ContactComponent },
  { path: 'busqueda',               component: BusquedaComponent, canActivate: [IdentityGuard]  },
  { path: 'pagina/:pag',            component: CoverSecondLevelComponent },
  { path: 'upload/:pag',            component: UploadRecordComponent, canActivate: [IdentityGuard] },
  { path: 'upload-articulos/:id',   component: UploadArticulosComponent, canActivate: [IdentityGuard] },
  { path: 'upload-libros/:id',      component: UploadLibrosComponent, canActivate: [IdentityGuard] },
  { path: 'upload-capitulo-libros/:id', component: UploadCapituloLibrosComponent, canActivate: [IdentityGuard] },
  { path: 'upload-obra-creativa/:id',component: UploadObraCreativaComponent, canActivate: [IdentityGuard] },
  { path: 'upload-otros/:id',    component: UploadOtrosComponent, canActivate: [IdentityGuard] },
  { path: 'upload-patentes/:id',    component: UploadPatentesComponent, canActivate: [IdentityGuard] },
  { path: 'upload-multipub/:pag',   component: UploadMultipubComponent, canActivate: [IdentityGuard] },
  { path: 'content/:pag',           component: PageContentComponent },
  { path: 'handle/:param1/:param2', component: SingleRecordComponent},
  { path: 'descarga-ficha/:titulo', component: DescargaGaComponent },
  { path: 'mis-publicaciones',      component: MyPostsComponent, canActivate: [IdentityGuard]  },
  { path: 'mispublicaciones/:pag',  component: MisPublicacionesComponent, canActivate: [IdentityGuard]  },
  { path: 'misdatos',               component: MisDatosComponent, canActivate: [IdentityGuard]  },
  { path: 'produccion-academicos/:pag',  component: ProduccionAcademicosComponent, canActivate: [IdentityGuard]  },
  { path: 'versiones', component: VersionesComponent },
  
  /* Ruta wildcard, si la ruta no existe, redirige al Home */
  { path: '**',                 redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
