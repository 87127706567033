import { Injectable, ɵConsole }                                     from '@angular/core';
import { DataService }                                    from '../services/data.service';
import { QueriesService }                                 from '../services/queries.service';
import { HttpClient }                                     from '@angular/common/http';
import { Router, ActivatedRoute }                         from '@angular/router';
import json                                               from '../../assets/json/upload-libros/upload-libros.json';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private urlLoginCas: string;
  public urlRegistrar: string; 
  public urlGuardar: string;
  public urlvincular: string;
  public urlSql: string; 
  public urlPhp: string;
  public urlSqlArte: string; 

  public cargando;
  public msj_guardar;
  public cargando_guardados;
  public msj_guardados;
  public msj = null;
  public msjSend;
  public msj_error;
  public msjEmail;
  public alertaElimina = null;
  public soportadateInput;
  public formFile = false;
  public tabla_resumen:any = [];
  today;
  dd;
  mm;
  yyyy;
  public anio: number = new Date().getFullYear();
  public max_date_fecha_inscripcion = true;
  public max_date_fecha_solicitud = true;
  public max_date_fecha_concesion = true;
  public max_date_fecha_inicio = true;
  public max_date_fecha_termino;
  public rango_date;
  public minNumber = true;
  public pag_StartEnd = false;

  public validarol;
  public valida_tipo_obra;
  public valida_arte_viva;
  public valida_curatorias;
  public campos_obligatorios: boolean;
  public validalugar;
  public valida_pag_inicio_termino;
  public validauspiciadora;
  public validapatrocinadora;
  public validaAutores;

  /* AUTORES */
  public busca_autor = null;
  public cargando_autor;
  public msj_autor;
  /* INSERTAR NUEVO POST */
  public array_new_post: any = [];
  public datos_academicos = JSON.parse(this._queriesService.getjsonAcademico());
  public email = this._queriesService.getToken();
  public usuario = this._queriesService.getUser();
  /* EDITAR UN POST */
  public activePane: any = 't1';
  public idguardar;
  public array_enviar: any = {
    publicacion: null,
    clave: null,
    user: this._queriesService.getToken(),
    titulo: null,
    revistas:null,
    anio_publicacion: null,
    afiliacion_uc: null,
    volumen: null,
    numero: null,
    pagina_inicio: null,
    pagina_termino: null,
    otro_financiamiento: null,
    indexacion: null,
    otra_indexacion: null,
    doi: null,
    url_publicacion: null,
    keywords: null,

    tipo_creacion: null,
    caracter_curatorial: null,
    descripcion: null,
    fecha_inicio: null,
    fecha_termino: null,
    subtipo_de_creacion_original: null,
    ubicacion_obra: null,
    pais_ubicacion: null,

    pais_obra: [],
    
    institucion_auspiciadora: null,
    pais_auspiciador: [],
    institucion_patrocinadora: null,
    
    
    edicion: null,

    nro_paginas: null,
    editores: null,
    lugar_edicion: null,
    editorial: null,
    isbn: null, 

    titulo_capitulo: null,
    area: null,

    tipo_proteccion: null,
    unidad_academica: null,
    titular_solicitante: null,
    territorialidad: null,
    tipo_presentacion: null,
    estado_patentamiento: null,
    fecha_solicitud: null,
    nro_solicitud: null,
    fecha_concesion: null,
    nro_concesion: null,
    pais_patente:null,

    autores: null,
    archivos: null,
    licencia: null
  };
  public json: any = json;
  myFiles:string [] = [];
  /* CONSULTAR BORRADORES */
  public array_guardados:any = [];
  public indice_autor;
  

  constructor(
    private _queriesService: QueriesService,
    public dataService: DataService,
    private http: HttpClient,
    private router: Router
  ) { 
    this.dataService.urls();
      this.urlLoginCas        = this.dataService.urlLoginCas;
      this.urlRegistrar       = this.dataService.phpFormulario;
      this.urlGuardar         = this.dataService.phpGuardar; 
      this.urlvincular        = this.dataService.phpVincular; 
      this.urlSql             = this.dataService.phpSql;
      this.urlPhp             = this.dataService.phpDiscovery;
      this.urlSqlArte         = this.dataService.phpSqlArte;
  }

  toScroll(){
    document.getElementById('top').scrollIntoView({behavior: 'smooth'});
  }

  /* SE GENERA UN VALOR ÚNICO PARA SER USADO EN LOS NAME DINAMICOS DE LOS INPUT DE CADA AUTOR */
  indice_fecha(){
    var  fecha_indice = new Date();
    var anio = fecha_indice.getDate() + "" + fecha_indice.getMonth() + "" + fecha_indice.getFullYear();
    var hora = fecha_indice.getHours() 
    var minuto = fecha_indice.getMinutes() 
    var segundo = fecha_indice.getSeconds() 
    this.indice_autor = anio + "_" + hora + "_" + minuto + "_" + segundo;
  }

  date(){
    var pruebaFecha = document.createElement("input");
    //Si tiene soporte: debe aceptar el tipo "date"...
    pruebaFecha.setAttribute("type", "date");
    if ( pruebaFecha.type === "date" ){
      this.soportadateInput = true;
    }else{
      this.soportadateInput = false;
    }
  }

  rangoDate(event){
    this.rango_date = false; 
    if(1900 <= this.array_enviar.anio_publicacion && this.array_enviar.anio_publicacion <= this.anio){
      this.rango_date = true; 
    }
    if(this.array_enviar.anio_publicacion == null){ 
      this.rango_date = true; 
    }
  }

  fechaHoy(valor,desde){
    this.today = new Date();
    const valorAnio = new Date(valor);
    var minAnio = valorAnio.getFullYear();
    this.dd = this.today.getDate();
    this.mm = this.today.getMonth() + 1; //January is 0!
    this.yyyy = this.today.getFullYear();
    if (this.dd < 10) {
      this.dd = '0' + this.dd
    }
    if (this.mm < 10) {
      this.mm = '0' + this.mm
    }

    this.today = this.yyyy + '-' + this.mm + '-' + this.dd;
    if(valor > this.today || minAnio<1900){
      if(desde == 'fecha_inscripcion'){
        this.max_date_fecha_inscripcion = false
      }
      if(desde == 'fecha_solicitud'){
        this.max_date_fecha_solicitud = false
      }
      if(desde == 'fecha_concesion'){
        this.max_date_fecha_concesion = false
      }
      if(desde == 'fecha_inicio'){
        this.max_date_fecha_inicio = false; 
      }
    }else{
      if(desde == 'fecha_inscripcion'){
        this.max_date_fecha_inscripcion = true
      }
      if(desde == 'fecha_solicitud'){
        this.max_date_fecha_solicitud = true
      }
      if(desde == 'fecha_concesion'){
        this.max_date_fecha_concesion = true
      }
      if(desde == 'fecha_inicio'){
        this.max_date_fecha_inicio = true; 
        this.fechaFin(this.array_enviar.fecha_termino,"fecha_fin_obra")
      }
    }
  }

  fechaFin(valor,desde){
    var inicio = this.array_enviar.fecha_inicio;
    if(inicio>valor){
      if(desde == 'fecha_fin_obra'){
        this.max_date_fecha_termino = "Debe ser superior a la fecha de inicio";
        this.array_enviar.fecha_termino = null;
      }
    }else{
      if(desde == 'fecha_fin_obra'){
        this.max_date_fecha_termino = null;
      }
    }
  }

  min_number($event, num){
    if($event){
      var str = $event.toString().length;
      var min:Number = num;
      if(str<min){
        this.minNumber = false;
      }else{
        this.minNumber = true;
      }
    }else{
      this.minNumber = true;
    }
    
  }

  collapse(item){
    if(item == "general"){
      $('#'+item).slideToggle(300);
      this.actualizarPost();
    }
    if(item == "autorias" && this.array_enviar.autores && this.array_enviar.autores.length > 0 && this.validaAutores){
      $('#'+item).slideToggle(300);
      this.actualizarPost();
    }
    if(item == "archivos"){
      $('#'+item).slideToggle(300);
      this.actualizarPost();
    }
    if(item == "licencias"){
      $('#'+item).slideToggle(300);
      this.actualizarPost();
    }
  }

  cheq(event, ind:number){
    var lista = this.json['horizon_forms']['content'][0]['sections'][0]['input_indexacion']['options'];
    if(!this.array_enviar.indexacion){
      this.array_enviar.indexacion = [];
    }
    if(event.target.checked){
      this.array_enviar.indexacion.push(
        event.target.value
      );
      lista[ind].cheq = true;
    }else{
      for(var i = 0; this.array_enviar.indexacion.length>i; i++){    
        if(this.array_enviar.indexacion[i]==event.target.value){
          this.array_enviar.indexacion.splice(i, 1);
        }
      }
      lista[ind].cheq = false;
    }
  }

  indexa_cheq(){
    var lista = this.json['horizon_forms']['content'][0]['sections'][0]['input_indexacion']['options'];
    for(let a=0; a < lista.length; a++){
      
      if(this.array_enviar.indexacion){
        var num = 0;
        for(let i=0; i < this.array_enviar.indexacion.length; i++){  
          if(this.array_enviar.indexacion[i] == lista[a].name){
            num = 1;
          }
        }
        if(num == 0){
          lista[a].cheq = false;
        }
        if(num == 1){
          lista[a].cheq = true;
        }
      }else{
        lista[a].cheq = false;
      }
    }
  }

  addBuscaAutores(){
    let autorexiste = true;
    this.cargando_autor = true;
    this.msj_autor = null;
    if(!this.array_enviar.autores && (this.array_enviar.autores == undefined || this.array_enviar.autores == '')){this.array_enviar.autores = []}

    for(let i=0; this.array_enviar.autores.length>i; i++){
        if(this.array_enviar.autores[i].email == this.busca_autor + "@uc.cl"){
          /* this.busca_autor = null; */
          autorexiste = false;
          this.cargando_autor = false;
          this.msj_autor = "El autor ya existe en su lista";
        }
    }

    if(autorexiste){
      // Busca autores
      let busca_autor = {
        tipo    : "buscarAutor",
        campo   :  this.busca_autor //  "riolmos@uc.cl"
      };
      const formData = new FormData();
      formData.append('array_insert', JSON.stringify(busca_autor));

      this.http.post(this.urlSql, formData)
        .subscribe(response => {
          this.cargando_autor = false;
          if(response['code'] == 404){
            window.location.href= this.urlLoginCas;
          }else if(response == 400){
            this.msj_autor = "No existe ese usuario UC";
          }else{ 
            
            this.msj_autor = null; 
            if(response[0].nacionalidad==' '){
              var pais="Chile"
            }else{
              pais=response[0].nacionalidad
            };
            
            this.indice_fecha();
            this.array_enviar.autores.push({
              email: response[0].correo,
              rol: null,
              nombres: null,
              apellidos: null,
              nombre_completo: response[0].nombre_completo,
              ORCID: null,
              pais: [{
                nuevo: "no",
                nombre: pais
              }],
              institucion: [{
                nuevo: "no",
                nombre: "UC"
              }],
              indice: this.indice_autor
            });
            this.validaAutoresBoton()
          }
        }, err => {
          this.msj_autor = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
          this.cargando_autor = false;
          console.log(err);
        })

      /* this.busca_autor = null;
      this.msj_autor = null; */
    }
    
    
  }

  addBuscaParticipantes(){
    this.cargando_autor = true;
    this.msj_autor = null;
    if(!this.array_enviar.autores && (this.array_enviar.autores == undefined || this.array_enviar.autores == '')){this.array_enviar.autores = []}

    if(this.busca_autor){
      // Busca autores
      let busca_autor = {
        tipo    : "buscarAutor",
        campo   :  this.busca_autor //  "riolmos@uc.cl"
      };
      const formData = new FormData();
      formData.append('array_insert', JSON.stringify(busca_autor));

      this.http.post(this.urlSql, formData)
        .subscribe(response => {
          this.cargando_autor = false;
          if(response['code'] == 404){
            window.location.href= this.urlLoginCas;
          }else if(response == 400){
            this.msj_autor = "No existe ese usuario UC";
          }else{ 
            
            this.msj_autor = null; 
            if(response[0].nacionalidad==' '){
              var pais="Chile"
            }else{
              pais=response[0].nacionalidad
            };
            
            this.indice_fecha();
            this.array_enviar.autores.push({
              email: response[0].correo,
              rol: null,
              nombres: null,
              apellidos: null,
              nombre_completo: response[0].nombre_completo,
              ORCID: null,
              pais: [{
                nuevo: "no",
                nombre: pais
              }],
              institucion: [{
                nuevo: "no",
                nombre: "UC"
              }],
              indice: this.indice_autor
            });
            this.validaAutoresBoton()
          }
        }, err => {
          this.msj_autor = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
          this.cargando_autor = false;
          console.log(err);
        })

      this.busca_autor = null;
    }
    
    
  }
  
  addAutores() {  
    if(this.array_enviar.autores == undefined){this.array_enviar.autores = []}
    this.indice_fecha()
    /* this.array_enviar.autores = this.array_enviar.autores; */
    this.array_enviar.autores.push({
      email: null,
      rol: null,
      nombres: null,
      apellidos: null,
      nombre_completo:null,
      ORCID: null,
      pais: null,
      institucion: [],
      indice: this.indice_autor
    });
    
    this.validaAutoresBoton();
  }

  removeAutores(i: number) {
    this.array_enviar.autores.splice(i, 1); 
    this.validaAutoresBoton()
  }

  tipoCreacion(item){
    if(item == "Obras"){
      delete this.array_enviar.arte_viva; 
      delete this.array_enviar.curatorias;
      delete this.array_enviar.caracter_curatorial;
      this.array_enviar.tipo_creacion = item;
      

      if(this.datos_academicos){
        this.array_enviar.autores = [];
        this.array_enviar.autores.push({
          email: this.email,
          nombre_completo: this.usuario,
          ORCID: this.datos_academicos.ORCID,
          pais: [{
            nuevo: "no",
            nombre: this.datos_academicos.PAIS
            }],
          institucion: [{
            nuevo: "no",
            nombre: this.datos_academicos.INSTITUCION
            }]
        });
      }
    }
    if(item == "Artes Vivas"){
      delete this.array_enviar.tipo_obra;
      delete this.array_enviar.curatorias;
      delete this.array_enviar.caracter_curatorial;
      this.array_enviar.tipo_creacion = item;
      
      if(this.datos_academicos){
        this.array_enviar.autores = [];
        this.array_enviar.autores.push({
          email: this.email,
          nombre_completo: this.usuario,
          ORCID: this.datos_academicos.ORCID,
          pais: [{
            nuevo: "no",
            nombre: this.datos_academicos.PAIS
            }],
          institucion: [{
            nuevo: "no",
            nombre: this.datos_academicos.INSTITUCION
            }]
        });
      }
    }
    if(item == "Curatorías"){
      delete this.array_enviar.tipo_obra;
      delete this.array_enviar.arte_viva;
      this.array_enviar.tipo_creacion = item;

      if(this.datos_academicos){
        this.array_enviar.autores = [];
        this.array_enviar.autores.push({
          email: this.email,
          nombre_completo: this.usuario,
          ORCID: this.datos_academicos.ORCID,
          pais: [{
            nuevo: "no",
            nombre: this.datos_academicos.PAIS
            }],
          institucion: [{
            nuevo: "no",
            nombre: this.datos_academicos.INSTITUCION
            }]
        });
      }
    }
    $('#autorias').show();
    this.validaAutoresBoton();
  }

  camposObligatorios(){
    let autores = this.array_enviar.autores;
    let num_obliga = 0;

    for(let i=0; autores.length>i; i++){
      if(autores[i].rol && autores[i].rol.length != 0  ){
        num_obliga = num_obliga + 1;
      }
    }

    if(autores.length == num_obliga){
      this.campos_obligatorios = true;
    }else{
      this.campos_obligatorios = false;
    }

    /* VALIDA TIPO DE OBRA */
    var tipo_obra = this.array_enviar.tipo_obra;
    if(tipo_obra && tipo_obra != 0){
      this.valida_tipo_obra = true;
    }else{
      this.valida_tipo_obra = false;
    }
    /* VALIDA TIPO DE ARTES VIVAS */
    var arte_viva = this.array_enviar.arte_viva;
    if(arte_viva && arte_viva != 0){
      this.valida_arte_viva = true;
    }else{
      this.valida_arte_viva = false;
    }
    /* VALIDA TIPO DE CURATORIAS */
    var curatorias = this.array_enviar.curatorias;
    if(curatorias && curatorias != 0){
      this.valida_curatorias = true;
    }else{
      this.valida_curatorias = false;
    }
  }

  validaLugarBoton(){  
    if(this.array_enviar.pais_ubicacion){
      for(let i=0; this.array_enviar.pais_ubicacion.length>i; i++){
        if(this.array_enviar.pais_ubicacion[i].ubicacion && this.array_enviar.pais_ubicacion[i].pais_obra && this.array_enviar.pais_ubicacion[i].pais_obra.length != 0){
          this.validalugar = true;
        }else{
          this.validalugar = false;
          return
        }
      }
    }else{
      this.validalugar = true;
    }
    
  }
  validaAuspiciadoraBoton(){
    if(this.array_enviar.institucion_auspiciadora){
      for(let i=0; this.array_enviar.institucion_auspiciadora.length>i; i++){
        if(this.array_enviar.institucion_auspiciadora[i].nombre && this.array_enviar.institucion_auspiciadora[i].pais_auspiciadora && this.array_enviar.institucion_auspiciadora[i].pais_auspiciadora.length != 0){
          this.validauspiciadora = true;
        }else{
          this.validauspiciadora = false;
          return
        }
      }
    }else{
      this.validauspiciadora = true;
    }
  }
  validaPatrocinadoraBoton(){
    if(this.array_enviar.institucion_patrocinadora){
      for(let i=0; this.array_enviar.institucion_patrocinadora.length>i; i++){
        if(this.array_enviar.institucion_patrocinadora[i].nombre && this.array_enviar.institucion_patrocinadora[i].pais_patrocinadora && this.array_enviar.institucion_patrocinadora[i].pais_patrocinadora.length != 0){
          this.validapatrocinadora = true;
        }else{
          this.validapatrocinadora = false;
          return
        }
      }
    }else{
      this.validapatrocinadora = true;
    }
  }
  validaAutoresBoton(){  
    let autores = this.array_enviar.autores;
    if(autores && autores != 0){
      for(let i=0; autores.length>i; i++){
        if(this.array_enviar.publicacion == "Obra creativa"){
          if((autores[i].nombre_completo) && ((autores[i].rol && autores[i].rol.length != 0))){
            this.validaAutores = true;
          }else{
            this.validaAutores = false;
            return
          }
        }else{
          if((autores[i].nombre_completo)){
            this.validaAutores = true;
          }else{
            this.validaAutores = false;
            return
          }
        }
      }
    }else{
      this.validaAutores = false;
    }

    /* VALIDA TIPO DE OBRA */
    var tipo_obra = this.array_enviar.tipo_obra;
    if(tipo_obra && tipo_obra != 0){
      this.valida_tipo_obra = true;
    }else{
      this.valida_tipo_obra = false;
    }
    /* VALIDA TIPO DE ARTES VIVAS */
    var arte_viva = this.array_enviar.arte_viva;
    if(arte_viva && arte_viva != 0){
      this.valida_arte_viva = true;
    }else{
      this.valida_arte_viva = false;
    }
    /* VALIDA TIPO DE CURATORIAS */
    var curatorias = this.array_enviar.curatorias;
    if(curatorias && curatorias != 0){
      this.valida_curatorias = true;
    }else{
      this.valida_curatorias = false;
    }
  }
  validaTipoCreacion(){
    delete this.array_enviar.tipo_obra;
    delete this.array_enviar.arte_viva; 
    delete this.array_enviar.curatorias;
  }


  /* +++++++ FILE ++++++++  */
     
  seleccionarArchivo(event) {
    this.formFile = true;
    /* Cargo archivos en array myFiles */
    for (var i = 0; i < event.target.files.length; i++) {    
        this.myFiles.push(event.target.files[i]);
    }
    /* Escribo archivo en el array con descricion y fecha */
    if(!this.array_enviar.archivos){
      this.array_enviar.archivos = [];
    }
    
    for (var i = 0; i < event.target.files.length; i++) { 
        this.array_enviar.archivos.push({
          archivo: event.target.files[i].name,
          fecha_embargo: '0'
        });
    }
  }

  removeFile(i: number) {
      this.array_enviar.archivos.splice(i, 1);
      this.myFiles.splice(i, 1);
      if(this.array_enviar.archivos.length == 0){
        this.array_enviar.archivos = null;
      }
  }

  resetFile(){
      for (var i = 0; i < this.array_enviar.archivos.length; i++) { 
        this.array_enviar.archivos[i].archivo = null;
      }
  }

  /* +++ CREA UN BORRADOR CON ID EN BBDD +++ */
  public insertBorrador(alias){
    this.array_new_post={
      publicacion: alias,
      user: this._queriesService.getToken(),
      /* titular_solicitante:[{
        nombre: "Pontificia Universidad Católica de Chile"
      }], */
      licencia: {
        concedo_licencia : false,
        creative_commons : "Atribución 4.0 Internacional (CC BY 4.0)",
        url : "https://creativecommons.org/licenses/by/4.0/legalcode.es"
      }
    }
    if(this.datos_academicos){
      this.array_new_post.autores = [];
      this.array_new_post.autores.push({
        email: this.email,
        nombre_completo: this.usuario,
        ORCID: this.datos_academicos.ORCID,
        pais: [{
          nuevo: "no",
          nombre: this.datos_academicos.PAIS
          }],
        institucion: [{
          nuevo: "no",
          nombre: this.datos_academicos.INSTITUCION
          }]
      });
    }

    var acciones = {accion: "guardar", id: "", email: this.email, validaBiblio: ""}
    const formData = new FormData();
    formData.append('array_acciones', JSON.stringify(acciones));
    formData.append('array_borrador', JSON.stringify(this.array_new_post));

    /* +++++ CARGAR EN BBDD ++++ */
    this.http.post(this.urlGuardar, formData)
    .subscribe(response => {
      this.cargando = false;
      if(response['code'] == 404){
        window.location.href= this.urlLoginCas;
      }else if(response['httpCode'] == "200"){
        this.idguardar = response['mensaje'];
        this.editPost(this.array_new_post, this.idguardar);
        $('#general').show();
        this.msj_guardar = "Se guardó borrador en Mi producción académica.";
        
      }else{
        this.msj_guardar = "Error de conexión, no pudo ser guardado";
        /* console.log("se conectó pero no trajo httpCode 201"); */
        console.log(response)
      }
    }, err => {
      this.msj_guardar = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      this.cargando = false;
      console.log("error en api")
      console.log(err);
    })
    
  }

  /* +++ DESPLIEGA UN BORRADOR PARA EDITAR +++ */
  editPost(obj,id_post){
    this.activePane = 't1';
    $('#general').show();
    this.idguardar = id_post;
    this.array_enviar = obj; 
    this.indexa_cheq();
    this.validaLugarBoton()
    this.validaAuspiciadoraBoton();
    this.validaPatrocinadoraBoton();
    this.validaAutoresBoton();
    this.rangoDate("");
    this.fechaHoy(obj.fecha_solicitud,"fecha_solicitud");
    this.fechaHoy(obj.fecha_concesion,"fecha_concesion");
    $('#autorias').show();

    /* Solucion que agrega indice al name de input dinamicos */
    if(obj.autores){
      for( let ind=0; obj.autores.length>ind; ind++){
        this.indice_fecha();
        obj.autores[ind].indice = this.indice_autor + ind;
      }
    }
    if(obj.titular_solicitante && obj.publicacion == "Patentes"){
        for( let ind=0; obj.titular_solicitante.length>ind; ind++){
          this.indice_fecha();
          obj.titular_solicitante[ind].indice = this.indice_autor + ind;
        }
    }else{
      obj.titular_solicitante = null;
    }
    

    if(this.array_enviar.publicacion == "Artículo" || this.array_enviar.publicacion == "articulos" || this.array_enviar.publicacion == "Artículos"){
      var tipo_form = "articulos";
      if(obj.url_publicacion){
        for( let ind=0; obj.url_publicacion.length>ind; ind++){
          this.indice_fecha();
          obj.url_publicacion[ind].indice = this.indice_autor + ind;
        }
      }
    }
    if(this.array_enviar.publicacion == "Obra creativa"){
      var tipo_form = "obra-creativa";
      /* Solucion que agrega indice al name de input dinamicos */
      if(obj.pais_ubicacion){
        for( let ind=0; obj.pais_ubicacion.length>ind; ind++){
          this.indice_fecha();
          obj.pais_ubicacion[ind].indice = this.indice_autor + ind;
        }
      }
      if(obj.institucion_auspiciadora){
        for( let ind=0; obj.institucion_auspiciadora.length>ind; ind++){
          this.indice_fecha();
          obj.institucion_auspiciadora[ind].indice = this.indice_autor + ind;
        }
      }
      if(obj.institucion_patrocinadora){
        for( let ind=0; obj.institucion_patrocinadora.length>ind; ind++){
          this.indice_fecha();
          obj.institucion_patrocinadora[ind].indice = this.indice_autor + ind;
        }
      }
    }
    if(this.array_enviar.publicacion == "Libro"){
      var tipo_form = "libros";
      /* Solucion que agrega indice al name de input dinamicos */
      if(obj.editores){
        for( let ind=0; obj.editores.length>ind; ind++){
          this.indice_fecha();
          obj.editores[ind].indice = this.indice_autor + ind;
        }
      }
      if(obj.url_publicacion){
        for( let ind=0; obj.url_publicacion.length>ind; ind++){
          this.indice_fecha();
          obj.url_publicacion[ind].indice = this.indice_autor + ind;
        }
      }
    }
    if(this.array_enviar.publicacion == "Capítulo de libro"){
      var tipo_form = "capitulo-libros";
      /* Solucion que agrega indice al name de input dinamicos */
      if(obj.editores){
        for( let ind=0; obj.editores.length>ind; ind++){
          this.indice_fecha();
          obj.editores[ind].indice = this.indice_autor + ind;
        }
      }
      if(obj.url_publicacion){
        for( let ind=0; obj.url_publicacion.length>ind; ind++){
          this.indice_fecha();
          obj.url_publicacion[ind].indice = this.indice_autor + ind;
        }
      }
    }
    if(this.array_enviar.publicacion == "Otros"){
      var tipo_form = "otros";
      if(obj.url_publicacion){
        for( let ind=0; obj.url_publicacion.length>ind; ind++){
          this.indice_fecha();
          obj.url_publicacion[ind].indice = this.indice_autor + ind;
        }
      }
    }
    if(this.array_enviar.publicacion == "Patente"){
      var tipo_form = "patentes";
    }

    this.router.navigate([ '/upload-' + tipo_form + '/' + this.idguardar ]);
    
  }

  // +++ SIDEBAR +++ DESPLIEGA LOS BORRADORES GUARDADOS SIN ENVIAR
  mis_guardados(){ 
    this.cargando_guardados = true;
    this.msj_guardados = null;
    var acciones;
    const formData = new FormData();
    acciones = {accion: "consultar", id: "", email: this.email, validaBiblio:"", start: "", handle: ""}
    formData.append('array_acciones', JSON.stringify(acciones));
     
    /* +++++ CARGAR EN BBDD ++++ */
      
    this.http.post(this.urlGuardar, formData)
      .subscribe(response => {
        this.cargando_guardados = false;
        this.array_guardados = response;
        if(response['code'] == 404){
          window.location.href= this.urlLoginCas;
        }else{
          
          if(response && this.array_guardados.length != 0){ 
            for( let i=0; this.array_guardados.length>i; i++){
              this.array_guardados[i].json_temporal = JSON.parse(this.array_guardados[i].json_temporal);
            }
          }else{
            this.array_guardados = [];
            this.msj_guardados = "No existen documentos guardados"
          }
        }
    }, err => {
      this.cargando_guardados = false;
      this.msj_guardados = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    })
    
  }

  /* +++ GUARDA EL BORRADOR +++ */
  actualizarPost(){ 
    let limpiar_array = this.array_enviar;
    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }

    var acciones;
    acciones = {accion: "actualizar", id: this.idguardar, email: this.email, validaBiblio: ""}

    const formData = new FormData();
    /* Entrego archivos */
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i]);
    }
    /* Entrgo array */
    formData.append('array_acciones', JSON.stringify(acciones));
    formData.append('array_borrador', JSON.stringify(limpiar_array));

    /* +++++ CARGAR EN BBDD ++++ */
    
    this.http.post(this.urlGuardar, formData)
    .subscribe(response => {
      this.cargando = false;
      if(response['code'] == 404){
        window.location.href= this.urlLoginCas;
      }else if(response['httpCode'] == "200"){
        this.mis_guardados();
        this.msj_guardar = "Se actualizó borrador en Mi producción académica.";  
      }else{
        this.msj_guardar = "Error de conexión, no pudo ser guardado";
        /* console.log("se conectó pero no trajo httpCode 201"); */
      }
    }, err => {
      this.msj_guardar = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      this.cargando = false;
      console.log("error en api")
      console.log(err);
    })
    this.indexa_cheq();
  }

  /* ELIMINO GUARDADO */
  alertDelete(i){
    this.alertaElimina = i;
  }

  deletePost(idpost, i){
    this.cargando = true;
    this.msj = null;
    this.alertaElimina = null;
    
    const formData = new FormData();
      var acciones = {accion: "eliminar", id: idpost, email: this.email, validaBiblio:"", start: "", handle: ""}
      formData.append('array_acciones', JSON.stringify(acciones));
    
      this.http.post(this.urlGuardar, formData)
      .subscribe(response => {
        this.cargando = false;
        if(response['code'] == 404){
          window.location.href= this.urlLoginCas;
        }else if(response['httpCode'] == "201" || response['httpCode'] == "200"){ 
          this.array_guardados.splice(i, 1);   
          window.location.href= "upload/subir-publicacion";
        }else{
          this.msj = "Tuvimos problema, no pudimos eliminar el registro";
          /* console.log("conecto pero no trajo httpCode = 201 o 200"); */
          console.log(response)
        }
      }, err => {
        this.msj= "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
        this.cargando = false;
        console.log(err);
      })
  }

  resumenEnvio(){ 
    let limpiar_array = this.array_enviar;
    this.tabla_resumen = [];
    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }
    
    for(let ind in limpiar_array){ 
      var alias_metadata = ind;
      var subitem = false;
      var mostrar = true;

      
      if(ind == "user"){
        alias_metadata = "Usuario";
        subitem = false;
        mostrar = false;
      }
      if(ind == "concedo_licencia"){
        mostrar = false;
      }
      if(ind == "institucion"){
        alias_metadata = "Institución";
        subitem = true;
        mostrar = false;
      }

      if(ind == "titular_solicitante"){
        alias_metadata = "Titular o solicitante";
        subitem = true;
        if(this.array_enviar.publicacion == "Patente"){
          mostrar = true;
        }else{
          mostrar = false;
        }
      }
      if(ind == "publicacion"){
        alias_metadata = "Tipo de publicación";
      }
      if(ind == "titulo"){
        alias_metadata = "Título";
      }
      if(ind == "titulo_capitulo"){
        alias_metadata = "Título del capítulo";
      }
      if(ind == "revistas"){
        alias_metadata = "Revista donde está publicado";
        subitem = true;
      }
      if(ind == "anio_publicacion"){
        alias_metadata = "Año publicación";
      }
      if(ind == "afiliacion_uc"){
        alias_metadata = "¿El artículo menciona afiliación UC?";
      }
      if(ind == "volumen"){
        alias_metadata = "Volumen de revista";
      }
      if(ind == "numero"){
        alias_metadata = "Número de revista";
      }
      if(ind == "pagina_inicio"){
        alias_metadata = "Página de inicio";
      }
      if(ind == "pagina_termino"){
        alias_metadata = "Página de término";
      }
      if(ind == "otro_financiamiento"){
        alias_metadata = "Financiamiento";
        subitem = true;
      }
      if(ind == "indexacion"){
        alias_metadata = "Indexación";
      }
      if(ind == "otra_indexacion"){
        alias_metadata = "Otra indexación";
      }
      if(ind == "doi"){
        alias_metadata = "DOI";
      }
      if(ind == "url_publicacion"){
        alias_metadata = "Url publicación";
        subitem = true;
      }
      if(ind == "editores"){
        alias_metadata = "Editores";
        subitem = true;
      }
      if(ind == "lugar_edicion"){
        alias_metadata = "Lugar de publicación";
        subitem = true;
      }
      if(ind == "editorial"){
        alias_metadata = "Editorial";
        subitem = true;
      }
      if(ind == "isbn"){
        alias_metadata = "ISBN";
      }
      if(ind == "nro_paginas"){
        alias_metadata = "Número de páginas";
      }
      if(ind == "keywords"){
        alias_metadata = "Palabras claves";
      }
      if(ind == "arte_viva"){
        alias_metadata = "Tipo de artes vivas";
        subitem = true;
      }
      if(ind == "autores"){
        alias_metadata = "Autores";
        subitem = true;
      }
      if(ind == "pais_ubicacion"){
        alias_metadata = "Lugar y país de la obra";
        subitem = true;
      }
      if(ind == "institucion_auspiciadora"){
        alias_metadata = "Institución auspiciadora";
        subitem = true;
      }
      if(ind == "institucion_patrocinadora"){
        alias_metadata = "Institución patrocinadora";
        subitem = true;
      }
      if(ind == "fecha_inicio"){
        alias_metadata = "Fecha de inicio";
      }
      if(ind == "fecha_termino"){
        alias_metadata = "Fecha de término";
      }
      if(ind == "descripcion"){
        alias_metadata = "Descripción";
      }
      if(ind == "tipo_creacion"){
        alias_metadata = "Tipo de creación";
      }
      if(ind == "tipo_obra"){
        alias_metadata = "Tipo de obra";
        subitem = true;
      }
      if(ind == "curatorias"){
        alias_metadata = "Tipo de curatorías";
        subitem = true;
      }
      if(ind == "caracter_curatorial"){
        alias_metadata = "Carácter de la curatoría / selección de obra";
      }
      if(ind == "territorialidad"){
        alias_metadata = "Territorialidad";
      }
      if(ind == "tipo_presentacion"){
        alias_metadata = "Tipo de presentación";
      }
      if(ind == "estado_patentamiento"){
        alias_metadata = "Estado proceso de patentamiento";
      }
      if(ind == "nro_solicitud"){
        alias_metadata = "Número de solicitud";
      }
      if(ind == "fecha_concesion"){
        alias_metadata = "Fecha de concesión";
      }
      if(ind == "nro_concesion"){
        alias_metadata = "Número de concesión";
      }
      if(ind == "fecha_solicitud"){
        alias_metadata = "Fecha de solicitud";
      }
      if(ind == "unidad_academica"){
        alias_metadata = "Unidad académica";
        subitem = true;
      }
      if(ind == "tipo_proteccion"){
        alias_metadata = "Tipo de protección";
      }
      if(ind == "pais_patente"){
        alias_metadata = "País patente";
        subitem = true;
      }
      if(ind == "archivos"){
        alias_metadata = "Archivos";
        subitem = true;
      }
      if(ind == "licencia"){
        alias_metadata = "Licencias";
        subitem = true;
      }
      

      if(mostrar){
        this.tabla_resumen.push({ 
          "param": alias_metadata, 
          "valor": limpiar_array[ind],
          "sub": subitem,
          "mostrar": mostrar
        });
      }
    }
  }

  // +++++++++++ ENVIO FORM +++++++++++++++
  submitPublica(form){ 
    this.cargando     = true;
    const formData    = new FormData();
    this.array_enviar.autores;
    let limpiar_array = this.array_enviar;

    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }

    /* Entrego archivos */
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i]);
    }
    /* Desde guardados */
    if(this.idguardar){
      formData.append('id_eliminar', this.idguardar);
    }else{
      formData.append('id_eliminar', 'no');
    }
    /* console.log(JSON.stringify(limpiar_array));
    console.log(limpiar_array);
       return */

    formData.append('array_datos', JSON.stringify(limpiar_array));
    
    /* +++++ ENVIAR A API ++++ */

        this.http.post(this.urlRegistrar, formData)
        .subscribe(response => {
          
          if(response['code'] == 404){
            window.location.href= this.urlLoginCas;
          }else if(response['httpCode'] == "200" || response['httpCode'] == "201"){
            form.reset();
            this.cargando = false;
            this.msj      = "success";
            this.msjSend  = "Proceso exitoso"
            this.mis_guardados();

            if(response['httpCodeCorreo'] == "200" || response['httpCodeCorreo'] == "201"){
              this.msjEmail = "Se ha enviado correo electrónico de confirmación a tu cuenta";
            }else{
              this.msjEmail = "No hemos podido enviar correo electrónico de confirmación a tu cuenta";
              /* console.log(response); */
            }
          }else{
            this.cargando   = false;
            this.msj        = "error";
            this.msjSend    = "Envío ha fallado."
            this.msj_error  = "¡Disculpa! Hemos enviado tus datos pero el servicio no logra recibirlos, inténtalo nuevamente.";
            console.log(response)
    }
        }, err => {
          this.msj        = "error";
          this.cargando   = false;
          this.msjSend    = "Envío ha fallado."
          this.msj_error  = "¡Disculpa! Hemos perdido la conexión con el servicio, inténtalo mas tarde";
          console.log(err);
        })
        
      
  }

  
}
