import { Component, OnInit,DoCheck, ɵConsole }    from '@angular/core';
import { Observable }                             from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { QueriesService }                         from '../../services/queries.service';
import json                                       from '../../../assets/json/home.json';
import AreasTematicas                             from '../../../assets/json/areas-tematicas.json';
import CajonBusqueda                              from '../../../assets/json/cajon-busqueda.json';

import { FormGroup,  FormBuilder,  Validators }   from '@angular/forms';
import { Router }                                 from '@angular/router';

import { NgForm }                                 from '@angular/forms';
import { SubscribeService }                       from '../../services/subscribe.service';
import { DataService }                            from '../../services/data.service';

import { environment }                            from "../../../environments/environment";
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/mergeMap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
  
})
export class HomeComponent implements OnInit, DoCheck {
  json:     any = json;
  posts:    any = AreasTematicas['areas-tematicas'];
  CajonBusqueda: any = CajonBusqueda;
  data:     any = [];
  loggedIn: any = false;
  public usuario = this.queriesService.getUser();
  public correo = this.queriesService.getToken();
  public datos_academicos = JSON.parse(this.queriesService.getjsonAcademico());
  tabla_datos_academicos: any=[];
  editing:  any = false;
  userData: any = [];
  loginFailed: any = "";
  angForm: FormGroup;
  editForm: FormGroup;
  categorias: any = [];
  status;
  public buscalibro = { name: ''};
  subscribeData: any = <any>{};
  modal = false;
  tituloModal;
  condicion_publicar = false;

  public now;
  public hours = 1;

  d1;
  d2;

  constructor(
    private subscribeService: SubscribeService,
    private router: Router,
    private queriesService: QueriesService, private _sanitizer: DomSanitizer, private formBuilder: FormBuilder,
    private dataService: DataService
    ) 
    {
    this.dataService.urls();
    }

  ngOnInit() {
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
    
    // TEXTOS PÁGINA
    this.json = json;
    
    // NUESTROS NUMEROS
    this.queriesService.queryGet(this.dataService.phpCantidadArticulos).then((data) => {
      this.data['statistics-articulos'] = data['response']['numFound'];
    });

    this.queriesService.queryGet(this.dataService.phpCantidadCapLibros).then((data) => {
      this.data['statistics-capitulo'] = data['response']['numFound'];
      
    });

    this.queriesService.queryGet(this.dataService.phpCantidadLibros).then((data) => {
      this.data['statistics-libros'] = data['response']['numFound']; 
    });

    this.queriesService.queryGet(this.dataService.phpCantidadPatentes).then((data) => {
      this.data['statistics-patentes'] = data['response']['numFound'];
    });

    this.queriesService.queryGet(this.dataService.phpCantidadObras).then((data) => {
      this.data['statistics-obras'] = data['response']['numFound'];
    });
    
  }

  ngDoCheck(){

    // COMPUEBO LOGIN
    if(this.queriesService.getToken()){
      this.loggedIn = true;
      this.router.navigate(['/mispublicaciones/confirmado']); // /upload/subir-publicacion
    }else{
      this.loggedIn = false;
    }
  }

  scrollToElement($element): void {
    document.getElementById('suscripcion').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  abreModal(){
    this.modal = true;
    this.tituloModal = "Mensaje";
  }
  cerrarModal(){
    this.modal = false;
  }

  loginCas(){
    this.dataService.login();
  } 
  logout(){
    this.dataService.logoutCas();
  }

  getStatistics(num){
    localStorage.setItem('search_form','');
    var array_Filtros: any[] = [
      {
        search_by: 'tipo',
        contains: 'contiene',
        term: num
      }
    ];
    localStorage.setItem('json_filtros',JSON.stringify(array_Filtros));
    localStorage.setItem('searchAdvanced','true');

    this.router.navigate(['/busqueda']);
  }

  cheq(event){
    if(event.target.checked){
      this.condicion_publicar = true;
    }else{
      this.condicion_publicar = false;
    }

  }
  

}
