import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger }     from '@angular/animations';
import { Router, ActivatedRoute }                         from '@angular/router';
import json                                               from '../../../assets/json/upload-patentes/upload-patentes.json';
import { QueriesService }                                 from '../../services/queries.service';
import { FormService }                                    from '../../services/form.service';


@Component({
  selector: 'app-upload-patentes',
  templateUrl: './upload-patentes.component.html',
  styleUrls: ['./upload-patentes.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class UploadPatentesComponent implements OnInit {
  public json: any = json;
  public usuario;
  public name_usuario;
  public activePane: any = 't1';

  constructor(
    private _queriesService: QueriesService,
    private router: Router,
    public formService: FormService
  ) { }

  ngOnInit() {
    // REFRESH PÁGINA
    if(!this.formService.idguardar) {
      this.router.navigate(['/upload/subir-publicacion']);
      return
    }
    
    this.toScroll();

    /* VALORIZACION */
    var URLactual = window.location.href;
    this.json["rating"]['star'].pagina = URLactual

    /* USUARIOS SIN CODPERS */
    if(localStorage.getItem('estado_user_sipa') == "false"){
      this.router.navigate(['/mispublicaciones/confirmado']);
    }
    /* RESCATAR NOMBRE USUARIO */
    this.usuario = this._queriesService.getUser()
    let split = this.usuario.split(',');
    this.name_usuario = split[1];

    this.formService.date();
    this.formService.mis_guardados(); 
  }

  toScroll(){
    document.getElementById('pasos').scrollIntoView({behavior: 'smooth'});
  }
  collapse(item){
    if(item == "general" && this.formService.array_enviar.titulo && this.formService.max_date_fecha_solicitud && this.formService.max_date_fecha_concesion && this.formService.array_enviar.tipo_proteccion && this.formService.array_enviar.unidad_academica && this.formService.array_enviar.unidad_academica.length != 0 && this.formService.array_enviar.territorialidad && this.formService.array_enviar.tipo_presentacion && this.formService.array_enviar.estado_patentamiento && this.formService.array_enviar.nro_solicitud && (this.formService.array_enviar.pais_patente && this.formService.array_enviar.pais_patente.length != 0)){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "autorias" && this.formService.array_enviar.autores && this.formService.array_enviar.autores.length > 0 && this.formService.validaAutores){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
    if(item == "archivos"){
      $('#'+item).slideToggle(300);
      this.formService.actualizarPost();
    }
  }
  addTitularSolicitante(opcion){
    this.formService.indice_fecha();
    this.formService.array_enviar.titular_solicitante.push({
      nombre: null,
      indice: this.formService.indice_autor
    });
  }
  removeTitularSolicitante(i: number) {
    this.formService.array_enviar.titular_solicitante.splice(i, 1); // elimina 1 indice a partir del indice i
  }
  territ(opcion){
    this.formService.array_enviar.territorialidad = opcion;
    this.formService.array_enviar.tipo_presentacion = null;
    if(opcion == "Extranjera"){
      this.formService.array_enviar.tipo_presentacion = opcion;
    }
    console.log(opcion)
    console.log(this.formService.array_enviar.tipo_presentacion)
    
  }
  tipo_presentacion(opcion){
    console.log(this.formService.array_enviar.territorialidad)
    console.log(opcion)
    this.formService.array_enviar.tipo_presentacion = opcion;
  }

}
